<template>
  <hq-layout>
    <div class="bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Hi {{ customer.first_name }}, this is the information you specified for your bespoke timepiece.
              </h1>
            </div>

            <div class="my-8">
              <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
                Self service
              </p>
              <nav class="border-b border-gray-mm-600">
                <a
                  v-if="false"
                  :href="route('account.orders.edit-certificate.index', { id: order.id })"
                  class="block p-2 py-3 border-t border-gray-mm-600">Edit Certificate</a>
                <a
                  v-if="!site.engraving_modification_ended && useSanity"
                  :href="route('account.orders.edit-timepiece.edit', { id: order.id })"
                  class="block p-2 py-3 border-t border-gray-mm-600">Edit Timepiece</a>
                <span
                  v-else-if="site.engraving_modification_ended && useSanity"
                  class="block p-2 py-3 border-t border-gray-mm-600 text-gray-mm-300">Edit Timepiece <span class="text-xs uppercase ml-2 text-gray-mm-400">Expired</span></span>
                <a
                  v-if="!site.engraving_modification_ended && site.meta.relationship_options && !useSanity"
                  :href="route('account.orders.edit-relationship.index', { id: order.id })"
                  class="block p-2 py-3 border-t border-gray-mm-600">Edit Unit/Company</a>
                <span
                  v-else-if="site.engraving_modification_ended && site.meta.relationship_options && !useSanity"
                  class="block p-2 py-3 border-t border-gray-mm-600 text-gray-mm-300">Edit Unit/Company <span class="text-xs uppercase ml-2 text-gray-mm-400">Expired</span></span>
                <a
                  v-if="!site.address_modification_ended"
                  :href="route('account.orders.edit-address.index', { id: order.id })"
                  class="block p-2 py-3 border-t border-gray-mm-600">Edit Shipping Address</a>
                <span
                  v-else
                  :href="route('account.orders.edit-address.index', { id: order.id })"
                  class="block p-2 py-3 border-t border-gray-mm-600 text-gray-mm-300">Edit Shipping Address <span class="text-xs uppercase ml-2 text-gray-mm-400">Expired</span></span>
                <a
                  v-if="false"
                  href="#"
                  class="block p-2 py-3 border-t border-gray-mm-600">Edit Engraving</a>
              </nav>
            </div>

            <div class="my-8">
              <p class="text-sm uppercase text-yellow-mm-600 tracking-widest">
                Timepiece
              </p>

              <div
                class="w-full mb-2"
                v-if="false">
                <span class="text-gray-mm-300 block">Timepiece</span>
                {{ timepiece }}
              </div>
              <div class="w-full mb-2">
                <span class="text-gray-mm-300 block">Kit</span>
                <pre>{{ kit.product.name }}</pre>
              </div>
              <div class="w-full mb-2">
                <span class="text-gray-mm-300 block">Addons</span>
                <div
                  v-for="(item,index) in addons"
                  :key="index">
                  {{ item.product.name }}
                </div>
              </div>
            </div>
            <div v-if="!editMode">
              <div
                v-if="!useSanity"
                class="my-8">
                <div class="mb-8 border-t border-gray-mm-600 pt-4">
                  <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-2">
                    Caseback Engraving
                  </p>
                  <div class="mb-4">
                    <p class="text-gray-mm-300 mb-2">
                      Engraving Line 1
                    </p>
                    <div class="mb-2 w-full">
                      <span v-if="order.meta.engraving_line_1">{{ order.meta.engraving_line_1 }}</span>
                      <span
                        v-else
                        class="text-gray-mm-400">Not set.</span>
                    </div>
                  </div>
                  <div
                    class="mb-4"
                    v-if="kit.product.meta && kit.product.meta.engraving_line_2_max && kit.product.meta.engraving_line_2_max > 0">
                    <p class="text-gray-mm-300 mb-2">
                      Engraving Line 2
                    </p>
                    <div class="mb-2 w-full">
                      {{ order.meta.engraving_line_2 }}
                    </div>
                  </div>
                </div>
                <default-button
                  tertiary
                  @click.prevent.native="editMode = true">
                  Click to edit
                </default-button>
              </div>
            </div>
            <form
              @submit.prevent="submit"
              v-else>
              <div class="mb-8">
                <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
                  Caseback Engraving
                </p>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    Engraving Line 1
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      :value="formData.engraving_line_1"
                      @input="formData.engraving_line_1 = $event.toUpperCase()"
                      type="text"
                      placeholder="Top engraving"
                      autofocus />
                    <span class="text-xs text-gray-mm-300">{{ kit.product.meta.engraving_line_1_max - formData.engraving_line_1.length }} remaining</span>
                  </div>
                </div>
                <div
                  class="mb-4"
                  v-if="kit.product.meta && kit.product.meta.engraving_line_2_max && kit.product.meta.engraving_line_2_max > 0">
                  <p class="text-gray-mm-300 mb-2">
                    Engraving Line 2
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      :value="formData.engraving_line_2"
                      @input="formData.engraving_line_2 = $event.toUpperCase()"
                      type="text"
                      placeholder="Bottom engraving"
                      autofocus />
                    <span class="text-xs text-gray-mm-300">{{ kit.product.meta.engraving_line_2_max - formData.engraving_line_2.length }} remaining</span>
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <default-button
                  tertiary
                  :loading="submitting"
                  :disabled="submitting">
                  Save changes
                </default-button>
              </div>
              <div
                class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center"
                v-if="$page.props.flash.success">
                {{ $page.props.flash.success }}
              </div>
              <validation-errors unique />
            </form>
            <div
              class="my-8"
              v-if="kit.product.site.filter(site => site.name === 'Panser').length > 0">
              <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
                Direction of the Mink
              </p>
              <div class="mb-4">
                <bezel-selector
                  v-model="formData.bezel_variant"
                  class="mb-4" />

                <div class="mb-4">
                  <default-button
                    tertiary
                    :loading="submitting"
                    :disabled="submitting"
                    @click.native="submitBezelVariant">
                    Save Changes
                  </default-button>
                </div>
                <div
                  class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center"
                  v-if="$page.props.flash.success">
                  {{ $page.props.flash.success }}
                </div>
                <validation-errors />
              </div>
            </div>
          </div>

          <div class="my-8">
            <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
              Delivery Information
            </p>
            <div
              class="flex flex-wrap"
              v-if="shipping.product.name === 'Private address'">
              <div class="w-6/12 mb-2">
                <span class="text-gray-mm-300 block">First name</span>
                {{ customer.first_name }}
              </div>
              <div class="w-6/12 mb-2">
                <span class="text-gray-mm-300 block">Last name</span>
                {{ customer.last_name }}
              </div>
              <div class="w-full mb-2">
                <span class="text-gray-mm-300 block">Street</span>
                {{ customer.address_line_1 }}
              </div>
              <div class="w-6/12 mb-2">
                <span class="text-gray-mm-300 block">City</span>
                {{ customer.city }}
              </div>
              <div class="w-6/12 mb-2">
                <span class="text-gray-mm-300 block">Postal code</span>
                {{ customer.postal_code }}
              </div>
              <div class="w-full mb-2">
                <span class="text-gray-mm-300 block">Email</span>
                {{ customer.email }}
              </div>
              <div class="w-full mb-2">
                <span class="text-gray-mm-300 block">Phone</span>
                {{ customer.phone }}
              </div>
            </div>
            <div v-else>
              {{ shipping.product.name }}
            </div>
          </div>

          <div class="py-8 sm:mt-16 sm:mb-12">
            <p>
              If you need to change name or delivery address, please reach out to us by email.  <a
                href="mailto:sos@micromilspec.com"
                class="text-yellow-mm-600 underline">sos@micromilspec.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </hq-layout>
</template>

<script>
import HqLayout from "@/Shared/HqLayout.vue"
import BezelSelector from "@/Shared/BezelSelector.vue"

export default {
  props: {
    customer: {
      type: Object,
      default () {
        return {}
      }
    },

    order: {
      type: Object,
      default () {
        return {}
      }
    },

    site: {
      type: Object,
      default () {
        return {}
      }
    },

    useSanity: {
      type: Boolean,
      default: false
    }
  },

  components: {
    HqLayout,
    BezelSelector
  },

  mounted () {
    this.formData.engraving_line_1 = this.order.meta.engraving_line_1
    this.formData.engraving_line_2 = this.order.meta.engraving_line_2
    this.formData.bezel_variant = this.order.meta.bezel_variant || { id: "right", title: "Right" }
  },

  data () {
    return {
      formData: {
        engraving_line_1: null,
        engraving_line_2: null,
        bezel_variant: null,
      },
      error: null,
      submitting: false,
      editMode: false,
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.patch(`/update-order/${this.order.id}`, this.formData)
        .then(() => {
          this.submitting = false
        })
    },

    submitBezelVariant () {
      this.submitting = true
      this.$inertia.patch(`/update-order/${this.order.id}`, { ...this.formData, bezel_variant: this.formData.bezel_variant })
        .then(() => {
          this.submitting = false
        })
    },
  },

  computed: {
    shipping () {
      return this.order.order_items.find(order => order.product.type === "shipping")
    },

    kit () {
      return this.order.order_items.find(order => order.product.type === "primary")
    },

    timepiece () {
      return this.order.order_items.filter(order => order.product.type === "timepiece")
    },

    addons () {
      return this.order.order_items.filter(order => order.product.type === "addon")
    }
  }
}
</script>
