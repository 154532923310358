<template>
  <button
    :class="classObject"
    :disabled="loading"
    class="default-button px-4 py-3 font-semibold text-sm rounded-sm focus:outline-none focus:shadow-outline whitespace-no-wrap">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: "Submit"
    },

    primary: {
      type: Boolean,
      default: false
    },

    secondary: {
      type: Boolean,
      default: false
    },

    tertiary: {
      type: Boolean,
      default: false
    },

    text: {
      type: Boolean,
      default: false
    },

    outline: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    classObject () {
      return {
        "loading": this.loading,
        "text-gray-mm-300": !this.primary && !this.tertiary && !this.secondary && !this.inverted && !this.text && !this.outline,
        "text-white bg-blue-500 hover:bg-blue-600 border border-transparent": this.primary,
        "text-black bg-yellow-mm-600 hover:bg-yellow-700 border border-transparent": this.tertiary,
        "text-white bg-transparent hover:bg-gray-mm-200 border border-gray-mm-300": this.secondary,
        "text-black bg-white hover:bg-gray-mm-100 border border-transparent": this.inverted,
        "text-white bg-transparent hover:bg-gray-mm-600 border border-transparent": this.text,
        "text-white bg-transparent hover:bg-gray-mm-600 border border-gray-mm-400 hover:border-gray-mm-600": this.outline,
      }
    }
  }
}
</script>
