<template>
  <div
    role="radiogroup"
    aria-labelledby="radio-group-title">
    <template v-if="options.length > 0">
      <label
        v-for="(option,index) in options"
        class="block select-none cursor-pointer"
        :key="index">
        <input
          type="radio"
          :name="'radio' + uid"
          v-model="model"
          :value="optionKey ? option[optionKey] : option"
          class="clip">
        <slot :item="option">
          {{ option.title }}
        </slot>
      </label>
    </template>
    <template v-else>
      <div class="text-gray-mm-300 text-sm p-4 bg-gray-mm-600 rounded">
        No options available
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    value: {
      type: [Array, Object, Number, String],
      default: null
    },

    optionKey: {
      type: [Array, Object, Number, String],
      default: null
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    uid() {
      return "-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>
