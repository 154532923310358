<template>
  <div>
    <div
      id="intro-box">
      <video
        v-if="data.intro_video.url"
        :src="data.intro_video.url"
        autoplay
        muted
        playsinline
        class="object-cover w-full h-full hidden md:block" />
      <video
        v-if="data.intro_video_mobile.url"
        :src="data.intro_video_mobile.url"
        autoplay
        muted
        playsinline
        class="object-cover w-full h-full md:hidden" />
      <ResponsiveImage
        v-else-if="!data.intro_video.url && !data.intro_video_mobile.url && data.intro_image"
        :image="data.intro_image" />
      <div
        id="intro-text"
        :class="`absolute inset-0 flex items-center titles-alignment-${data.titles_alignment}`"
      >
        <div
          id="titles"
          class="text-center mx-auto">
          <div
            id="titles-preamble"
            class=""
            :style="`color: ${data.preamble_color};`">
            {{ data.preamble }}
          </div>
          <h1
            class="font-bold text-3xl lg:text-5xl"
            :style="`color: ${data.title_color};`"
            :class="{ 'lg:text-6xl': data.title_size == '6xl', 'lg:text-7xl': data.title_size == '7xl', 'lg:text-8xl': data.title_size == '8xl' }">
            {{ data.title }}
          </h1>
          <div
            id="titles-usp"
            class=""
            :style="`color: ${data.usp_color};`"
            v-html="asHtml(data.usp)" />
        </div>
        <div class="pad" />
      </div>
    </div>
    <div id="intro-ghost" />
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import ResponsiveImage from "../Fragments/ResponsiveImage.vue"
import {staticStrings} from "../staticStrings"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    ResponsiveImage,
  },

  mixins: [prismicMixins, staticStrings]
}
</script>
