<template>
  <div>
    <div
      v-for="(option,index) in options"
      :key="index"
      :class="optionClass">
      <label class="block select-none cursor-pointer">
        <input
          type="checkbox"
          :name="'checkbox' + uid"
          v-model="model"
          :value="option"
          checked
          class="clip">
        <slot :item="option">
          {{ option.title }}
        </slot>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    optionClass: {
      type: String,
      default: null
    },

    value: {
      type: [Array, Object, Number, String],
      default: null
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    uid() {
      return "-" + Math.random().toString(36).substr(2, 9)
    }
  }
}
</script>
