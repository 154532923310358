<template>
  <layout />
</template>

<script>
import Layout from "@/Shared/Layout.vue"

export default {
  components: {
    Layout
  }
}
</script>
