<template>
  <div>
    <div>
      <ul class="flex mx-auto sm:max-w-sm items-center justify-between border-b border-gray-mm-600 relative flex-wrap sm:flex-no-wrap text-sm sm:text-base relative z-10">
        <li
          v-for="(tab,index) in tabs"
          :key="index"
          class="text-center w-auto border-b-4 border-transparent hover:border-white select-none"
          :class="{ 'border-white': tab.isActive }">
          <a
            :href="tab.href"
            class="w-full py-2 inline-block"
            @click="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>

    <div class="py-6 tabs">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabs: []
    }
  },

  created () {
    this.tabs = this.$children
  },

  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name === selectedTab.name)
      })
    }
  }
}
</script>
