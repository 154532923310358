<template>
  <div class="fixed bottom-0 left-0 w-full flex items-stretch justify-center px-4 pb-4 sm:px-10 sm:pb-10 z-10">
    <a
      href="#"
      class="inline-flex items-center justify-center py-2 sm:py-4 px-3 sm:px-5 bg-white text-black text-sm leading-tight font-semibold mx-1 rounded-md">
      <span><svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="6.5"
          y="4.5"
          width="11"
          height="9"
          rx="1.5"
          fill="white"
          stroke="#949494" />
        <rect
          x="3.5"
          y="2.5"
          width="11"
          height="13"
          rx="1.5"
          fill="white"
          stroke="#636363" />
        <rect
          x="0.5"
          y="0.5"
          width="11"
          height="17"
          rx="1.5"
          fill="white"
          stroke="black" />
      </svg>
      </span>
      <span class="inline-block ml-2">{{ getStaticString("imageGallery") }}</span></a>
    <a
      :href="data.checkout_url"
      class="inline-block py-2 sm:py-4 px-3 sm:px-5 bg-yellow-mm-600 text-black text-sm leading-tight font-semibold mx-1 rounded-md text-center">{{ data.checkout_cta_text }}</a>
  </div>
</template>

<script>
import {staticStrings} from "../staticStrings"

export default {
  mixins: [staticStrings]
}
</script>
