<template>
  <div
    id="engraving-box"
    class="box rounded text-center py-8 lg:py-12 px-8 lg:px-24 bg-cover bg-center"
    :class="{ 'black': data.checkout_url !== 'https://checkout.micromilspec.com/field-testing-watch' }"
    :style="data.checkout_url == 'https://checkout.micromilspec.com/field-testing-watch' ? { backgroundImage: `url(${data.engraving_caseback_image.url})`, minHeight: '750px' } : {}">
    <CasebackEngraving
      v-if="data.checkout_url !== 'https://checkout.micromilspec.com/field-testing-watch'"
      :data="data" />
  </div>
</template>

<script>
import CasebackEngraving from "../Fragments/CasebackEngraving.vue"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    CasebackEngraving,
  },
}
</script>
