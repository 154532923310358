<template>
  <div
    class="io"
    :class="{ 'intersected': intersected }"
    :style="{ 'transition-delay': 100 * index + 'ms' }">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      observer: null,
      intersected: false
    }
  },
  mounted() {
    const interRef = document.querySelector("#showBuyButton")
    this.observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        this.intersected = true
      } else {
        this.intersected = false
      }
    })
    this.observer.observe(interRef)
  },
}
</script>
<style lang="scss" scoped>
  .io {
    opacity: 0;
    transition: opacity 550ms, transform 350ms cubic-bezier(.18,.79,.72,.99);
    transform: translate(0px, 30px);
    transition-delay: 150ms;
  }
  .intersected {
    opacity: 1;
    transform: translate(0, 0);
  }
</style>
