<template>
  <inertia-link
    :href="href"
    :class="classObject"
    class="inline-block px-4 py-3 font-semibold text-sm rounded-sm focus:outline-none focus:shadow-outline whitespace-no-wrap">
    <slot />
  </inertia-link>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Submit"
    },

    primary: {
      type: Boolean,
      default: false
    },

    secondary: {
      type: Boolean,
      default: false
    },

    tertiary: {
      type: Boolean,
      default: false
    },

    href: {
      type: String,
      default: "/"
    }
  },

  computed: {
    classObject () {
      return {
        "text-gray-800": !this.primary && !this.tertiary && !this.secondary,
        "text-white bg-blue-500 hover:bg-blue-600 border border-transparent": this.primary,
        "text-black bg-yellow-mm-600 hover:bg-yellow-700 border border-transparent": this.tertiary,
        "text-gray-800 bg-transparent hover:bg-gray-mm-200 border border-gray-mm-300": this.secondary,
      }
    }
  }
}
</script>
