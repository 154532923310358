<template>
  <div class="relative">
    <div class="absolute top-0 left-0 w-full z-10">
      <div class="container mx-auto">
        <div class="flex justify-between items-center py-8 pt-4 lg:pt-8">
          <a
            href="https://www.micromilspec.com"
            class="block w-8 h-8">
            <svg
              viewBox="0 0 26 26"
              :fill="data.top_icon_color"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.575 9.4446C20.6336 4.01384 15.9856 0 10.3705 0H9.4446V4.42583C4.01384 5.36656 0 10.0144 0 15.6295V16.5554H4.40684C5.34757 21.9862 9.99538 26 15.6114 26H16.5373V21.5753C21.9788 20.6351 26 15.9871 26 10.3705V9.4446H21.575ZM16.5373 19.7037V17.2037H14.6855V24.1047C10.504 23.7087 7.12821 20.644 6.27858 16.5554H8.79627V14.7036H1.89525C2.2913 10.5229 5.35605 7.1472 9.4446 6.29757V8.79627H11.2964V1.89525C15.4771 2.2913 18.8537 5.35605 19.7033 9.4446H17.1847V11.2964H24.1047C23.7078 15.4778 20.6357 18.8547 16.5373 19.7037Z"
              />
            </svg>
          </a>
          <a
            v-if="false"
            :href="data.checkout_url"
            class="block bg-yellow-mm-600 rounded-md py-2 px-6 text-black text-xs font-semibold">{{ getStaticString("buy") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {staticStrings} from "../staticStrings"

export default {
  mixins: [staticStrings],
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
}
</script>
