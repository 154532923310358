<template>
  <layout>
    <template slot-scope="{ addToCart, submitting }">
      <div class="container mx-auto">
        <div class="mx-auto md:max-w-5xl xl:max-w-6xl md:my-8 lg:my-16">
          <slider
            v-if="false"
            :inset-scrollbar="true"
            :data="slider">
            <template v-slot:default="{ slide, index }">
              <div>Slide {{ slide }} {{ index }}</div>
            </template>
          </slider>

          <div>
            <div class="lg:grid grid-cols-2 gap-16">
              <div>
                <div class="bg-gray-mm-600 p-3 max-w-xl mx-auto">
                  <figure class="pb-full relative">
                    <div class="absolute inset-0 flex items-center justify-center">
                      <img
                        :src="product.featured_image.startsWith('http') ? product.featured_image : asset(product.featured_image)"
                        :alt="product.name"
                        class="object-fill mx-auto">
                      <img
                        v-if="product.meta && product.meta.full_size_image"
                        :src="product.meta.full_size_image.startsWith('http') ? product.meta.full_size_image : asset(product.meta.full_size_image)"
                        :alt="product.name"
                        class="object-fill mx-auto">
                    </div>
                  </figure>
                </div>
              </div>
              <div>
                <div
                  v-if="$page.props.app.customer === null && product.requires_associated_order"
                  class="mx-auto max-w-md bg-white rounded-sm p-8 text-black my-8">
                  <h2 class="text-2xl leading-tight font-bold mb-4">
                    Which timepiece do you have?
                  </h2>
                  <p class="text-gray-mm-400">
                    This product is unique to the timepiece you bought, therefore we need to know which one you have.<br>Please sign in using the email or phone number you purchased your item with.
                  </p>

                  <auth class="mt-8" />
                </div>
                <div v-else>
                  <header class="mb-8 md:mb-12 mt-8">
                    <div class="text-gray-mm-300">
                      {{ defaultCollection.title }}
                    </div>
                    <h1 class="text-2xl sm:text-3xl md:text-5xl leading-tight mb-1 font-medium">
                      {{ product.name }}
                    </h1>
                    <div class="text-gray-mm-300 leading-tight font-medium">
                      <p>{{ product.description }}</p>
                    </div>
                  </header>
                  <div
                    v-for="(block,fbIndex) in blocks"
                    :key="`fblock-${fbIndex}`"
                    class="my-8">
                    <div v-if="block.type === 'features'">
                      <ul class="font-medium md:text-lg">
                        <li
                          v-for="(feature,fIndex) in block.content"
                          :key="fIndex"
                          class="flex items-center">
                          <span class="inline-block w-3 mr-2">
                            <svg
                              viewBox="0 0 9 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M8.42797 3.61201L5.31997 5.978V3.99H0.909973V2.674H5.31997V0.686005L8.42797 3.052V3.61201Z"
                                fill="white" />
                            </svg>
                          </span>
                          {{ feature.feature }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="my-8">
                    <div class="text-yellow-mm-600 text-lg">
                      <product-price :prices="product.prices">
                        <template slot-scope="{ priceFormatted }">
                          {{ priceFormatted }}
                        </template>
                      </product-price>
                    </div>
                    <div class="text-gray-mm-300 text-sm">
                      On delivery: 3-4 weeks
                    </div>
                  </div>

                  <div
                    v-for="(field,fIndex) in requiredFields"
                    :key="fIndex">
                    <div
                      v-if="field.type === 'project'"
                      class="my-6">
                      <order-projects>
                        <template slot-scope="{ projects }">
                          <select-field
                            :options="projects"
                            label="Design / Project"
                            placeholder="Select project"
                            @input="setProjectProperty('project', $event)" />
                        </template>
                      </order-projects>
                    </div>

                    <div
                      class="my-6"
                      v-if="field.type === 'engraving'">
                      <input-field
                        label="Engraving"
                        @input="setProjectProperty('engraving_line_1', $event)" />
                    </div>
                  </div>

                  <validation-errors />

                  <div class="flex flex-col items-center mt-8 md:mb-8">
                    <default-button
                      v-if="product.in_stock || !product.track_inventory"
                      :loading="submitting"
                      tertiary
                      class="mb-4 w-full text-base"
                      @click.prevent.native="addToCart(formData)">
                      Add to cart
                    </default-button>
                    <default-button
                      v-else-if="product.track_inventory && !product.in_stock"
                      secondary
                      class="mb-4 w-full text-base">
                      Sold out
                    </default-button>
                    <div class="font-medium text-sm">
                      Free shipping
                    </div>
                  </div>

                  <div
                    v-for="(block,bIndex) in blocks"
                    :key="`acblock-${bIndex}`">
                    <div v-if="block.type === 'accordion'">
                      <accordion
                        v-for="(item,baIndex) in block.content"
                        header-size="none"
                        :inverted="true"
                        :key="baIndex">
                        <template v-slot:header>
                          {{ item.headline }}
                        </template>
                        <template v-slot:content>
                          <div v-html="item.content" />
                        </template>
                      </accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="my-8 md:my-20">
            <div class="font-medium">
              <h2 class="sm:text-3xl leading-tight mb-1">
                Related Products
              </h2>
            </div>
            <div class="my-8 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              <product-card
                v-for="(relatedProduct,rIndex) in relatedProducts"
                :product="relatedProduct"
                :key="rIndex" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/Pages/Shop/Layout.vue"
import Auth from "@/Pages/Shop/Partials/Auth.vue"
import OrderProjects from "@/Pages/Shop/Partials/OrderProjects.vue"
import Slider from "@/Pages/Sales/Slider.vue"
import Accordion from "@/Shared/Accordion.vue"
import ProductCard from "@/Pages/Shop/Partials/ProductCard.vue"
import ProductPrice from "@/Pages/Shop/Partials/ProductPrice"

export default {
  props: {
    product: {
      type: Object,
      default () {
        return {}
      }
    },

    defaultCollection: {
      type: Object,
      default () {
        return {}
      }
    },

    relatedProducts: {
      type: Array,
      default () {
        return {}
      }
    }
  },

  components: {
    Layout,
    Auth,
    OrderProjects,
    Slider,
    Accordion,
    ProductCard,
    ProductPrice
  },

  data () {
    return {
      formData: {
        id: this.product.id,
        quantity: 1,
        product: this.product,
        properties: {}
      },

      slider: [
        {
          url: "foo"
        },
        {
          url: "bar"
        },
        {
          url: "baz"
        },
      ],

      features: [
        {
          headline: "Materials",
          content: "Our rubber straps are made uniquely for Micromilspec watches. The rubber is sourced in the deep forests of Rubberton, South Africa. The clasp is made from stainless steel and will never let you down. The strap will fit any Micromilspec watch."
        },
        {
          headline: "What’s included",
          content: "Our rubber straps are made uniquely for Micromilspec watches. The rubber is sourced in the deep forests of Rubberton, South Africa. The clasp is made from stainless steel and will never let you down. The strap will fit any Micromilspec watch."
        },
        {
          headline: "Care & Maintenance",
          content: "Our rubber straps are made uniquely for Micromilspec watches. The rubber is sourced in the deep forests of Rubberton, South Africa. The clasp is made from stainless steel and will never let you down. The strap will fit any Micromilspec watch."
        },
        {
          headline: "Warranty",
          content: "Our rubber straps are made uniquely for Micromilspec watches. The rubber is sourced in the deep forests of Rubberton, South Africa. The clasp is made from stainless steel and will never let you down. The strap will fit any Micromilspec watch."
        },
      ]
    }
  },

  methods: {
    setProjectProperty (key, value) {
      this.formData.properties[key] = value
    },

    todo () {
      console.log("Register engraving requirements somehow")
    }
  },

  computed: {
    blocks () {
      return this.product.presentation ? this.product.presentation.blocks : []
    },

    requiredFields () {
      if (this.product.meta && this.product.meta.required_fields) {
        return this.product.meta.required_fields
      }

      return []
    }
  }
}
</script>
