<template>
  <picture>
    <source
      media="(max-width:640px)"
      :srcset="mobileSrc">
    <source :srcset="defaultSrc">
    <img
      :src="defaultSrc"
      :alt="image.alt">
  </picture>
</template>

<script>
import * as prismicH from "@prismicio/helpers"

export default {
  props: {
    image: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    mobileSrc () {
      const src = prismicH.asImageSrc(this.image.mobile, {
        q: 80
      })

      return src
    },

    defaultSrc () {
      const src = prismicH.asImageSrc(this.image, {
        q: 95
      })

      return src
    }
  }
}
</script>
