<template>
  <div class="sm:px-4 mb-6 text-sm w-1/2 sm:w-1/5">
    <div class="pb-6 border-b border-gray-800 h-full">
      <div class="w-6 h-6 rounded mb-3">
        <img
          :src="icon"
          alt="">
      </div>
      <p class="font-bold mb-1">
        {{ title }}
      </p>
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },

    icon: {
      type: String,
      default: null
    },

    content: {
      type: String,
      default: null
    }
  }
}
</script>
