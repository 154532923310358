<template>
  <layout>
    <checkout-total :data="formData">
      <template v-slot:default="slotProps">
        <div class="bg-white text-black py-6 sm:py-16">
          <div class="container mx-auto">
            <div class="lg:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Personalize your watch
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  You're only a few steps away from completing your order
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Select kit"
          subtitle="Choose one out of the two options below">
          <radio-list
            :options="products"
            v-model="formData.product">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.product.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.product.id">
                  <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Selected</span>
                </div>
                <div>
                  <img
                    :src="asset(item.featured_image)"
                    :alt="item.name"
                    class="object-contain h-24">
                </div>
                <div class="flex font-semibold text-sm justify-between mt-4 mb-2 -ml-4 -mr-4">
                  <div class="w-4/12 px-4 whitespace-no-wrap">
                    {{ item.name }}
                  </div>
                  <div class="w-8/12 px-4">
                    {{ item.price | formatPrice }} <span class="text-gray-mm-300">or {{ item.price / 4 | formatPrice }} /month</span>
                  </div>
                </div>
                <p class="text-gray-mm-300">
                  {{ item.description }}
                </p>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Choose addons"
          subtitle="Optional upgrades, click to add. (Everything is included in Emerald)">
          <template v-slot:leftcol>
            <div class="block xl:-ml-20 sm:mr-12">
              <img
                :src="asset('images/tmbn/giphy.gif')"
                alt="Straps">
            </div>
          </template>
          <checkbox-list
            class="flex flex-wrap items-stretch -ml-2 -mr-2 mb-4"
            :options="addons"
            v-model="formData.addons"
            option-class="px-2 mb-4 w-1/2">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 flex text-sm sm:text-base"
                :class="{ 'bg-gray-mm-600': formData.addons.includes(item) }">
                <div>
                  <img
                    :src="asset(item.featured_image)"
                    :alt="item.name"
                    class="object-contain h-24">
                </div>
                <div class="pl-4">
                  <h3 class="font-bold">
                    {{ item.name }}
                  </h3>
                  <p class="text-gray-mm-300 text-sm">
                    {{ item.price | formatPrice }}
                  </p>
                  <in-cart
                    :item="item"
                    :cart="formData.addons" />
                </div>
              </div>
            </template>
          </checkbox-list>
        </checkout-section>
        <checkout-section
          title="Pay on your own terms"
          subtitle="No interest. No fees.">
          <radio-list
            :options="paymentOptions"
            v-model="formData.payment_option">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.payment_option.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.payment_option.id">
                  <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Selected</span>
                </div>
                <div class="flex font-bold items-center mb-2">
                  <span>{{ item.title }}</span>
                  <span class="ml-auto pl-3">{{ slotProps.total / item.terms | formatPrice }}</span>
                </div>
                <p class="text-gray-mm-300">
                  {{ item.description }}
                </p>
              </div>
            </template>
          </radio-list>
        </checkout-section>
        <checkout-section
          title="Custom Engraving"
          subtitle="Personalise your caseback"
          class="relative">
          <template v-slot:leftcol>
            <figure class="text-right sm:mr-12">
              <img
                :src="asset('images/tmbn/caseback-loupe.jpg')"
                alt=""
                class="inline-block">
            </figure>
          </template>
          <div class="relative">
            <input-field
              label="Below the TMBN heraldic symbol. Your division and position. Max 16 characters. (Example MEK4 / TRSJ)"
              placeholder="Description"
              :maxlength="16"
              v-model="formData.engraving_line_2"
              class="mb-6" />
            <input-field
              label="Outer circle, bottom. Up to 22 characters including spaces. (Example your name)"
              placeholder="Your name"
              v-model="formData.engraving_line_1"
              :maxlength="22" />
          </div>
        </checkout-section>

        <checkout-section
          title="Your Information"
          subtitle="Double and triple check">
          <input-field
            placeholder="First Name"
            v-model="formData.customer.first_name"
            class="mb-3" />
          <input-field
            placeholder="Last Name"
            v-model="formData.customer.last_name"
            class="mb-3" />
          <input-field
            placeholder="Street"
            v-model="formData.customer.address_line_1"
            class="mb-3" />
          <input-field
            placeholder="City"
            v-model="formData.customer.city"
            class="mb-3" />
          <input-field
            placeholder="Postal Code"
            v-model="formData.customer.postal_code"
            class="mb-3" />
          <select-field
            :options="countries"
            option-key="Code"
            option-value="Name"
            name="country"
            placeholder="Select country"
            v-model="formData.customer.country"
            class="mb-3" />
          <input-field
            placeholder="Phone Number"
            v-model="formData.customer.phone"
            class="mb-3" />
          <input-field
            placeholder="Email"
            v-model="formData.customer.email"
            class="mb-3" />
        </checkout-section>

        <checkout-section
          title="Relationship with TMBN"
          subtitle="Choose one">
          <radio-list
            class="mb-4"
            :options="relationshipOptions"
            v-model="formData.relationship_option">
            <template slot-scope="{ item }">
              <div class="py-2 flex items-center text-sm sm:text-base">
                <span
                  class="radio-icon mr-3"
                  :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                <span>{{ item.title }}</span>
              </div>
            </template>
          </radio-list>

          <input-field
            :placeholder="relationshipDescriptionPlaceholder"
            v-model="formData.relationship_description"
            class="mb-3" />
        </checkout-section>

        <checkout-section
          title="Delivery location"
          subtitle="Choose one"
          class="pb-24 sm:pb-32">
          <radio-list
            :options="deliveryOptions"
            v-model="formData.delivery_option">
            <template slot-scope="{ item }">
              <div class="py-2 flex items-center text-sm sm:text-base">
                <span
                  class="radio-icon mr-3"
                  :class="{ 'radio-icon--checked': formData.delivery_option.id === item.id }" />
                <span>{{ item.name }} - {{ item.description }}</span>
              </div>
            </template>
          </radio-list>

          <div class="my-16">
            <h4 class="mb-3 text-sm sm:text-base">
              Terms of purchase
            </h4>
            <div class="bg-gray-mm-600 p-4 text-white h-48 overflow-y-scroll text-sm sm:text-base">
              <terms />
            </div>
          </div>

          <div class="checkbox-list my-8 text-sm sm:text-base">
            <p class="my-3">
              <input-checkbox v-model="formData.terms">
                I have read and accept the terms of purchase.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.newsletter">
                I want to receive updates on future releases.
              </input-checkbox>
            </p>
          </div>

          <div class="my-8 content text-gray-mm-300 text-sm">
            <p>* Unpaid orders may be subject to cancellation without notice.</p>
            <p>* The Swiss Made N.3 timepiece is custom made and assembled by hand in Switzerland upon receiving your order.</p>
            <p>* All orders are binding and non-refundable.</p>
            <p>**Expected assembly and delivery time is minimum 24 weeks.**</p>
          </div>

          <validation-errors />

          <default-button
            primary
            :loading="submitting"
            @click.prevent.native="submit"
            class="w-full">
            Complete Order
          </default-button>
        </checkout-section>
      </template>
    </checkout-total>
  </layout>
</template>

<script>
import countries from "@/utils/countries.json"
import Layout from "@/Shared/Layout.vue"
import CheckoutSection from "@/Shared/CheckoutSection.vue"
import CheckoutTotal from "@/Shared/CheckoutTotal.vue"
import RadioList from "@/Shared/RadioList.vue"
import CheckboxList from "@/Shared/CheckboxList.vue"
import Terms from "@/Shared/Terms.vue"
import InCart from "@/Shared/InCart.vue"

export default {
  components: {
    Layout,
    CheckoutSection,
    CheckoutTotal,
    RadioList,
    CheckboxList,
    Terms,
    InCart
  },

  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },

    addons: {
      type: Array,
      default () {
        return []
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    },

    formDefaults: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  created () {
    try {
      window.fathom("trackPageview")
    } catch (e) {}
  },

  mounted () {
    this.formData = { ...this.formData, ...this.formDefaults }
    this.countries = countries
  },

  data () {
    return {
      formData: {
        product: {},
        payment_option: {},
        delivery_option: {},
        relationship_option: {},
        relationship_description: null,
        addons: [],
        customer: {
          first_name: null,
          last_name: null,
          address_line_1: null,
          city: null,
          postal_code: null,
          country: null,
          phone: null,
          email: null
        },
        engraving_line_1: null,
        engraving_line_2: null,
        terms: false,
        newsletter: false
      },
      relationshipOptions: [
        {
          id: "active",
          title: "Working at TMBN",
          description: "Which division do you work in?"
        },
        {
          id: "past",
          title: "Previously worked at TMBN",
          description: "Which division did you work in?"
        },
        {
          id: "invited",
          title: "Specially invited",
          description: "Name of the person who invited you"
        },
      ],
      paymentOptions: [
        {
          id: "now",
          title: "Pay now",
          description: "An invoice is sent to your email. Pay it immediately to process the order.",
          terms: 1
        }
      ],
      submitting: false,
      countries: []
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post("/checkout", this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    relationshipDescriptionPlaceholder() {
      let id = this.formData.relationship_option.id
      let active = this.relationshipOptions.find(option => option.id === id)

      if (active) {
        return active.description
      }

      return this.relationshipOptions[0].description
    }
  }
}
</script>
