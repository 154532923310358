import "@babel/polyfill"
import { App, plugin } from "@inertiajs/inertia-vue"

import PortalVue from "portal-vue"
import Vue from "vue"
import VueI18n from "vue-i18n"
import bugsnag from "@bugsnag/js"
import bugsnagVue from "@bugsnag/plugin-vue"

import "../sass/app.scss"

const bugsnagClient = bugsnag("0ca576a55bba783ec1585be60ebc48fd")
bugsnagClient.use(bugsnagVue, Vue)

const asset = (path) => {
  return window.asset_url + path
}

Vue.use(plugin)
Vue.use(PortalVue)
Vue.use(VueI18n)
Vue.mixin({ methods: { route: window.route, asset: asset } })

Vue.filter("formatPrice", (value) => {
  return new Intl.NumberFormat(window.locale, {
    style: "currency",
    currency: window.currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }).format(value)
})

Vue.filter("formatPriceWithDecimals", (value, currency) => {
  let c = currency || window.currency
  return Number(value).toLocaleString(window.locale, { style: "currency", currency: c, minimumFractionDigits: 2, maximumFractionDigits: 2 })
})

Vue.filter("splitNewline", (str) => {
  return str.split(/\r?\n/)
})

Vue.filter("portablePlainText", (blocks = []) => {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children,
      // return nothing
      if (block._type !== "block" || !block.children) {
        return ""
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join("")
    })
    // join the paragraphs leaving split by two linebreaks
    .join("\n\n")
})

const i18n = new VueI18n({
  locale: window.locale,
  fallbackLocale: window.fallback_locale,
  messages: window.messages,
})

const el = document.getElementById("app")
const files = require.context("./", true, /\.vue$/i)

const globals = require.context("./Globals", true, /\.vue$/i)
globals.keys().map(key => Vue.component(key.split("/").pop().split(".")[0], globals(key).default))

new Vue({
  i18n,
  render: h => h(App, {
    props: {
      initialPage: JSON.parse(el.dataset.page),
      resolveComponent: page => files(`./Pages/${page}.vue`).default,
    },
  })
}).$mount(el)
