<template>
  <form @submit.prevent="submit">
    <div class="sm:flex items-center">
      <input-field
        placeholder="Email"
        class="flex-grow mb-2 sm:mb-0"
        v-model="formData.email" />
      <default-button
        :loading="submitting"
        class="sm:ml-2 text-base rounded-sm w-full sm:w-auto"
        tertiary>
        {{ buttonText }}
      </default-button>
    </div>

    <div
      class="bg-green-700 text-white px-3 py-2 rounded-sm relative text-sm my-3 text-left"
      role="alert"
      v-if="success">
      {{ success }}
    </div>

    <div
      class="bg-red-700 text-white px-3 py-2 rounded-sm relative text-sm my-3 text-left"
      role="alert"
      v-if="errors">
      <div
        v-for="(error,index) in errors"
        :key="index">
        <div
          v-for="(message,key) in error"
          :key="key">
          <span>{{ message }}</span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios"

export default {
  props: {
    siteId: {
      type: Number,
      default: 1
    },

    buttonText: {
      type: String,
      default: "Sign up for access"
    }
  },

  data () {
    return {
      formData: {
        email: null,
        site_id: this.siteId
      },
      submitting: false,
      errors: null,
      success: null
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.errors = null
      axios.post("/pre-signup", this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(response => {
          this.success = response.data.success
          this.formData.email = null
          this.submitting = false
        })
        .catch(e => {
          this.errors = e.response.data.errors
          this.submitting = false
        })
    }
  }
}
</script>
