<template>
  <div
    id="specifications-box"
    class="box grey rounded">
    <div class="mobile">
      <ResponsiveImageSlider
        id="specifications"
        :label="asText(data.specifications_title)"
        :slides="data.specifications_gallery" />
    </div>
    <div class="desktop">
      <div class="label">
        {{ asText(data.specifications_title) }}
      </div>
      <div
        v-for="(item,index) in data.specifications_gallery"
        :key="index"
        class="box grey rounded"
      >
        <ResponsiveImage :image="item.image" />
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import ResponsiveImageSlider from "../Fragments/ResponsiveImageSlider.vue"
import ResponsiveImage from "../Fragments/ResponsiveImage.vue"

export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    ResponsiveImageSlider,
    ResponsiveImage
  },
}
</script>
