<template>
  <div
    id="facebook-box"
    class="box black rounded padded">
    <div class="container mx-auto">
      <div class="title">
        {{ data.facebook_title }}
      </div>
      <div
        class="content"
        v-html="asHtml(data.facebook_content)" />
      <div id="fb-container">
        <div
          class="fb-group"
          :data-href="`https://www.facebook.com/groups/${data.facebook_group_id}/`"
          :data-width="fbWidth"
          data-show-social-context="true" 
          data-show-metadata="false"
          data-skin="dark" />
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"

export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      fbWidth: 310
    }
  },
  mounted() {
    window.addEventListener("resize", this.scaleFb)
    if (window.FB) {
      window.FB.XFBML.parse()
    } else {
      console.warn("No window.FB found")
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scaleFb)
  },
  methods: {
    scaleFb() {
      //this doesn't work - but could it? The FB SDK rewrites many styles on mount
      //this.fbWidth = this.$refs["column-right"].clientWidth
    }
  },
}
</script>
