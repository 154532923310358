<template>
  <div v-if="false">
    <div class="container mx-auto">
      <div class="lg:w-1/3 mx-auto pt-16 lg:pt-24 pb-8">
        <p class="mb-3 block lg:text-lg">
          {{ signupText }}
        </p>
        <form @submit.prevent="submit">
          <div class="sm:flex items-center">
            <input-field
              :placeholder="placeholder"
              class="flex-grow mb-2 sm:mb-0"
              v-model="formData.email" />
            <default-button
              :loading="submitting"
              class="sm:ml-2 text-base rounded-sm w-full sm:w-auto"
              tertiary>
              {{ buttonText }}
            </default-button>
          </div>

          <validation-errors />
        </form>
        <div
          v-if="locale == 'nb'"
          class="mt-4 text-sm">
          Ved å klikke send godtar du <a
            :href="route('checkout.privacy-policy')"
            target="_blank"
            class="underline">personvernerklæringen</a> knyttet til kjøp hos Micromilspec AS.
        </div>
        <div
          v-else
          class="mt-4 text-sm">
          By submitting you accept the <a
            :href="route('checkout.privacy-policy')"
            target="_blank"
            class="underline">privacy policy</a> in regards to purchases from Micromilspec AS.
        </div>
      </div>
    </div>
    <div
      class="relative">
      <div class="bg-opacity-75 absolute left-0 w-full h-full bg-black z-30" />
      <slot />
    </div>
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  data () {
    return {
      formData: {
        email: null,
        site_id: this.siteId
      },
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(this.route("checkout.signup.store", { site: this.$page.props.siteId }), this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    signupText () {
      switch (this.locale) {
        case "nb":
          return "Fyll inn epost for å fortsette og lagre handlekurven din til senere."
        default:
          return "Enter your email to customize your watch"
      }
    },

    placeholder () {
      switch (this.locale) {
        case "nb":
          return "E-post"
        default:
          return "Email"
      }
    },

    buttonText () {
      switch (this.locale) {
        case "nb":
          return "Send"
        default:
          return "Submit"
      }
    },

    locale () {
      return window.locale
    }
  }
}
</script>
