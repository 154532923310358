<template>
  <hq-layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Modify Your Delivery
              </h1>
            </div>

            <form @submit.prevent="submit">
              <div class="my-8">
                <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
                  Shipping Address
                </p>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    First Name
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      placeholder="First Name"
                      name="shipping_first_name"
                      v-model="formData.shipping_first_name"
                      class="mb-3" />
                  </div>
                  <p class="text-gray-mm-300 mb-2">
                    Last Name
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      placeholder="Last Name"
                      name="shipping_last_name"
                      v-model="formData.shipping_last_name"
                      class="mb-3" />
                  </div>
                  <p class="text-gray-mm-300 mb-2">
                    Street Address
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      placeholder="Street Address"
                      name="shipping_street_address"
                      v-model="formData.shipping_address_line_1"
                      class="mb-3" />
                  </div>
                  <p class="text-gray-mm-300 mb-2">
                    Street Address Line 2
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      placeholder="Street Address Line 2"
                      name="shipping_street_address_line_2"
                      v-model="formData.shipping_address_line_2"
                      class="mb-3" />
                  </div>
                  <p class="text-gray-mm-300 mb-2">
                    Country
                  </p>
                  <div class="mb-2 w-full">
                    <select-field
                      :options="countries"
                      option-key="Code"
                      option-value="Name"
                      name="shipping_country"
                      placeholder="Choose Country"
                      v-model="formData.shipping_country"
                      class="mb-3" />
                  </div>
                  <p class="text-gray-mm-300 mb-2">
                    City
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      placeholder="City"
                      name="shipping_city"
                      v-model="formData.shipping_city"
                      class="mb-3" />
                  </div>
                  <div v-if="['US', 'CA'].includes(formData.shipping_country)">
                    <p class="text-gray-mm-300 mb-2">
                      State / Province
                    </p>
                    <div class="mb-2 w-full">
                      <input-field
                        placeholder="State"
                        name="shipping_state"
                        v-model="formData.shipping_state"
                        class="mb-3" />
                    </div>
                  </div>
                  <p class="text-gray-mm-300 mb-2">
                    Postal Code
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      placeholder="Zip Code"
                      name="shipping_postal_code"
                      v-model="formData.shipping_postal_code"
                      class="mb-3" />
                  </div>
                </div>
                <div class="mb-4">
                  <default-button
                    tertiary
                    :loading="submitting"
                    :disabled="submitting">
                    Save Changes
                  </default-button>
                </div>
                <div
                  class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center"
                  v-if="$page.props.flash.success">
                  {{ $page.props.flash.success }}
                </div>
                <validation-errors unique />
              </div>
            </form>

            <div class="my-8 text-sm">
              <nav>
                <a
                  :href="route('account.orders.show', { id: order.id })"
                  class="block p-2 border-t border-gray-mm-600 hover:underline">Return to order</a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hq-layout>
</template>

<script>
import HqLayout from "@/Shared/HqLayout.vue"
import countries from "@/utils/countries.json"

export default {
  props: {
    customer: {
      type: Object,
      default () {
        return {}
      }
    },

    order: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    HqLayout
  },

  mounted () {
    this.countries = countries

    this.formData.shipping_first_name = this.order.shipping_first_name
    this.formData.shipping_last_name = this.order.shipping_last_name
    this.formData.shipping_address_line_1 = this.order.shipping_address_line_1
    this.formData.shipping_address_line_2 = this.order.shipping_address_line_2
    this.formData.shipping_city = this.order.shipping_city
    this.formData.shipping_state = this.order.shipping_state
    this.formData.shipping_postal_code = this.order.shipping_postal_code
    this.formData.shipping_country = this.order.shipping_country
  },

  data () {
    return {
      countries: [],
      formData: {
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_address_line_1: null,
        shipping_address_line_2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_postal_code: null,
        shipping_country: null,
      },
      error: null,
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.patch(`/orders/${this.order.id}/edit-address`, this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
