<template>
  <div
    v-if="data.in_box_desktop.url || data.in_box_mobile.url"
    class="box aspect-[16/9] rounded relative">
    <figure class="hidden lg:block">
      <img
        :src="data.in_box_desktop.url"
        :alt="data.in_box_desktop.alt"
        width="1920"
        height="1080">
    </figure>
    <figure class="lg:hidden">
      <img
        :src="data.in_box_mobile.url"
        :alt="data.in_box_mobile.alt"
        width="870"
        height="1260">
    </figure>

    <div class="absolute py-4 lg:py-8 px-4 lg:px-8 xl:px-20 top-0 left-0">
      <h2 class="text-2xl leading-tight text-white">
        In the box
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
