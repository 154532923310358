<template>
  <span
    v-if="inCart"
    class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2"><slot /></span>
</template>

<script>
export default {
  props: {
    cart: {
      type: Array,
      default () {
        return []
      }
    },

    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    inCart () {
      let res = this.cart.find(item => item.id === this.item.id)
      if (res) return true

      return false
    }
  }
}
</script>
