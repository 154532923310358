<template>
  <checkout-total :data="formData">
    <template v-slot:copy>
      Your total
    </template>
    <template v-slot:default="slotProps">
      <div v-if="step === 1">
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Customize your watch
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  You are only a few steps away from completing your order
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Choose kit"
          subtitle="One of four options">
          <p class="mb-8 text-sm">
            All kits include personal engraving, display box, tool for changing straps, proof of authenticity by MICROMILSPEC. All straps will fit any wrist
          </p>
          <radio-list
            :options="products"
            v-model="formData.product">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.product.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.product.id">
                  <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Selected</span>
                </div>
                <div class="flex items-center text-sm justify-between mt-4 mb-2 -ml-4 -mr-4">
                  <div class="w-5/12 px-4">
                    <zoom-image>
                      <img
                        v-if="item.featured_image"
                        :src="item.featured_image"
                        :alt="item.name"
                        class="object-contain h-40 mx-auto">
                      <template v-slot:zoomed>
                        <div>
                          <p class="font-xl block mb-4 text-center">
                            {{ item.name }}
                          </p>
                          <img
                            :src="item.meta.full_size_image"
                            alt="">
                        </div>
                      </template>
                    </zoom-image>
                  </div>
                  <div class="w-7/12 px-4">
                    <h2 class="text-lg leading-tight text-white mb-2">
                      {{ item.name }}
                    </h2>
                    <p class="text-gray-mm-300 block mb-4">
                      {{ item.description }}
                    </p>
                    <p class="text-yellow-mm-600">
                      {{ item.price | formatPrice }} incl. tax,
                    </p> or <span>{{ item.price / 6 | formatPrice }}</span> /mo for six months.
                  </div>
                </div>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Add extra straps"
          subtitle="Click to add strap">
          <template v-slot:rightcol>
            <div class="block xl:-mr-20 sm:mt-12 sm:mr-12">
              <video
                autoplay
                loop
                muted
                playsinline>
                <source
                  :src="formDefaults.checkout.strap_video_url"
                  type="video/mp4">
              </video>
            </div>
          </template>
          <p class="mb-8 text-sm text-gray-mm-300">
            (Rubber, leather and steel bracelet are included in the kit)
          </p>
          <checkbox-list
            class="flex flex-wrap items-stretch -ml-2 -mr-2 mb-4"
            :options="addons"
            v-model="formData.addons"
            option-class="px-2 mb-4 w-1/2">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 flex text-sm sm:text-base"
                :class="{ 'bg-gray-mm-600': formData.addons.includes(item) }">
                <div>
                  <img
                    :src="asset(item.featured_image)"
                    :alt="item.name"
                    class="object-contain h-24">
                </div>
                <div class="pl-4">
                  <h3 class="font-bold leading-tight">
                    {{ item.name }}
                  </h3>
                  <p class="text-gray-mm-300 text-sm">
                    {{ item.price | formatPrice }}
                  </p>
                  <in-cart
                    :item="item"
                    :cart="formData.addons">
                    Selected
                  </in-cart>
                </div>
              </div>
            </template>
          </checkbox-list>
        </checkout-section>

        <checkout-engraving
          :value="formData.engraving_line_1"
          :image="formDefaults.checkout.caseback_image_url"
          :is-dark="false"
          caseback-kind="open-caseback-silver">
          <div class="lg:max-w-md py-8 sm:py-12">
            <h3 class="text-xl lg:text-3xl leading-tight mb-4">
              Choose your unique engraving
            </h3>
            <p class="text-gray-mm-900 lg:text-lg mb-6">
              You can modify your engraving any time before production starts at https://hq.micromilspec.com
            </p>
            <p class="text-gray-mm-900 mb-3">
              Your personal engraving
            </p>
            <input-field
              placeholder="Your engraving here"
              :maxlength="30"
              v-model="formData.engraving_line_1"
              class="mb-6"
              bg-class="bg-gray-mm-500" />
            <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
              Ex. Name, personal motto, dates or important milestones.
            </p>
          </div>
        </checkout-engraving>

        <div class="container mx-auto">
          <div class="mx-auto lg:w-1/3 pt-8 pb-24 sm:pb-32">
            <default-button
              tertiary
              @click.prevent.native="step = 2"
              class="w-full">
              Next step
            </default-button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Step 2 of 3
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  Your only a few steps away from completing your order
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Contact information"
          subtitle="Double- and triplecheck">
          <input-field
            placeholder="First Name"
            name="firstname"
            v-model="formData.customer.first_name"
            class="mb-3" />
          <input-field
            placeholder="Last Name"
            name="surname"
            v-model="formData.customer.last_name"
            class="mb-3" />
          <input-field
            placeholder="Street Address"
            name="street_address"
            v-model="formData.customer.address_line_1"
            class="mb-3" />
          <select-field
            :options="countries"
            option-key="Code"
            option-value="Name"
            name="country"
            placeholder="Choose Country"
            v-model="formData.customer.country"
            class="mb-3" />
          <input-field
            placeholder="City"
            name="city"
            v-model="formData.customer.city"
            class="mb-3" />
          <input-field
            v-if="formData.customer.country === 'US'"
            placeholder="State"
            name="state"
            v-model="formData.customer.state"
            class="mb-3" />
          <input-field
            placeholder="Zip Code"
            name="postal_code"
            v-model="formData.customer.postal_code"
            class="mb-3" />
          <input-field
            placeholder="Phone"
            name="phone"
            v-model="formData.customer.phone"
            class="mb-3" />
          <input-field
            placeholder="Email"
            name="email"
            v-model="formData.customer.email"
            class="mb-3" />
        </checkout-section>

        <checkout-section
          title="Shipping information"
          subtitle="Where should we send your watch?">
          <input-checkbox
            v-model="formData.use_separate_shipping_address"
            class="mb-3">
            Use a separate shipping address
          </input-checkbox>
          <div v-if="formData.use_separate_shipping_address">
            <input-field
              placeholder="First Name"
              name="shipping_first_name"
              v-model="formData.shipping_first_name"
              class="mb-3" />
            <input-field
              placeholder="Last Name"
              name="shipping_last_name"
              v-model="formData.shipping_last_name"
              class="mb-3" />
            <input-field
              placeholder="Street Address"
              name="shipping_street_address"
              v-model="formData.shipping_address_line_1"
              class="mb-3" />
            <input-field
              placeholder="Street Address Line 2"
              name="shipping_street_address_line_2"
              v-model="formData.shipping_address_line_2"
              class="mb-3" />
            <select-field
              :options="countries"
              option-key="Code"
              option-value="Name"
              name="shipping_country"
              placeholder="Choose Country"
              v-model="formData.shipping_country"
              class="mb-3" />
            <input-field
              placeholder="City"
              name="shipping_city"
              v-model="formData.shipping_city"
              class="mb-3" />
            <input-field
              v-if="formData.shipping_country === 'US'"
              placeholder="State"
              name="shipping_state"
              v-model="formData.shipping_state"
              class="mb-3" />
            <input-field
              placeholder="Zip Code"
              name="shipping_postal_code"
              v-model="formData.shipping_postal_code"
              class="mb-3" />
          </div>
        </checkout-section>

        <checkout-section
          title="Your connection to the AWACS community"
          subtitle="Choose one">
          <radio-list
            class="mb-4"
            :options="relationshipOptions"
            v-model="formData.relationship_option">
            <template slot-scope="{ item }">
              <div class="py-2 flex items-center text-sm sm:text-base">
                <span
                  class="radio-icon mr-3"
                  :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                <span>{{ item.title }}</span>
              </div>
            </template>
          </radio-list>

          <input-field
            v-if="formData.relationship_option.id === 'other'"
            :placeholder="relationshipDescriptionPlaceholder"
            v-model="formData.relationship_description"
            class="mb-3" />
        </checkout-section>

        <checkout-section
          title="Shipping Option"
          subtitle="Choose one">
          <radio-list
            :options="deliveryOptions"
            v-model="formData.delivery_option">
            <template slot-scope="{ item }">
              <div class="py-2 flex items-center text-sm sm:text-base">
                <span
                  class="radio-icon mr-3"
                  :class="{ 'radio-icon--checked': formData.delivery_option.id === item.id }" />
                <span>{{ item.name }} - {{ item.description }}</span>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Pay at your own pace"
          subtitle="No fees. No interest."
          class="pb-24 sm:pb-32">
          <radio-list
            :options="paymentOptions"
            v-model="formData.payment_option">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.payment_option.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.payment_option.id">
                  <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Selected</span>
                </div>
                <div class="flex font-bold items-center mb-2">
                  <span>{{ item.title }}</span>
                  <span class="ml-auto pl-3">{{ slotProps.total / item.terms | formatPrice }}</span>
                </div>
                <p class="text-gray-mm-300">
                  {{ item.description }}
                </p>
              </div>
            </template>
          </radio-list>

          <div v-if="paymentMethods.length > 1">
            <div class="my-16">
              <h4 class="mb-3 text-sm sm:text-base">
                Payment Method
              </h4>
            </div>
            <radio-list
              :options="paymentMethods"
              v-model="formData.payment_method">
              <template slot-scope="{ item }">
                <div
                  class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                  :class="{ 'bg-gray-mm-600': item.id === formData.payment_method }">
                  <div
                    class="absolute top-0 right-0 mx-4 -mt-5"
                    v-if="item === formData.payment_method">
                    <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Valgt</span>
                  </div>
                  <div class="flex font-bold items-center mb-2">
                    <span>{{ item }}</span>
                  </div>
                </div>
              </template>
            </radio-list>
          </div>

          <div class="my-16">
            <h4 class="mb-3 text-sm sm:text-base">
              Terms of purchase
            </h4>
            <div class="bg-gray-mm-600 p-4 text-white h-48 overflow-y-scroll text-sm sm:text-base">
              <div v-html="salesTerms" />
            </div>
          </div>

          <div class="checkbox-list my-8 text-sm sm:text-base">
            <p class="my-3">
              <input-checkbox v-model="formData.terms">
                I have read and accepted the terms of purchase.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.privacy_terms">
                I accept the <a
                  :href="route('checkout.privacy-policy')"
                  target="_blank"
                  class="underline">privacy policy</a> in regards to purchases from Micromilspec AS.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.newsletter">
                I wish to receive information of future launches.
              </input-checkbox>
            </p>
          </div>

          <div class="my-8 content text-gray-mm-300 text-sm">
            <p>* Unpaid orders can be cancelled without notice by MICROMILSPEC.</p>
            <p>* This timepiece is custom made and assembled by hand in Switzerland. All orders are binding and non-refundable at the point of production start.</p>
            <p>* Estimated production and delivery time is minimum 24 weeks. In addition,  another 60 days of potential delays due to Covid-19 may occur.</p>
          </div>

          <validation-errors />

          <default-button
            tertiary
            :loading="submitting"
            @click.prevent.native="submit"
            class="w-full mb-2">
            Complete order
          </default-button>
          <default-button
            @click.prevent.native="step = 1"
            class="w-full text-gray-mm-300">
            Go back
          </default-button>
        </checkout-section>
      </div>
    </template>
  </checkout-total>
</template>

<script>
import countries from "@/utils/countries.json"
import provinces from "@/utils/canadian-provinces.json"
import CheckoutSection from "@/Shared/CheckoutSection.vue"
import CheckoutTotal from "@/Shared/CheckoutTotal.vue"
import RadioList from "@/Shared/RadioList.vue"
import CheckboxList from "@/Shared/CheckboxList.vue"
import InCart from "@/Shared/InCart.vue"
import CheckoutEngraving from "@/Shared/CheckoutEngraving.vue"
import ZoomImage from "@/Shared/ZoomImage.vue"

export default {
  components: {
    CheckoutSection,
    CheckoutTotal,
    RadioList,
    CheckboxList,
    InCart,
    CheckoutEngraving,
    ZoomImage,
  },

  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },

    addons: {
      type: Array,
      default () {
        return []
      }
    },

    paymentMethods: {
      type: Array,
      default () {
        return []
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    },

    formDefaults: {
      type: Object,
      default () {
        return {}
      }
    },

    siteId: {
      type: String,
      default: null
    },

    salesTerms: {
      type: String,
      default: null
    }
  },

  mounted () {
    this.formData = { ...this.formData, ...this.formDefaults }
    this.countries = countries
    this.provinces = provinces
  },

  data () {
    return {
      step: 1,
      checkoutComponent: "checkout-default-english",
      formData: {
        product: {},
        payment_option: {},
        payment_method: {},
        delivery_option: {},
        relationship_option: {},
        relationship_description: null,
        addons: [],
        customer: {
          first_name: null,
          last_name: null,
          address_line_1: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          phone: null,
          email: null,
        },
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_address_line_1: null,
        shipping_address_line_2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_postal_code: null,
        shipping_country: null,
        engraving_line_1: null,
        engraving_line_2: null,
        terms: false,
        privacy_terms: false,
        newsletter: false,
        use_separate_shipping_address: false
      },
      relationshipOptions: [
        {
          id: "active",
          title: "In active duty",
          description: "Which division do you work in?"
        },
        {
          id: "past",
          title: "Veteran",
          description: "Which division did you work in?"
        },
        {
          id: "invited",
          title: "Specially invited",
          description: "Name of the person who invited you"
        },
        {
          id: "other",
          title: "Other",
          description: "Enter your connection"
        }
      ],
      paymentOptions: [
        {
          id: "delayed",
          title: "Pay in 45 days",
          description: "An invoice due in 45 days will be sent to your email.",
          terms: 1
        },
        {
          id: "two",
          title: "Split payment in two",
          description: "Pay half in 45 days. A second invoice will be sent due in 30 days after the first one.",
          terms: 2
        },
        {
          id: "six",
          title: "Six part payments",
          description: "Pay 1/6 in 45 days. Over the next 6 months you will receive 5 additional invoices.",
          terms: 6
        },
      ],
      submitting: false,
      countries: []
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(`/${this.siteId}`, this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    relationshipDescriptionPlaceholder() {
      let id = this.formData.relationship_option.id
      let active = this.relationshipOptions.find(option => option.id === id)

      if (active) {
        return active.description
      }

      return this.relationshipOptions[0].description
    },

    caProvinces () {
      return Object.keys(provinces).map(key => {
        return {
          key: key,
          value: provinces[key]
        }
      })
    }
  },

  watch: {
    step () {
      window.scrollTo(0,0)
    }
  }
}
</script>
