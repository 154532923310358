<template>
  <div class="text-sm block text-center py-3">
    <span class="text-yellow-mm-600">{{ remaining }}</span> / {{ quota }} klokker tilgjengelig
  </div>
</template>

<script>
import axios from "axios"

export default {
  props: {
    quota: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      count: 0
    }
  },

  mounted () {
    this.fetchData()

    setInterval(() => {
      this.fetchData()
    }, 30000)
  },

  methods: {
    fetchData () {
      axios.get("/nansen/order-count")
        .then(response => {
          this.count = response.data.count
        })
    }
  },

  computed: {
    remaining () {
      return this.count <= this.quota ? (this.quota - this.count) : 0
    }
  }
}
</script>
