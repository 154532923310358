<template>
  <div
    id="turntable-box"
    class="box white rounded p-4 lg:p-0"
    @click.prevent="activeModal = `turntable`">
    <OpenButton />
    <Turntable
      mode="video"
      :manifest="turntableManifest" />
    <div
      id="turntable-more"
      class="text-2xl">
      {{ getStaticString("interactive360Viewing") }}
    </div>
    <SalesModal
      :open="activeModal === 'turntable'"
      @close="activeModal = null">
      <div
        id="turntable-modal"
        class="container mx-auto">
        <div class="copy text-center flex flex-col justify-center py-12">
          <h1 class="text-2xl mb-4 font-bold leading-tight">
            {{ getStaticString("interactiveView") }}
          </h1>
          <h2 class="content text-gray-mm-900 text-lg">
            {{ getStaticString("moveSliderToRotate") }}
          </h2>
          <Turntable
            mode="sequence"
            :manifest="turntableManifest" />
        </div>
      </div>
    </SalesModal>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import Turntable from "../Turntable/Turntable.vue"
import OpenButton from "../Fragments/OpenButton.vue"
import SalesModal from "../Fragments/SalesModal.vue"

const TURNTABLE_ASSET_HOST = "https://micromilspec-assets.s3.eu-north-1.amazonaws.com/turntables"
const DEFAULT_TURNTABLE_SIZES = [["large",804,900]]
const DEFAULT_TURNTABLE_LENGTH = 45
const DEFAULT_TURNTABLE_LABEL_LEFT = "Front"
const DEFAULT_TURNTABLE_LABEL_RIGHT = "Back"

export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      activeModal: null,
    }
  },

  computed: {
    turntableManifest: function () {
      const id = this.data.turntable_id

      let sizes = DEFAULT_TURNTABLE_SIZES

      if (this.data.turntable_sizes) {
        try {
          sizes = JSON.parse(this.data.turntable_sizes)
        } catch(e) {
          console.warn(`Invalid turntable sizes json: ${this.data.turntable_sizes}`)
        }
      }

      return {
        left: this.data.turntable_label_left || DEFAULT_TURNTABLE_LABEL_LEFT,
        right: this.data.turntable_label_right || DEFAULT_TURNTABLE_LABEL_RIGHT,
        length: this.data.turntable_length || DEFAULT_TURNTABLE_LENGTH,
        sizes,
        video: `${TURNTABLE_ASSET_HOST}/${id}/turntable/video.mp4`,
        root: `${TURNTABLE_ASSET_HOST}/${id}/turntable/frames`
      }
    }
  },

  components: {
    Turntable,
    OpenButton,
    SalesModal
  }
}
</script>

