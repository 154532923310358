<template>
  <div
    id="gallery-box"
    class="box black rounded">
    <ResponsiveImageSlider
      id="gallery"
      :label="asText(data.gallery_title)"
      :slides="data.gallery" />
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import ResponsiveImageSlider from "../Fragments/ResponsiveImageSlider.vue"

export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    ResponsiveImageSlider
  },
}
</script>
