<template>
  <hq-layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Modify Certificate Owner
              </h1>
            </div>

            <form @submit.prevent="submit">
              <div class="my-8">
                <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
                  Certificate of authenticity
                </p>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    Name
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      v-model="formData.certificate_name"
                      type="text"
                      placeholder="Name"
                      autofocus />
                  </div>
                </div>
                <div class="mb-4">
                  <default-button
                    tertiary
                    :loading="submitting"
                    :disabled="submitting">
                    Save Changes
                  </default-button>
                </div>
                <div
                  class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center"
                  v-if="$page.props.flash.success">
                  {{ $page.props.flash.success }}
                </div>
                <validation-errors unique />
              </div>
            </form>

            <div class="my-8 text-sm">
              <nav>
                <a
                  :href="route('account.orders.show', { id: order.id })"
                  class="block p-2 border-t border-gray-mm-600 hover:underline">Return to order</a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hq-layout>
</template>

<script>
import HqLayout from "@/Shared/HqLayout.vue"

export default {
  props: {
    customer: {
      type: Object,
      default () {
        return {}
      }
    },

    order: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    HqLayout
  },

  mounted () {
    this.formData.certificate_name = this.order.meta.certificate_name || `${this.customer.first_name} ${this.customer.last_name}`
  },

  data () {
    return {
      formData: {
        certificate_name: null
      },
      error: null,
      submitting: false,
      editMode: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.patch(`/orders/${this.order.id}/edit-certificate`, { certificate_name: this.formData.certificate_name })
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
