<template>
  <div
    class="fixed top-0 right-0 text-sm">
    <transition
      @before-leave="cardLeaving = true"
      @after-leave="cardLeaving = false"
      enter-active-class="transition-all transition-fast ease-out-quad"
      leave-active-class="transition-all transition-fast ease-in-quad"
      enter-class="opacity-0 scale-70"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-70"
      appear
    >
      <div
        v-if="visible && $page.props.flash.success"
        class="bg-green-500 rounded shadow-lg text-white px-4 py-3 m-6 w-64 font-semibold">
        {{ $page.props.flash.success }}
      </div>
      <div
        v-if="visible && $page.props.flash.error"
        class="bg-red-500 rounded shadow-lg text-white px-4 py-3 m-6 w-64 font-semibold">
        {{ $page.props.flash.error }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      visible: true
    }
  },

  computed: {
    page () {
      return this.$page
    }
  },

  mounted () {
    this.resetTimer()
  },

  methods: {
    resetTimer () {
      this.visible = true

      setTimeout(() => {
        this.visible = false
      }, 5000)
    }
  },

  watch: {
    "$page.props.flash" () {
      this.resetTimer()
    }
  }
}
</script>
