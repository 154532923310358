<template>
  <div class="grid justify-items-center items-center h-full">
    <div class="text-center">
      <div class="flex uppercase">
        <div class="flex flex-col items-center justify-center mr-6">
          <span :class="numberClass">
            {{ days }}
          </span>
          {{ getStaticString('days') }}
        </div>
        <div class="flex flex-col items-center justify-center mr-6">
          <span :class="numberClass">
            {{ hours }}
          </span>
          {{ getStaticString('hours') }}
        </div>
        <div class="flex flex-col items-center justify-center mr-6">
          <span :class="numberClass">
            {{ minutes }}
          </span>
          {{ getStaticString('minutes') }}
        </div>
        <div class="flex flex-col items-center justify-center">
          <span :class="numberClass">
            {{ seconds }}
          </span>
          {{ getStaticString('seconds') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { staticStrings } from "../staticStrings"

export default {
  mixins: [staticStrings],

  props: {
    date: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: null
    },

    numberClass: {
      type: String,
      default: "text-2xl"
    }
  },

  data() {
   return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
   }
  },

  methods: {
    countdown() {
      var countDownDate = new Date(this.date).getTime()

      const x = setInterval(() => {
        var now = new Date().getTime()
        var distance = countDownDate - now
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
      }, 1000)
      return
    }
  },
  created(){
    this.countdown()
  },
}
</script>
