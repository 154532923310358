<template>
  <div
    ref="frame"
    class="frame">
    <canvas
      ref="canvas"
      class="canvas" />
  </div>
</template>

<script>
import { ImageSequence } from "./ImageSequence.js"

export default {
  name: "Sequence",
  props: ["manifest", "time"],
  created() {
    this.imageSequence = new ImageSequence(this.manifest)
  },
  mounted() {
    this.imageSequence.mount(this.$refs.frame, this.$refs.canvas)
  },
  beforeDestroy() {
    this.imageSequence.destroy()
  },
  watch: {
    time: function(newTime) {
      this.imageSequence.setProgress(newTime)
    }
  }
}
</script>

<style lang="scss" scoped>
.ghost {
  position: relative;
}

.frame {
  width: 100%;
  position: relative;

  .canvas {
    position: absolute;
    left: 0px;
    transform-origin: 0% 0%;
  }
}
</style>
