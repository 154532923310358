<template>
  <div
    id="more-info-box"
    class="box rounded bg-yellow-mm-600 text-black py-8 lg:py-12">
    <div class="container mx-auto">
      <div class="max-w-lg mx-auto font-bold">
        <div class="copy lg:text-center">
          <span
            class="text-2xl leading-tight"
            v-html="asHtml(data.more_info_title)" />
          <span
            class="text-2xl leading-tight text-black text-opacity-50"
            v-html="asHtml(data.more_info_subtitle)" />
        </div>

        <div id="more-info-menu">
          <div
            v-for="(item,index) in data.more_info_sections"
            :key="index"
          >
            <SalesModal
              :open="activeModal === `more-info-${index}`"
              @close="activeModal = null">
              <div id="more-info-modal-content">
                <figure
                  v-if="item.top_image.url"
                  class="top-image">
                  <ResponsiveImage :image="item.top_image" />
                </figure>
                <div
                  v-else
                  class="space" />
                <div class="copy container mx-auto text-2xl leading-tight">
                  <span v-html="asHtml(item.more_info_section_title)" />
                  <div
                    class="content text-gray-mm-900"
                    v-html="asHtml(item.more_info_section_body)" />
                </div>
                <figure
                  v-if="item.bottom_image.url"
                  class="bottom-image">
                  <ResponsiveImage :image="item.bottom_image" />
                </figure>
              </div>
            </SalesModal>
            <a
              href="#"
              @click.prevent="activeModal = `more-info-${index}`"
              class="py-4 border-b border-gray-mm-500 flex items-center justify-between">
              <span
                class=""
                v-html="asHtml(item.more_info_section_title)" />
              <MoreInfoChevron />
            </a>
          </div>

          <SalesModal
            :open="activeModal === 'faq'"
            @close="activeModal = null">
            <div
              id="faq-modal"
              class="container mx-auto h-full lg:h-auto">
              <div class="flex flex-col justify-center py-12">
                <span
                  class="text-2xl mb-4 leading-tight text-white"
                  v-html="asHtml(data.faq_title)" />
                <div
                  class="content text-2xl leading-tight text-gray-mm-900"
                  v-html="asHtml(data.faq_body)" />
                <div class="entries my-8">
                  <accordion
                    v-for="(entry,index) in data.faq_entries"
                    :key="`faq-${index}`"
                    :inverted="true"
                  >
                    <template slot="header">
                      <h3 class="text-xl text-white">
                        {{ entry.question }}
                      </h3>
                    </template>
                    <template slot="content">
                      <div
                        class="content text-gray-mm-900"
                        v-html="asHtml(entry.answer)" />
                    </template>
                  </accordion>
                </div>
              </div>
            </div>
          </SalesModal>
          <a
            href="#"
            @click.prevent="activeModal = 'faq'"
            class="py-4 border-b border-gray-mm-500 flex items-center justify-between">
            <span
              class=""
              v-html="asHtml(data.faq_title)" />
            <MoreInfoChevron />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import MoreInfoChevron from "../Fragments/MoreInfoChevron.vue"
import Accordion from "@/Shared/Accordion.vue"
import SalesModal from "../Fragments/SalesModal.vue"
import ResponsiveImage from "../Fragments/ResponsiveImage.vue"

export default {
  mixins: [prismicMixins],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      activeModal: null,
    }
  },

  components: {
    MoreInfoChevron,
    Accordion,
    SalesModal,
    ResponsiveImage
  }
}
</script>
