<script>
import axios from "axios"

export default {
  created () {
    axios.get("/customer/projects", {
      withCredentials: true
    }).then(response => {
      this.projects = response.data.data
    })
  },

  data () {
    return {
      projects: []
    }
  },

  render () {
    return this.$scopedSlots.default({
      projects: this.projects
    })
  }
}
</script>
