<template>
  <div v-show="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },

    selected: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: this.selected
    }
  },

  computed: {
    href() {
      return "#" + this.name.toLowerCase().replace(/ /g, "-")
    }
  }
}
</script>
