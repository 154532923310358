<template>
  <div ref="message">
    <div
      v-if="Object.keys($page.props.errors).length !== 0"
      class="bg-red-700 text-white px-3 py-2 rounded-sm relative text-sm my-2"
      role="alert">
      <div v-if="!unique">
        <div
          v-for="(error,index) in $page.props.errors"
          :key="index">
          <div
            v-for="(value,vIndex) in error"
            :key="vIndex">
            {{ value }}
          </div>
        </div>
      </div>
      <div v-else>
        <div
          v-for="(error,index) in uniqueErrors"
          :key="index">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    "$page.props.errors" (value) {
      if (Object.keys(value).length) {
        this.$nextTick(() => {
          this.$refs.message.scrollIntoView()
        })
      }
    }
  },

  props: {
    unique: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    uniqueErrors() {
      let errors = []
      Object.keys(this.$page.props.errors).map((error) => {
        let item = this.$page.props.errors[error]
        item.map(value => errors.push(value))
      })

      return errors.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
    }
  }
}
</script>
