<template>
  <div
    class="glide relative bg-black"
    ref="glide">
    <div
      class="glide__track"
      data-glide-el="track">
      <ul class="glide__slides">
        <li
          class="glide__slide select-none cursor-pointer"
          v-for="(item,index) in items"
          :key="index">
          <slot :item="item">
            <div class="p-12 bg-yellow-400">
              0
            </div>
          </slot>
        </li>
      </ul>
    </div>
    <div class="absolute inset-0">
      <div class="container mx-auto relative">
        <div class="mt-4 md:mt-12 mb-4 sm:mb-6 leading-tight">
          <h2 class="text-sm md:text-2xl text-white font-medium">
            Image Gallery
          </h2>
          <p class="text-sm md:text-2xl text-gray-mm-300 font-medium">
            Tap to explore
          </p>
        </div>
        <div
          class="glide__bullets"
          data-glide-el="controls[nav]">
          <button
            class="glide__bullet"
            v-for="(item,index) in items"
            :key="index"
            :data-glide-dir="'=' + index" />
        </div>
      </div>
      <div
        class="glide__controls"
        data-glide-el="controls">
        <button
          class="glide__arrow glide__arrow--left"
          data-glide-dir="<" />
        <button
          class="glide__arrow glide__arrow--right"
          data-glide-dir=">" />
      </div>
    </div>
  </div>
</template>

<script>
import Glide, { Controls, Breakpoints, Anchors, Swipe } from "@glidejs/glide/dist/glide.modular.esm"

export default {
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },

  mounted () {
    new Glide(this.$refs.glide, {
      gap: 0
    }).mount({ Controls, Breakpoints, Anchors, Swipe })
  }
}
</script>
