<template>
  <div
    id="intro-text"
    class="box black rounded">
    <div class="container mx-auto">
      <div class="copy font-bold py-8 lg:py-24">
        <div class="inner">
          <h2 class="text-2xl leading-tight mb-6">
            {{ asText(data.intro_headline) }}
          </h2>
          <div
            class="content"
            v-html="asHtml(data.intro_content)" />
          <accordion
            class="accordion border-t border-gray-mm-500"
            :inverted="true"
          >
            <template slot="header">
              <div class="hero-question">
                <img :src="asset('images/sales/info-icon.svg')">
                <div class="">
                  {{ data.intro_hero_question }}
                </div>
              </div>
            </template>
            <template slot="content">
              <div
                class="content text-gray-mm-900"
                v-html="asHtml(data.intro_hero_answer)" />
            </template>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import Accordion from "@/Shared/Accordion.vue"

export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      expanded: false
    }
  },
  components: {
    Accordion,
  }
}
</script>
