<template>
  <hq-layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="lg:w-3/12 xl:w-1/3 mx-auto">
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Modify Timepiece
              </h1>
            </div>
            <div class="my-8 text-sm">
              <nav>
                <a
                  :href="route('account.orders.show', { id: order.id })"
                  class="block py-2 border-t border-gray-mm-600 hover:underline">Return to order</a>
              </nav>

              <div
                class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center mt-8"
                v-if="$page.props.flash.success">
                {{ $page.props.flash.success }}
              </div>
            </div>
          </div>
        </div>
        <form @submit.prevent="submit">
          <div v-if="sanityData">
            <div
              v-for="block in sanityData.page1Blocks"
              :key="block._key"
              class="loop">
              <div v-if="block._type === 'checkoutEngravingWithRelationship'">
                <checkout-section
                  :title="block.title ? block.title : 'Your connection to the unit'"
                  :subtitle="block.subtitle ? block.subtitle : 'Choose one'">
                  <select-field-object
                    v-if="block.relationshipSelectStyle == 'select'"
                    :options="block.options"
                    v-model="formData.relationship_option"
                    placeholder="Choose option"
                    class="mb-4">
                    <template slot-scope="{ item }">
                      {{ item.title }}
                    </template>
                  </select-field-object>
                  <radio-list
                    v-else
                    class="mb-4"
                    :options="block.options"
                    v-model="formData.relationship_option">
                    <template slot-scope="{ item }">
                      <div class="py-2 flex items-center text-sm sm:text-base">
                        <span
                          class="radio-icon mr-3"
                          :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                        <span>{{ item.title }}</span>
                      </div>
                    </template>
                  </radio-list>

                  <div v-if="formData.relationship_option && ['other', 'Other'].includes(formData.relationship_option.id)">
                    <input-field
                      placeholder="Enter your connection"
                      v-model="formData.relationship_description"
                      class="mb-3" />
                  </div>

                  <div
                    class="content text-sm"
                    v-if="block.helpText">
                    <block-content
                      :blocks="block.helpText" />
                  </div>
                </checkout-section>

                <checkout-engraving
                  :value="formData.engraving_line_1"
                  :image="formData.relationship_option.id ? formData.relationship_option.image : block.casebackImage"
                  :is-dark="false"
                  :caseback-kind="block.engravingStyle">
                  <div class="lg:max-w-md py-8 sm:py-12">
                    <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                      Choose your unique engraving
                    </h3>
                    <p class="text-gray-mm-900 lg:text-lg mb-6">
                      You can modify your engraving any time before production starts at https://hq.micromilspec.com
                    </p>
                    <label
                      for="engraving_line_1"
                      class="text-gray-mm-900 mb-3 inline-block">
                      Your personal engraving (optional)
                    </label>
                    <input-field
                      placeholder="Your engraving here (optional)"
                      :maxlength="block.engravingLine1Max || 30"
                      v-model="formData.engraving_line_1"
                      class="mb-6"
                      name="engraving_line_1"
                      bg-class="bg-gray-mm-500" />
                    <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
                      Ex. Name, personal motto, dates or important milestones.
                    </p>
                  </div>
                </checkout-engraving>
              </div>
              <div v-if="block._type === 'checkoutRelationship'">
                <checkout-section
                  :title="block.title ? block.title : 'Your connection to the unit'"
                  :subtitle="block.subtitle ? block.subtitle : 'Choose one'">
                  <select-field-object
                    v-if="block.relationshipSelectStyle == 'select'"
                    :options="block.options"
                    v-model="formData.relationship_option"
                    placeholder="Choose one"
                    class="mb-4">
                    <template slot-scope="{ item }">
                      {{ item.title }}
                    </template>
                  </select-field-object>
                  <radio-list
                    v-else
                    class="mb-4"
                    :options="block.options"
                    v-model="formData.relationship_option">
                    <template slot-scope="{ item }">
                      <div class="py-2 flex items-center text-sm sm:text-base">
                        <span
                          class="radio-icon mr-3"
                          :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                        <span>{{ item.title }}</span>
                      </div>
                    </template>
                  </radio-list>

                  <div v-if="formData.relationship_option && ['other', 'Other'].includes(formData.relationship_option.id)">
                    <input-field
                      placeholder="Enter your connection"
                      v-model="formData.relationship_description"
                      class="mb-3" />
                  </div>

                  <div
                    class="content text-sm"
                    v-if="block.helpText">
                    <block-content
                      :blocks="block.helpText" />
                  </div>
                </checkout-section>
              </div>
              <div v-if="block._type === 'checkoutRelationshipWithDialAndEngraving'">
                <checkout-section
                  :title="block.title ? block.title : 'Your connection to the unit'"
                  :subtitle="block.subtitle ? block.subtitle : 'Choose one'">
                  <div
                    v-if="formData.relationship_option.id"
                    class="w-full md:px-12">
                    <figure class="my-4">
                      <img
                        :src="formData.relationship_option.dialImage"
                        :alt="formData.relationship_option.id">
                    </figure>
                  </div>
                  <div
                    v-else
                    class="w-full md:px-12">
                    <figure class="my-4 opacity-50">
                      <img
                        :src="block.dialImage"
                        alt="Default">
                    </figure>
                  </div>
                  <select-field-object
                    v-if="block.relationshipSelectStyle == 'select'"
                    :options="block.options"
                    v-model="formData.relationship_option"
                    :placeholder="block.subtitle ? block.subtitle : 'Choose one'"
                    class="mb-4">
                    <template slot-scope="{ item }">
                      {{ item.title }}
                    </template>
                  </select-field-object>
                  <radio-list
                    v-else
                    class="mb-4"
                    :options="block.options"
                    v-model="formData.relationship_option">
                    <template slot-scope="{ item }">
                      <div class="py-2 flex items-center text-sm sm:text-base">
                        <span
                          class="radio-icon mr-3"
                          :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                        <span>{{ item.title }}</span>
                      </div>
                    </template>
                  </radio-list>

                  <div v-if="formData.relationship_option && ['other', 'Other'].includes(formData.relationship_option.id)">
                    <input-field
                      placeholder="Enter your connection"
                      v-model="formData.relationship_description"
                      class="mb-3" />
                  </div>

                  <div
                    class="content text-sm"
                    v-if="block.helpText">
                    <block-content
                      :blocks="block.helpText" />
                  </div>
                </checkout-section>

                <checkout-engraving
                  :value="formData.engraving_line_1"
                  :image="formData.relationship_option.id ? formData.relationship_option.image : block.casebackImage"
                  :is-dark="false"
                  :caseback-kind="block.engravingStyle">
                  <div class="lg:max-w-md py-8 sm:py-12">
                    <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                      Choose your unique engraving
                    </h3>
                    <p class="text-gray-mm-900 lg:text-lg mb-6">
                      You can modify your engraving any time before production starts at https://hq.micromilspec.com
                    </p>
                    <label
                      for="engraving_line_1"
                      class="text-gray-mm-900 mb-3 inline-block">
                      Your personal engraving (optional)
                    </label>
                    <input-field
                      placeholder="Your engraving here (optional)"
                      :maxlength="block.engravingLine1Max || 30"
                      v-model="formData.engraving_line_1"
                      class="mb-6"
                      name="engraving_line_1"
                      bg-class="bg-gray-mm-500" />
                    <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
                      Ex. Name, personal motto, dates or important milestones.
                    </p>
                  </div>
                </checkout-engraving>
              </div>
              <div v-if="block._type === 'checkoutEngraving'">
                <checkout-engraving
                  :value="formData.engraving_line_1"
                  :image="block.casebackImage"
                  :is-dark="false"
                  :caseback-kind="block.engravingStyle">
                  <div class="lg:max-w-md py-8 sm:py-12">
                    <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                      Choose your unique engraving
                    </h3>
                    <p class="text-gray-mm-900 lg:text-lg mb-6">
                      You can modify your engraving any time before production starts at https://hq.micromilspec.com
                    </p>
                    <label
                      for="engraving_line_1"
                      class="text-gray-mm-900 mb-3 inline-block">
                      Your personal engraving (optional)
                    </label>
                    <input-field
                      placeholder="Your engraving here (optional)"
                      :maxlength="block.engravingLine1Max || 30"
                      v-model="formData.engraving_line_1"
                      class="mb-6"
                      name="engraving_line_1"
                      bg-class="bg-gray-mm-500" />
                    <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
                      Ex. Name, personal motto, dates or important milestones.
                    </p>
                  </div>
                </checkout-engraving>
              </div>
              <div v-if="block._type === 'checkoutDialWithCustomText'">
                <div class="bg-black text-white py-6 sm:py-8">
                  <div class="container mx-auto">
                    <div class="bg-gray-mm-600">
                      <div class="lg:flex items-center">
                        <div class="lg:w-1/2 lg:pr-4">
                          <div class="p-4 sm:p-8 lg:p-16 hidden lg:block">
                            <figure class="pb-full relative">
                              <img
                                v-if="block.defaultDialImage && formData.meta.filter(line => line.value != null && line.value != '').length === 0"
                                :src="block.defaultDialImage"
                                alt=""
                                class="absolute inset-0">
                              <img
                                v-else
                                :src="block.dialImage"
                                alt=""
                                class="absolute inset-0">
                              <div
                                v-for="(dialLine,dialLineIndex) in formData.meta"
                                class="absolute inset-0"
                                :key="dialLineIndex">
                                <div
                                  class="text-center uppercase"
                                  :style="{ marginTop: `${dialLine.offsetTop}%`, containerType: 'inline-size' }">
                                  <div
                                    :style="{ fontSize: `${dialLine.fontScale}cqw`, fontFamily: `${block.previewFont}, sans-serif` }">
                                    {{ dialLine.value }}
                                  </div>
                                </div>
                              </div>
                            </figure>
                          </div>
                        </div>
                        <div class="lg:w-1/2 px-4">
                          <div class="lg:max-w-md py-8 sm:py-12">
                            <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                              {{ block.title }}
                            </h3>
                            <p class="text-gray-mm-900 lg:text-lg mb-6">
                              {{ block.subtitle }}
                            </p>
                            <div class="p-4 sm:p-8 lg:p-16 lg:hidden">
                              <figure class="pb-full relative">
                                <img
                                  v-if="block.defaultDialImage && formData.meta.filter(line => line.value != null && line.value != '').length === 0"
                                  :src="block.defaultDialImage"
                                  alt=""
                                  class="absolute inset-0">
                                <img
                                  v-else
                                  :src="block.dialImage"
                                  alt=""
                                  class="absolute inset-0">
                                <div
                                  v-for="(dialLine,dialLineIndex) in formData.meta"
                                  class="absolute inset-0"
                                  :key="dialLineIndex">
                                  <div
                                    class="text-center uppercase"
                                    :style="{ marginTop: `${dialLine.offsetTop}%`, containerType: 'inline-size' }">
                                    <div
                                      :style="{ fontSize: `${dialLine.fontScale}cqw`, fontFamily: `${block.previewFont}, sans-serif` }">
                                      {{ dialLine.value }}
                                    </div>
                                  </div>
                                </div>
                              </figure>
                            </div>
                            <div
                              v-for="(metaItem, metaItemIndex) in formData.meta"
                              :key="metaItemIndex">
                              <label
                                :name="metaItem.key"
                                class="text-gray-mm-900 mb-3 inline-block">
                                {{ metaItem.label }}
                              </label>
                              <input-field
                                placeholder="..."
                                :maxlength="metaItem.maxLength"
                                :value="metaItem.value"
                                @input="metaItem.value = $event.toUpperCase()"
                                class="mb-6"
                                :name="metaItem.key"
                                :autocomplete="false"
                                bg-class="bg-gray-mm-500 uppercase" />
                            </div>
                            <div
                              class="content text-sm"
                              v-if="block.helpText">
                              <block-content
                                :blocks="block.helpText" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="py-8 container mx-auto">
            <div class="lg:w-3/12 xl:w-1/3 mx-auto">
              <div class="mb-4">
                <default-button
                  tertiary
                  :loading="submitting"
                  :disabled="submitting">
                  Save Changes
                </default-button>
              </div>
              <div
                class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center"
                v-if="$page.props.flash.success">
                {{ $page.props.flash.success }}
              </div>
              <validation-errors unique />
            </div>
          </div>
        </form>
      </div>
    </div>
  </hq-layout>
</template>

<script>
import HqLayout from "@/Shared/HqLayout.vue"
import CheckoutSection from "@/Shared/CheckoutSection.vue"
import CheckoutEngraving from "@/Shared/CheckoutEngraving.vue"
import BlockContent from "sanity-blocks-vue-component"
import RadioList from "@/Shared/RadioList.vue"

export default {
  props: {
    customer: {
      type: Object,
      default () {
        return {}
      }
    },

    order: {
      type: Object,
      default () {
        return {}
      }
    },

    sanityData: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    HqLayout,
    CheckoutSection,
    CheckoutEngraving,
    BlockContent,
    RadioList
  },

  mounted () {
    if (this.sanityData && this.sanityData.page1Blocks) {
      this.sanityData.page1Blocks.map(block => {
        if (["checkoutEngravingWithRelationship", "checkoutRelationshipWithDialAndEngraving", "checkoutRelationship"].includes(block._type)) {
          this.formData.relationship_option = block.options ? block.options.find(item => {
            return item.id == this.order.meta.relationship_option.id
          }) : {}
        }

        if (["checkoutDialWithCustomText"].includes(block._type)) {
          this.formData.meta = block.lines.map((line) => {
            return {
              ...line,
              value: this.order.meta[line.key]
            }
          })
        }
      })
    }

    this.formData.engraving_line_1 = this.order.meta.engraving_line_1
    this.formData.engraving_line_2 = this.order.meta.engraving_line_2
  },

  data () {
    return {
      formData: {
        engraving_line_1: null,
        engraving_line_2: null,
        meta: [],
        relationship_option: {}
      },
      error: null,
      submitting: false,
      editMode: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.patch(`/orders/${this.order.id}/edit-timepiece`, this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
