<template>
  <div
    id="shop-box"
    class="box white rounded">
    <div class="content">
      <ResponsiveImage :image="data.shop_image" />
      <div class="price">
        {{ data.shop_price }}
      </div>
      <div
        class="price-alternative"
        v-html="asHtml(data.shop_price_alternative)" />
      <a
        id="shop-button"
        :href="data.checkout_url"
        class="inline-block py-2 sm:py-4 px-3 sm:px-5 bg-yellow-mm-600 text-black text-sm leading-tight font-semibold mx-1 rounded-md text-center">{{ data.shop_button_label }}</a>
      <div>{{ data.shop_terms }}</div>
      <div class="subtitle">
        {{ data.shop_terms_subtitle }}
      </div>
      <div class="terms-list">
        <div class="row">
          <div class="label">
            {{ data.shop_terms_line_a }}
          </div>
          <img :src="asset('images/sales/shop-circles-check.svg')">
        </div>
        <div class="row">
          <div class="label">
            {{ data.shop_terms_line_b }}
          </div>
          <img :src="asset('images/sales/shop-circles-2.svg')">
        </div>
        <div class="row">
          <div class="label">
            {{ data.shop_terms_line_c }}
          </div>
          <img :src="asset('images/sales/shop-circles-6.svg')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import ResponsiveImage from "../Fragments/ResponsiveImage.vue"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    ResponsiveImage,
  },

  mixins: [prismicMixins, staticStrings],
}
</script>
