<template>
  <div class="text-center bg-black">
    <h2 class="text-2xl mb-4 font-bold leading-tight">
      {{ data.engraving_headline }}
    </h2>

    <div
      class="content text-gray-mm-900 text-lg"
      v-html="asHtml(data.engraving_content)" />
    <div>
      <Engraving
        :caseback-kind="data.engraving_caseback_kind"
        :native-width="1000"
        :native-height="1000"
        :inputs="[
          {
            label: 'Din personlige tekst',
            placeholder: '',
            maxCharacters: 30,
            center: 0
          },
        ]"
        :value="dummy_engraving"
        :is-dark="false">
        <img
          :src="data.engraving_caseback_image.url"
          :alt="data.engraving_caseback_image.alt">
      </Engraving>

      <div class="pt-4">
        <h3 class="text-xl lg:text-2xl leading-tight mb-6">
          {{ getStaticString("tryEngraving") }}
        </h3>
        <input-field
          :placeholder="getStaticString('tryYourEngraving')"
          :maxlength="data.engraving_caseback_kind === 'closed-caseback-dark-2023' ? 24 : 30"
          v-model="dummy_engraving"
          class="mb-6 max-w-sm mx-auto px-4"
          bg-class="bg-gray-mm-500" />
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import Engraving from "@/Shared/Engraving.vue"

export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      dummy_engraving: null
    }
  },

  components: {
    Engraving
  }
}
</script>
