<template>
  <div
    id="straps-box"
    class="box black rounded lg:text-left"
    @click.prevent="activeModal = `straps`"
  >
    <OpenButton />
    <video
      autoplay
      loop
      muted
      playsinline>
      <source
        :src="data.straps_video.url"
        type="video/mp4">
    </video>
    <div
      id="straps-more"
      class="text-2xl">
      {{ getStaticString("moreAboutStraps") }}
    </div>
    <SalesModal
      :open="activeModal === 'straps'"
      @close="activeModal = null">
      <div
        id="straps-modal"
        class="container mx-auto h-full lg:h-auto">
        <div class="text-center flex flex-col justify-center py-12">
          <span
            class="text-2xl mb-4 leading-tight"
            v-html="asHtml(data.straps_modal_title)" />
          <div
            class="content text-2xl leading-tight text-gray-mm-900"
            v-html="asHtml(data.straps_modal_body)" />
          <video
            autoplay
            loop
            muted
            playsinline>
            <source
              :src="data.straps_video.url"
              type="video/mp4">
          </video>
        </div>
      </div>
    </SalesModal>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import OpenButton from "../Fragments/OpenButton.vue"
import SalesModal from "../Fragments/SalesModal.vue"


export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      activeModal: null,
    }
  },

  components: {
    OpenButton,
    SalesModal
  }
}
</script>
