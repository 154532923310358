<template>
  <layout :cart-is-visible="false">
    <div class="container mx-auto">
      <div class="mx-auto max-w-5xl">
        <div class="mx-auto grid sm:grid-cols-8 gap-4 md:gap-0">
          <div class="sm:col-span-4">
            <header class="mb-8 md:mt-8 font-medium">
              <h1 class="text-2xl sm:text-3xl md:text-4xl leading-tight mb-1">
                Checkout
              </h1>
              <div class="text-gray-mm-400 text-sm mt-2 leading-tight flex">
                <span class="mr-3">Information</span>
                <span class="mr-3 text-white">Shipping</span>
                <span class="mr-3">Payment</span>
              </div>
            </header>

            <div class="my-8 md:my-16 text-sm">
              <div class="p-2 sm:p-3 flex items-center border border-gray-mm-500 rounded-sm">
                <div class="w-20 text-gray-mm-300">
                  Contact
                </div>
                <div class="ml-2">
                  {{ checkout.email }}
                </div>
                <div class="ml-auto pl-4 text-xs font-semibold">
                  <inertia-link :href="route('shop.checkout.contact-information', { id: checkout.id })">
                    Change
                  </inertia-link>
                </div>
              </div>
              <div class="p-2 sm:p-3 flex items-center border border-t-0 border-gray-mm-500 rounded-sm rounded-t-none">
                <div class="w-20 text-gray-mm-300">
                  Ship to
                </div>
                <div class="ml-2">
                  {{ checkout.shipping_address_line_1 }}, {{ checkout.shipping_postal_code }} {{ checkout.shipping_city }}, {{ checkout.shipping_country }}
                </div>
                <div class="ml-auto pl-4 text-xs font-semibold">
                  <inertia-link :href="route('shop.checkout.contact-information', { id: checkout.id })">
                    Change
                  </inertia-link>
                </div>
              </div>
            </div>

            <div class="my-8 md:mt-12 md:mb-16">
              <h2 class="sm:text-2xl leading-tight mb-1 font-medium">
                Delivery method
              </h2>
              <div class="text-gray-mm-300 leading-tight">
                <p>Choose the one that suits you best.</p>
              </div>
              <div class="my-4">
                <radio-list
                  :options="deliveryOptions"
                  @input="handleDeliveryOptionChange">
                  <template slot-scope="{ item }">
                    <div class="py-2 flex items-center text-sm sm:text-base">
                      <span
                        class="radio-icon mr-3"
                        :class="{ 'radio-icon--checked': formData.shipping_method_id === item.id }" />
                      <div>
                        <div>{{ item.name }}</div>
                        <div class="text-gray-mm-300 text-sm">
                          {{ item.description }}
                        </div>
                      </div>
                      <div class="ml-auto pl-2">
                        <product-price :prices="item.prices">
                          <template slot-scope="{ priceFormatted }">
                            {{ priceFormatted }}
                          </template>
                        </product-price>
                      </div>
                    </div>
                  </template>
                </radio-list>
              </div>
            </div>

            <validation-errors />

            <div class="flex flex-col items-center mt-8 mb-8 sm:mb-16">
              <default-button
                tertiary
                :loading="submitting"
                class="mb-4 w-full text-base"
                @click.prevent.native="submit">
                Continue with payment
              </default-button>
            </div>
          </div>
          <div class="sm:col-span-3 sm:col-start-6 sm:pt-28 mt-2">
            <div>
              <h2 class="sm:text-2xl leading-tight mb-1 font-medium">
                Summary
              </h2>

              <checkout-items-summary
                :items="checkout.items"
                class="mt-4 mb-8" />

              <div class="flex my-1 justify-between">
                <span>Items</span>
                <span>{{ checkout.items_count }}</span>
              </div>
              <div class="flex my-1 justify-between">
                <span>Subtotal</span>
                <total-price
                  :price="checkout.subtotal"
                  :currency="checkout.currency" />
              </div>
              <div class="flex my-1 justify-between">
                <span>Shipping</span>
                <total-price
                  :price="checkout.total_shipping"
                  :currency="checkout.currency" />
              </div>
              <div class="flex my-1 justify-between items-end">
                <span>Tax</span>
                <total-price
                  :price="checkout.total_tax"
                  :currency="checkout.currency" />
              </div>
              <div class="flex mt-4 justify-between border-t border-gray-mm-500 pt-3">
                <span>Total</span>
                <total-price
                  :price="checkout.total"
                  :currency="checkout.currency"
                  class="font-medium" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import countries from "@/utils/countries.json"
import Layout from "@/Pages/Shop/Layout.vue"
import CheckoutItemsSummary from "@/Pages/Shop/Partials/CheckoutItemsSummary.vue"
import RadioList from "@/Shared/RadioList.vue"
import ProductPrice from "@/Pages/Shop/Partials/ProductPrice"
import TotalPrice from "@/Pages/Shop/Partials/TotalPrice"

export default {
  props: {
    checkout: {
      type: Object,
      default () {
        return {}
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    }
  },

  components: {
    Layout,
    CheckoutItemsSummary,
    RadioList,
    ProductPrice,
    TotalPrice
  },

  data () {
    return {
      formData: {
        shipping_method_id: null
      },
      submitting: false
    }
  },

  mounted () {
    let option = this.deliveryOptions.filter(option => option.id === this.checkout.shipping_method_id)
    if (option.length > 0) {
      this.formData.shipping_method_id = option[0].id
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(this.route("shop.checkout.shipping.store", { checkout: this.checkout.id }), this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    },

    handleDeliveryOptionChange (item) {
      this.formData.shipping_method_id = item.id
      this.submitting = true

      this.$inertia.patch(this.route("shop.checkout.shipping.update", { checkout: this.checkout.id }), this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    selectedCountry () {
      return countries
        .filter(country => country.Code === this.formData.customer.country)
        .map(country => country.Name)[0]
    },

    shipping () {
      if (this.formData.shipping_method_id) {
        let option = this.deliveryOptions.filter(option => option.id === this.checkout.shipping_method_id)
        return option[0].price
      }

      return 0
    }
  }
}
</script>
