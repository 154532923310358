<template>
  <div>
    <label
      v-if="label"
      class="block text-white mb-2"
      for="title">
      {{ label }}
    </label>
    <textarea
      v-model="model"
      class="appearance-none block w-full bg-gray-mm-600 border border-transparent h-40 shadow px-4 py-3 text-white pr-8 rounded leading-tight focus:outline-none focus:shadow-outline placeholder-white"
      id="username"
      type="text"
      :placeholder="placeholder" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },

    value: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: null
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
