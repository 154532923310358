<template>
  <div class="relative">
    <main>
      <slot />
    </main>
    <portal-target name="modals" />
  </div>
</template>

<script>
export default {
  mounted () {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    })
  }
}
</script>
