<template>
  <div
    id="timepiece-box"
    class="box black rounded">
    <SalesModal
      :open="activeModal === 'timepiece'"
      @close="activeModal = null">
      <div
        id="timepiece-modal"
        class="container mx-auto">
        <video
          controls
          autoplay
          loop
          muted
          playsinline>
          <source
            :src="data.timepiece_video.url"
            type="video/mp4">
        </video>
      </div>
    </SalesModal>

    <div class="outer lg:container lg:mx-auto">
      <div
        class="image-wrap"
        @click.prevent="activeModal = 'timepiece'">
        <ResponsiveImage :image="data.timepiece_image" />
        <PlayButton />
      </div>
      <div class="copy container text-center lg:pl-8 xl:pl-16 lg:text-left py-8 lg:py-24">
        <div class="inner font-bold">
          <h2 class="text-2xl leading-tight text-white mb-4">
            {{ data.timepiece_headline }}
          </h2>
          <div
            class="content text-gray-mm-900 text-lg"
            v-html="asHtml(data.timepiece_content)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import ResponsiveImage from "../Fragments/ResponsiveImage.vue"
import PlayButton from "../Fragments/PlayButton.vue"
import SalesModal from "../Fragments/SalesModal.vue"

export default {
  mixins: [prismicMixins],

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      activeModal: null,
    }
  },

  components: {
    ResponsiveImage,
    PlayButton,
    SalesModal
  }
}
</script>
