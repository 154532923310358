<template>
  <div id="auth-countdown">
    <div class="countdown-bg">
      <div class="grid justify-items-center items-center h-full">
        <div class="text-center">
          <p class="mb-4 uppercase tracking-widest">
            Lansering
          </p>
          <h1 class="text-yellow-mm-600 text-4xl leading-7 mb-6 font-medium">
            {{ title }}
          </h1>
          <div class="flex uppercase">
            <div class="flex flex-col items-center justify-center mr-8">
              <span class="text-4xl">
                {{ days }}
              </span>
              DAGER
            </div>
            <div class="flex flex-col items-center justify-center mr-8">
              <span class="text-4xl">
                {{ hours }}
              </span>
              TIMER
            </div>
            <div class="flex flex-col items-center justify-center mr-8">
              <span class="text-4xl">
                {{ minutes }}
              </span>
              MIN
            </div>
            <div class="flex flex-col items-center justify-center">
              <span class="text-4xl">
                {{ seconds }}
              </span>
              SEK
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    date: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: null
    },
  },

  data() {
   return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
   }
  },

  methods: {
    countdown() {
      var countDownDate = new Date(this.date).getTime()

      const x = setInterval(() => {
        var now = new Date().getTime()
        var distance = countDownDate - now
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
        // If the count down is finished, write some text
        if (distance < 0 ) {
          clearInterval(x)
          setTimeout(()=>{
            location.reload()
          }, 10000)
        }
      }, 1000)
      return
    }
  },
  created(){
    this.countdown()
  },
}
</script>
