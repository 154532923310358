<template>
  <layout>
    <nav class="hidden lg:block relative">
      <div class="fixed top-0 left-0 p-4 z-50">
        <img
          :src="asset('images/top-left-title.svg')"
          alt="">
      </div>
      <div class="fixed top-0 right-0 p-4 z-50">
        <img
          :src="asset('images/top-right-title.svg')"
          alt="">
      </div>
    </nav>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <article class="text-center max-w-4xl mx-auto">
          <header class="sm:mt-12">
            <div class="w-8 text-white mx-auto mb-6">
              <svg
                class="fill-current text-white"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M32.1457 5H30.4497C20.8435 5 12.7322 11.2155 10.3175 19.7049H2.5V21.3292C2.5 30.3293 8.70296 37.9392 17.2437 40.4388V45H18.9397C28.5511 45 36.6363 38.7608 39.0682 30.2951H47.5V28.6708C47.5 19.4749 41.0139 11.6838 32.1457 9.38714V5ZM28.7538 8.32629V12.0077L30.1196 12.2673C37.5668 13.6827 43.2869 19.6797 44.0269 27.0466H36.3464L36.0694 28.346C34.5506 35.4717 28.3164 40.9622 20.6357 41.6734V37.9096L19.3281 37.6149C12.1549 35.9985 6.6947 30.1396 5.97309 22.9534H13.0473L13.321 21.6493C14.8193 14.5113 21.0666 9.03501 28.7538 8.32629Z" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.6583 39.196V29.602H17.2663V39.196H20.6583Z" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M32.1457 18.2106V10.6741H28.7538V18.2106H32.1457Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30.4498 30.295H39.088V27.0465H30.4498V30.295Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.9623 19.7049H7.88191V22.9534H18.9623V19.7049Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.181 26.4618V21.9572H24.0503V26.4618H25.181Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.241 24.7509H26.9672V23.6681H22.241V24.7509Z"
                />
              </svg>
            </div>
            <h2 class="sm:text-xl uppercase leading-tight block my-2">
              Official TMBN timepiece
            </h2>
            <h1 class="text-xl sm:text-4xl uppercase font-black leading-tight block">
              the swiss made N.3
            </h1>
            <p class="font-medium block my-4 sm:text-2xl leading-tight text-yellow-mm-600">
              Contact <a href="mailto:sos@micromilspec.com">sos@micromilspec.com</a> for enquiries
            </p>
          </header>

          <div>
            <video
              ref="player"
              playsinline
              autoplay
              muted>
              <source
                :src="asset('images/tmbn/hero-video-high-quality.webm')"
                type="video/webm">
              <source
                :src="asset('images/tmbn/hero-video-high-quality.mp4')"
                type="video/mp4">
            </video>
          </div>

          <div class="z-50 py-12 fixed bottom-0 left-0 w-full">
            <inertia-link
              v-if="false"
              :href="route('tmbn.customize')"
              class="inline-block w-auto px-4 shadow-lg pt-1 pb-2 border-2 border-yellow-mm-600 bg-yellow-mm-600 text-black font-medium text-lg leading-tight rounded-full">
              Order Now
            </inertia-link>
          </div>

          <div
            class="mt-16 mb-16 sm:px-16"
            v-if="false">
            <p class="mb-6">
              Secure your timepiece.<br>Enter your email below to get notified when the N.3 is available for purchase. Starting at NOK 8900,-
            </p>
            <pre-signup :site-id="1" />
          </div>
        </article>
      </div>
    </div>
    <div class="bg-gray-mm-600 py-8 sm:py-12">
      <div class="container mx-auto">
        <article class="text-center max-w-xl mx-auto">
          <p class="uppercase text-gray-mm-300 tracking-widest text-xs sm:text-sm block my-4">
            Bespoke Timekeeping Instrument
          </p>
          <p class="font-medium block my-4 sm:text-2xl leading-tight">
            The N.3 is custom made to captivate TMBN’s robust, raw and enduring reputation on the battlefield.
          </p>
          <p class="text-sm sm:text-base block my-4">
            An extension of the flag insignia to the wrist, and a reminder of the covenant elements to TMBN success; respect, responsibility, courage, discipline, loyalty, independent, robust and caring.
          </p>

          <footer>
            <div class="flex items-stretch justify-between text-xs mt-8 sm:mt-8 sm:mb-8 flex-wrap sm:flex-no-wrap">
              <div class="w-1/2 sm:w-auto border border-white p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.7143 3.14286C13.619 3.14286 10.4762 2.09524 8.90475 0V22C17.2857 18.8571 17.8095 13.619 17.8095 10.4762V3.14286H15.7143Z"
                      fill="#676767" />
                    <mask
                      id="path-2-inside-1"
                      fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.90476 0C7.33333 2.09524 4.19048 3.14286 2.09524 3.14286H0V10.4762C0 13.619 0.52381 18.8571 8.90476 22C17.2857 18.8571 17.8095 13.619 17.8095 10.4762V3.14286H15.7143C13.619 3.14286 10.4762 2.09524 8.90476 0Z" />
                    </mask>
                    <path
                      d="M8.90476 0L9.70476 -0.6L8.90476 -1.66667L8.10476 -0.6L8.90476 0ZM0 3.14286V2.14286H-1V3.14286H0ZM8.90476 22L8.55364 22.9363L8.90476 23.068L9.25589 22.9363L8.90476 22ZM17.8095 3.14286H18.8095V2.14286H17.8095V3.14286ZM2.09524 4.14286C3.29059 4.14286 4.73166 3.84839 6.08449 3.27878C7.43543 2.70996 8.7811 1.83155 9.70476 0.6L8.10476 -0.6C7.457 0.263688 6.44552 0.956708 5.30837 1.43551C4.1731 1.91352 2.99512 2.14286 2.09524 2.14286V4.14286ZM0 4.14286H2.09524V2.14286H0V4.14286ZM1 10.4762V3.14286H-1V10.4762H1ZM9.25589 21.0637C5.23897 19.5573 3.2217 17.5978 2.1811 15.7478C1.13004 13.8793 1 11.9937 1 10.4762H-1C-1 12.1015 -0.86814 14.4064 0.437948 16.7284C1.75449 19.0689 4.1896 21.2998 8.55364 22.9363L9.25589 21.0637ZM9.25589 22.9363C13.6199 21.2998 16.055 19.0689 17.3716 16.7284C18.6777 14.4064 18.8095 12.1015 18.8095 10.4762H16.8095C16.8095 11.9937 16.6795 13.8793 15.6284 15.7478C14.5878 17.5978 12.5706 19.5573 8.55364 21.0637L9.25589 22.9363ZM18.8095 10.4762V3.14286H16.8095V10.4762H18.8095ZM17.8095 2.14286H15.7143V4.14286H17.8095V2.14286ZM15.7143 2.14286C14.8144 2.14286 13.6364 1.91352 12.5012 1.43551C11.364 0.956708 10.3525 0.263688 9.70476 -0.6L8.10476 0.6C9.02842 1.83155 10.3741 2.70996 11.725 3.27878C13.0779 3.84839 14.5189 4.14286 15.7143 4.14286V2.14286Z"
                      fill="white"
                      mask="url(#path-2-inside-1)" />
                  </svg>
                </div>
                <div>
                  5 year warranty
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white border-l-0 p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="path-1-inside-1"
                      fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.72727 7.72727V0L0 12.2727H5.90909V20L13.6364 7.72727H7.72727Z" />
                    </mask>
                    <path
                      d="M7.72727 7.72727H6.72727V8.72727H7.72727V7.72727ZM7.72727 0H8.72727V-3.46507L6.88104 -0.532813L7.72727 0ZM0 12.2727L-0.846233 11.7399L-1.81134 13.2727H0V12.2727ZM5.90909 12.2727H6.90909V11.2727H5.90909V12.2727ZM5.90909 20H4.90909V23.4651L6.75532 20.5328L5.90909 20ZM13.6364 7.72727L14.4826 8.26009L15.4477 6.72727H13.6364V7.72727ZM8.72727 7.72727V0H6.72727V7.72727H8.72727ZM6.88104 -0.532813L-0.846233 11.7399L0.846233 12.8055L8.57351 0.532813L6.88104 -0.532813ZM0 13.2727H5.90909V11.2727H0V13.2727ZM4.90909 12.2727V20H6.90909V12.2727H4.90909ZM6.75532 20.5328L14.4826 8.26009L12.7901 7.19446L5.06286 19.4672L6.75532 20.5328ZM13.6364 6.72727H7.72727V8.72727H13.6364V6.72727Z"
                      fill="white"
                      mask="url(#path-1-inside-1)" />
                  </svg>
                </div>
                <div>
                  Swiss Made
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white sm:border-l-0 border-t-0 sm:border-t p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.4871 11C19.2271 16.0143 15.079 20 10 20C4.92101 20 0.772939 16.0143 0.512932 11H19.4871Z"
                      fill="#676767"
                      stroke="white" />
                    <path
                      d="M10 1C4.75329 1 0.5 5.25329 0.5 10.5H19.5C19.5 5.25329 15.2467 1 10 1Z"
                      stroke="white" />
                    <circle
                      cx="10"
                      cy="10.5"
                      r="2"
                      fill="white"
                      stroke="white" />
                  </svg>
                </div>
                <div>
                  85 components
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white border-l-0 border-t-0 sm:border-t p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="9.5"
                      cy="11"
                      r="5.5"
                      fill="#676767"
                      stroke="white" />
                    <path
                      d="M9.5 19.5H6.5V21H4.5L1 13.5V8.5L4.5 1H6.5V2.5H9.5"
                      stroke="white" />
                    <path
                      d="M9.5 19.5H12.5V21H14.5L18 13.5V8.5L14.5 1H12.5V2.5H9.5"
                      stroke="white" />
                  </svg>
                </div>
                <div>
                  Custom made
                </div>
              </div>
            </div>
          </footer>
        </article>
      </div>
    </div>
    <div class="bg-gray-mm-100 footer-bg sm:px-16 pt-8 sm:pt-24">
      <div class="container mx-auto">
        <article class="text-center sm:max-w-3xl sm:px-12 mx-auto text-black">
          <h2 class="md:text-2xl font-medium text-sm mb-4">
            Custom Swiss Made Design
          </h2>
          <p class="md:text-2xl font-medium text-sm sm:text-base mt-4 sm:mb-4">
            The Swiss Made N.3 tactical timepiece features a unique yet elegant shape of an octagon. Fitted with custom details created together with Head of TMBN Emaus, Battalion Sergeant Aannestad, former Second in Command Lübbe, and former Second in Command of Squadron 6 Borud.
          </p>
          <br>
          <p class="md:text-2xl font-medium text-sm sm:text-base mt-4 sm:mb-4">
            True to the great tradition of Swiss watchmaking, the N.3 is in compliance with the Swiss Made Ordinance (Adopted 1st January 2019) as defined by the The Swiss Federal Council.
          </p>
        </article>
      </div>
      <div class="aspect-ratio-16x9 pb-64 sm:pb-0" />
    </div>
    <div class="bg-gray-mm-500 text-white sm:px-16 sm:pt-32 sm:pb-24 text-center">
      <div class="container mx-auto">
        <div class="md:flex flex-wrap -ml-4 -mr-4">
          <div class="sm:px-2 mb-4 md:w-1/2">
            <article class="bg-black h-full">
              <figure class="relative aspect-ratio-16x9 hidden sm:block">
                <img
                  :src="asset('images/tmbn/swiss-explode.jpg')"
                  class="absolute inset-0"
                  alt="Hidden mobil, synlig på desktop">
              </figure>
              <figure class="relative aspect-ratio-1x1 block sm:hidden">
                <img
                  :src="asset('images/tmbn/swiss-explode-mob.jpg')"
                  class="absolute inset-0"
                  alt="Synlig på mobil, hidden desktop">
              </figure>
              <div class="p-6">
                <h2 class="text-lg sm:text-2xl font-medium mb-4">
                  Swiss made movement
                </h2>
                <p class="mt-4 sm:mb-4 text-sm sm:text-base">
                  For decades Swiss Made Automatic, has been dominating the market of mechanical movements with the coveted “Swiss Made” label. Frequently used by IWC, Tag Heur, Breitling, Longines, Tudor, Omega (Speedmaster) Junghans and Panerai. Swiss Made Automatic is the sole manufacture of Micro Milspec mechanical self-winding movements.
                </p>
              </div>
            </article>
          </div>
          <div class="sm:px-2 mb-4 md:w-1/2">
            <article class="bg-black h-full">
              <overlay-viewer />
              <div class="p-6">
                <h2 class="text-lg sm:text-2xl font-medium mb-4">
                  Swiss Super-Luminova
                </h2>
                <p class="mt-4 sm:mb-4 text-sm sm:text-base">
                  The renowned self glowing Swiss Super-Luminova is applied to every element with time keeping information for fast readings and reliability even in dark environments.
                </p>
              </div>
            </article>
          </div>
          <div class="sm:px-2 mb-4 md:w-1/2">
            <article class="bg-black h-full">
              <figure class="relative aspect-ratio-16x9 hidden sm:block">
                <img
                  :src="asset('images/tmbn/caseback-darkened.jpg')"
                  class="absolute inset-0"
                  alt="Hidden mobil, synlig på desktop">
              </figure>
              <figure class="relative aspect-ratio-1x1 block sm:hidden">
                <img
                  :src="asset('images/tmbn/caseback-darkened-mob.jpg')"
                  class="absolute inset-0"
                  alt="Synlig på mobil, hidden desktop">
              </figure>
              <div class="p-6">
                <h2 class="text-lg sm:text-2xl font-medium mb-4">
                  Customisable caseback
                </h2>
                <p class="mt-4 sm:mb-4 text-sm sm:text-base">
                  Your name on the outer circle surrounding the official heraldic symbol of the Telemark Battalion. Below it you can customise furthermore with your division, and position within TMBN.
                </p>
              </div>
            </article>
          </div>
          <div class="sm:px-2 mb-4 md:w-1/2">
            <article class="bg-black h-full">
              <figure class="relative aspect-ratio-16x9 hidden sm:block">
                <img
                  :src="asset('images/tmbn/straps-lineup.jpg')"
                  class="absolute inset-0"
                  alt="Hidden mobil, synlig på desktop">
              </figure>
              <figure class="relative aspect-ratio-1x1 block sm:hidden">
                <img
                  :src="asset('images/tmbn/straps-lineup-mob.jpg')"
                  class="absolute inset-0"
                  alt="Synlig på mobil, hidden desktop">
              </figure>
              <div class="p-6">
                <h2 class="text-lg sm:text-2xl font-medium mb-4">
                  Steel, rubber, leather &amp; nato.<br>Five straps. One watch
                </h2>
                <p class="mt-4 sm:mb-4 text-sm sm:text-base">
                  Mix and match to accommodate for professional use, dressed down occasions or presentations. We’ve included a strap changing tool so you can easily swap out the strap depending on the setting.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
    <div>
      <slider :items="sliderItems">
        <template slot-scope="{ item }">
          <figure
            class="aspect-ratio-16x9 hidden sm:block select-none bg-cover"
            :style="{ 'background-image': `url(${item.desktopSrc})`}" />
          <figure
            class="aspect-ratio-1x1 sm:hidden select-none bg-cover"
            :style="{ 'background-image': `url(${item.mobileSrc})`}" />
        </template>
      </slider>
    </div>
    <div class="bg-black py-6 sm:py-16">
      <div class="container mx-auto">
        <div class="pb-6 sm:pb-8 font-medium">
          <h2 class="sm:text-2xl text-white leading-tight">
            Design details
          </h2>
          <p class="sm:text-2xl text-gray-mm-300 leading-tight">
            Click to read more
          </p>
        </div>
        <features
          class="features-grid w-full"
          v-slot="{ features, handleClick }">
          <feature-card
            v-for="(feature,index) in features"
            :key="index"
            :feature="feature"
            @open="handleClick">
            {{ feature }}
          </feature-card>
        </features>
      </div>
    </div>
    <div class="bg-black text-white py-6 sm:py-16">
      <div class="container mx-auto">
        <div class="pb-8 font-medium">
          <h2 class="sm:text-2xl leading-tight">
            Features
          </h2>
          <p class="sm:text-2xl text-gray-mm-300 leading-tight">
            Specifications &amp; content
          </p>
        </div>

        <div class="flex flex-wrap items-stretch sm:-ml-4 sm:-mr-4 sm:my-12">
          <feature-item
            v-for="(item,index) in featureItems"
            :key="index"
            :title="item.title"
            :icon="item.icon"
            :content="item.content" />
        </div>

        <div class="flex items-center flex-wrap sm:-ml-4 sm:-mr-4 sm:my-8">
          <div class="w-full sm:w-auto sm:px-4 mb-2 sm:mb-0 flex items-center text-xs">
            <span class="inline-block w-6 mr-2">
              <img
                :src="asset('images/icons/case-diameter.svg')"
                alt="">
            </span>
            <p>42mm diameter (whole case)</p>
          </div>
          <div class="w-full sm:w-auto sm:px-4 mb-2 sm:mb-0 flex items-center text-xs">
            <span class="inline-block w-6 mr-2">
              <img
                :src="asset('images/icons/case-height.svg')"
                alt="">
            </span>
            <p>Approx. 12mm height</p>
          </div>
          <div class="w-full sm:w-auto sm:px-4 mb-2 sm:mb-0 flex items-center text-xs">
            <span class="inline-block w-6 mr-2">
              <img
                :src="asset('images/icons/strap-tools.svg')"
                alt="">
            </span>
            <p>Includes a strap tool, swap straps easily</p>
          </div>
          <div class="w-full sm:w-auto sm:px-4 mb-2 sm:mb-0 flex items-center text-xs">
            <span class="inline-block text-center w-6 sm:w-4 mr-2">
              <img
                :src="asset('images/icons/steel-bracelet.svg')"
                class="inline-block"
                alt="">
            </span>
            <p>Steel bracelet is included</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-mm-400 text-white pt-6 sm:pt-16">
      <div class="container mx-auto">
        <div class="pb-6 sm:pb-8 font-medium">
          <h2 class="sm:text-2xl leading-tight">
            Watch box
          </h2>
          <p class="sm:text-2xl text-gray-mm-300 leading-tight">
            What's inside
          </p>
        </div>
      </div>
      <figure class="hidden sm:block aspect-ratio-16x9">
        <img
          :src="asset('images/tmbn/in-the-box.jpg')"
          alt=""
          class="object-fill absolute z-0 inset-0 w-full">
      </figure>
      <figure class="sm:hidden aspect-ratio-1x1">
        <img
          :src="asset('images/tmbn/in-the-box-mob.jpg')"
          alt=""
          class="object-fill absolute z-0 inset-0 w-full">
      </figure>
    </div>
    <div class="bg-yellow-mm-600 text-black font-normal py-6 sm:py-32">
      <div class="container mx-auto">
        <div class="flex flex-wrap sm:-ml-4 sm:-mr-4">
          <div class="sm:w-3/12 sm:px-4 mt-4 mb-6 sm:mb-0 font-medium">
            <h2 class="sm:text-2xl leading-tight mb-2 sm:mb-8">
              Micro Milspec
            </h2>
            <p class="sm:text-2xl text-gray-mm-400 leading-tight">
              Bespoke timekeeping instruments.
            </p>
          </div>

          <div class="sm:w-3/12 text-sm sm:text-base ml-auto sm:px-4 content">
            <p>Micro Milspec designs and produces custom Swiss Made timekeeping instruments for first responders and military units.</p>
            <p>Founded by a team with extensive experience in watch design, production and military operations, we offer customised timepieces unique to our clients with the highest of standards true to the great tradition of Swiss watchmaking.</p>
          </div>
          <div class="sm:w-3/12 text-sm sm:text-base sm:px-4 content">
            <p>Made for those who face extreme situations where success depends on absolute precision and perfect timing. Our made-to-order timepieces are perfectly spec’d for professional use. Not available to the public and by appointment only.</p>
            <p>We hope you enjoy this timepiece as much as we did creating it. Thank you for your service.</p>
            <p>Henrik, Martin &amp; Anders</p>
          </div>
        </div>
        <div class="flex flex-wrap sm:-ml-4 sm:-mr-4 mt-12 mb-6">
          <div class="sm:w-6/12 sm:px-4 mt-4 mb-6 sm:mb-0 font-medium">
            <h2 class="sm:text-2xl leading-tight mb-2 sm:mb-8">
              Frequently Asked Questions
            </h2>
          </div>
          <div class="w-full sm:w-6/12 sm:px-4">
            <accordion
              v-for="(item,index) in faq"
              :key="index">
              <template slot="header">
                <div class="pr3">
                  {{ item.title }}
                </div>
              </template>
              <template slot="content">
                {{ item.content }}
              </template>
            </accordion>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-6 sm:p-8 sm:pb-0 bg-gray-mm-600">
      <div class="container mx-auto">
        <article class="text-center sm:max-w-xl mx-auto text-white">
          <header class="my-6 sm:my-12">
            <h1 class="text-lg sm:text-5xl uppercase font-black leading-tight block">
              the swiss made N.3
            </h1>
            <h2 class="sm:text-xl uppercase leading-tight block my-2 sm:my-4">
              by <span class="font-bold">micro milspec</span> <span class="">x</span> <span class="font-bold">tmbn</span>
            </h2>
          </header>

          <div
            class="mt-16 mb-16 sm:px-16"
            v-if="false">
            <p class="mb-6">
              Sales start friday 18th October.<br>Sign up to get notified.
            </p>
            <pre-signup :site-id="1" />
          </div>

          <div class="sm:my-16 sm:-mb-20 relative z-10">
            <p class="text-xs sm:text-base uppercase tracking-widest">
              Starting at NOK 8900,-
            </p>
            <inertia-link
              v-if="false"
              :href="route('tmbn.customize')"
              class="inline-block px-4 sm:px-12 py-1 sm:py-3 border-2 sm:border-6 border-white text-white font-bold sm:text-3xl uppercase rounded-full mt-6">
              Buy Now
            </inertia-link>
          </div>
        </article>
      </div>
      <figure class="hidden sm:block aspect-ratio-16x9">
        <img
          :src="asset('images/tmbn/angled-front.jpg')"
          class="object-fill absolute z-0 inset-0 w-full">
      </figure>
      <figure class="sm:hidden aspect-ratio-1x1">
        <img
          :src="asset('images/tmbn/angled-front-mob.jpg')"
          class="object-fill absolute z-0 inset-0 w-full">
      </figure>
    </div>
    <div class="py-8 sm:py-16 pb-32 sm:pb-48">
      <div class="container mx-auto max-w-2xl">
        <p class="mb-8 text-center text-xl">
          Access to our selection of timepieces, collaborations and new releases are limited to email subscribers:
        </p>
        <div class="sm:px-20">
          <pre-signup :site-id="1" />
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import Slider from "@/Shared/Slider.vue"
import OverlayViewer from "@/Shared/OverlayViewer.vue"
import FeatureCard from "@/Shared/FeatureCard.vue"
import FeatureItem from "@/Shared/FeatureItem.vue"
import Features from "@/Shared/Features.vue"
import PreSignup from "@/Shared/PreSignup.vue"
import Accordion from "@/Shared/Accordion.vue"

export default {
  components: {
    Layout,
    Slider,
    OverlayViewer,
    FeatureCard,
    FeatureItem,
    Features,
    PreSignup,
    Accordion
  },

  created () {
    try {
      window.fathom("trackPageview")
    } catch (e) {}
  },

  data () {
    return {
      faq: [
        {
          title: "Why does the N.3 cost NOK 8900?",
          content: "The N.3 is custom made, meaning that there is no existing mold to manufacture the timepiece. The machines used to create components such as the case, straps, dial, index markers, viking ship, glass, crown, hands and back case, are all custom calibrated to manufacture the N.3 timepiece. The movement is Swiss Made, a world renowned seal of approval in quality and craftsmanship, and it consists of sixty-five individual parts coming together in perfect harmony through the utmost precision and handcraft in Switzerland. "
        },
        {
          title: "Do you offer down payments?",
          content: "Yes, you can choose to pay in intervals over 2 or 4 months."
        },
        {
          title: "What is Swiss Made?",
          content: "True to the great tradition of Swiss watchmaking, the N.3 is in compliance with the Swiss Made Ordinance (Adopted 1st January 2019) as defined by the The Swiss Federal Council. Swiss Made is a protected title that requires a watch to have a Swiss Made movement and at least 60% of the watch (and it's parts) made in Switzerland. Final assembly and quality inspection must also be done in Switzerland."
        },
        {
          title: "What is a mechanical movement and what is automatic?",
          content: "A mechanical watch operates without batteries. The N.3 is powered by the movement of your hand, hence it is automatically wind. Kinetic energy automatically transfers to the inner mechanic of your timepiece by a rotor in perfect harmony with 24 jewels and 65 components. Mechanically displayed by sweeping hand motions with a 42 hour power reserve."
        },
        {
          title: "When will my timepiece be finished? ",
          content: "Production time is estimated to 24 weeks. "
        },
        {
          title: "What does my 5 year worldwide warranty cover?",
          content: "Your watch is covered by a five-year international warranty against any defects in manufacturing; this includes the watch movement, indexes, dial, straps and hands. In other words, if the watch parts or mechanics don't work properly, and it's our fault, we will repair or replace your product for free. However, this does not cover any abuse or physical damage inflicted on the watch. The warranty does not cover water damage, normal wear and tear, accidental glass damage, scratches, strap damage, or theft. Please note that leather straps are not waterproof although the watch is water resistant to 100 meters. If the product is returned during the warranty period, Micro Milspec will repair or replace any defective watch or watch-part, at no additional expense, within a reasonable time, after the product is returned. However, the customer may be subject to the costs of shipping the product."
        },
        {
          title: "Do I have to wind my N.3 or use batteries?",
          content: "No, the N.3 is automatic and will power itself once you put it on your wrist. The 42hr power reserve may take up to 4-5 hours to fully wind. You can wind your crown until you sense a change in friction and the watch will be fully powered the next 42hr even on your desk."
        },
        {
          title: "Where can I service my watch and how much does it cost?",
          content: "The N.3 timepiece is made to stand the test of time. Every component can be repaired or replaced. There is no battery in the watch, it is purely mechanical. Maintenance on the mechanical movement is vital in order to improve and uphold the endurance of your timepiece, and the watch mechanics needs to be cleaned, balanced and oiled. Such a maintenance service interval is recommended every 5 years for mechanical watches, regardless of brand. A maintenance service, called a full overhaul where the watch is extensively cared for, would typically cost NOK 3700. A full overhaul is offered to owners of N.3 timepieces for NOK 2500 with our Micro Milspec approved watchmaker."
        },
        {
          title: "Can the watch withstand severe weather and strain?",
          content: "Yes, however the watch will not withstand being dropped directly onto a hard surface such as concrete, bathroom tiles etc. or direct contact with other hard surfaces at high velocity. This may damage the glass and inner workings of the watch. "
        },
        {
          title: "Who can buy the N.3?",
          content: "People affiliated with Telemark Bataljonen."
        },
        {
          title: "How can I look after my MM?",
          content: "A dedicated link with instructions and watch care will be sent to your order email."
        },
        {
          title: "Can a MM be polished?",
          content: "Yes and no, the watch can be polished, but you will remove the brushed finish. However the watch will inevitably look stunning either way."
        },
      ],
      sliderItems: [
        {
          title: "Backpack",
          desktopSrc: this.asset("images/tmbn/backpack.jpg"),
          mobileSrc:  this.asset("images/tmbn/backpack-mob.jpg")
        },
        {
          title: "Soldier",
          desktopSrc: this.asset("images/tmbn/soldier.jpg"),
          mobileSrc:  this.asset("images/tmbn/soldier-mob.jpg")
        },
        {
          title: "Angled",
          desktopSrc: this.asset("images/tmbn/angled.jpg"),
          mobileSrc:  this.asset("images/tmbn/angled-mob.jpg")
        },
        {
          title: "The box",
          desktopSrc: this.asset("images/tmbn/box.jpg"),
          mobileSrc:  this.asset("images/tmbn/box-mob.jpg")
        },
        {
          title: "Closeup",
          desktopSrc: this.asset("images/tmbn/closeup.jpg"),
          mobileSrc:  this.asset("images/tmbn/closeup-mob.jpg")
        },
        {
          title: "Engraved caseback",
          desktopSrc: this.asset("images/tmbn/caseback.jpg"),
          mobileSrc:  this.asset("images/tmbn/caseback-mob.jpg")
        },
      ],

      featureItems: [
        {
          title: "Warranty",
          content: "5 years",
          icon: this.asset("images/icons/warranty.svg")
        },
        {
          title: "Self-winding movement",
          content: "Swiss Made Automatic",
          icon: this.asset("images/icons/self-winding.svg")
        },
        {
          title: "Water resistance",
          content: "10 ATM/100 M",
          icon: this.asset("images/icons/water-resistance.svg")
        },
        {
          title: "Sapphire glass",
          content: "Scratch resistant anti-reflective sapphire crystal",
          icon: this.asset("images/icons/glass.svg")
        },
        {
          title: "Power reserve",
          content: "42 hrs power reserve",
          icon: this.asset("images/icons/power-reserve.svg")
        },
        {
          title: "Certificate of authenticity",
          content: "With your name & serial number",
          icon: this.asset("images/icons/certificate.svg")
        },
        {
          title: "Luminous",
          content: "Renowed Swiss Super-Luminova",
          icon: this.asset("images/icons/luminous.svg")
        },
        {
          title: "Stainless steel case",
          content: "316L surgical grade stainless brushed steel case",
          icon: this.asset("images/icons/case.svg")
        },
        {
          title: "Display box",
          content: "Beautiful wooden box housing your watch",
          icon: this.asset("images/icons/watch-box.svg")
        },
        {
          title: "Surprise object",
          content: "We include a custom item unique to our collaboration",
          icon: this.asset("images/icons/surprise-object.svg")
        },
      ]
    }
  }
}
</script>
