<template>
  <div
    class="relative"
    @click.prevent="toggleZoom">
    <slot />
    <span class="icon-magnifier block w-5 h-5 bg-cover bg-no-repeat absolute bottom-0 left-0" />

    <modal
      :open="open"
      @close="open = false">
      <div class="flex h-full relative inset-0 items-center justify-center">
        <slot name="zoomed" />
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false
    }
  },

  methods: {
    toggleZoom () {
      this.open = !this.open
    }
  }
}
</script>
