<template>
  <layout :cart-is-visible="false">
    <div class="container mx-auto">
      <div class="mx-auto max-w-5xl">
        <div class="max-w-xl mx-auto">
          <header class="mb-8 md:mb-16 md:mt-8 font-medium text-center">
            <h1 class="sm:text-3xl md:text-4xl leading-tight mb-2">
              Thank you!
            </h1>
            <div class="text-gray-mm-300 md:text-lg leading-tight">
              <p>We have received your order and will begin processing it shortly.</p>
            </div>
          </header>

          <div class="my-8 md:my-16">
            <h2 class="sm:text-2xl leading-tight mb-6">
              Order Details
            </h2>
            <div class="my-4">
              ...
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Pages/Shop/Layout.vue"

export default {
  props: {
    order: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    Layout
  }
}
</script>
