<template>
  <layout>
    <div class="hero bg-black text-white pt-12">
      <div class="container mx-auto">
        <article class="max-w-4xl mx-auto md:py-20">
          <div class="content">
            <h1 class="sm:text-2xl leading-tight font-bold">
              PRIVACY STATEMENT FOR MICROMILSPEC AS
            </h1>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              1. RESPONSIBLE PARTY
            </h2>
            <p>On behalf of MICROMILSPEC AS, Henrik Rye is responsible for handling the company&apos;s personal data.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              2. STORAGE OF PERSONAL INFORMATION&nbsp;
            </h2>
            <p>We store the following personal information about our customers: name, address, telephone number, e-mail address unless otherwise stated. We also store purchase history and behavior patterns on our website, i.e. information on how the individual customer navigates the site.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              3. PURPOSE OF PROCESSING PERSONAL INFORMATION
            </h2>
            <p>We process the information in order to be able to carry out our agreements and obligations with you. We also store purchase history and behavior patterns on our website, i.e. information on how the individual customer navigates the site.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              4. BASIS OF PROCESSING PERSONAL INFORMATION
            </h2>
            <p>Information about name, address, telephone, e-mail address is used to fulfill the purchase agreement. The basis for this process is Article 6 (b) of the GDPR. Where you have consented to it, purchase history and behavior patterns are used to provide recommendations and advertising specifically tailored to you as a user. The basis for this treatment is the GDPR Art 6 (a). You can withdraw your consent to such use at any time.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              5. COLLECTION OF PERSONAL INFORMATION
            </h2>
            <p>We store the personal information you have provided to our employees in connection with your purchase. We use cookies on our websites to give you as a visitor the best customer experience and service. The Electronic Communications Act requires that we inform our visitors about the use of cookies (cookies).&nbsp;</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              6. PROVISION OF INFORMATION TO THIRD PARTIES
            </h2>
            <p>We will not share, sell, transfer or otherwise disclose personal information to others, unless we are legally obliged to do so.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              7. DELETION OF PERSONAL INFORMATION
            </h2>
            <p>Information we are obliged to retain under the Accounting Act will be stored for up to 5 years, in accordance with the requirements of the Act.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              8. RIGHTS OF THE REGISTERED
            </h2>
            <p>We process your personal data in accordance with the Personal Data Act and current regulations. Please note that you can demand access to and transfer of your own personal information, as well as demand correction or deletion of information. It can be complained to the&nbsp;Norwegian Data Protection Authority&nbsp;about processing in violation of the rules.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              9. PRIVACY REPRESENTATIVE
            </h2>
            <p>We have a privacy representative, Henrik Rye, who ensures that the Personal Data Act&apos;s rules on the processing of personal data are followed.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              10. INFORMATION SECURITY
            </h2>
            <p>We secure your personal information by both physical and virtual access and access control, as well as by encrypting sensitive parts of submitted information.</p>

            <h2 class="sm:text-lg text-gray-mm-300 leading-tight mt-4 mb-1">
              CONTACT INFORMATION
            </h2>
            <p>Inquiries about what information has been registered, correction and deletion can be sent in writing to the following addresses: sos@micromilspec.com MICROMILSPEC AS Akersgata 18 0158 Oslo</p>
          </div>
        </article>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"

export default {
  components: {
    Layout
  }
}
</script>
