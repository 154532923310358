<template>
  <div
    class="feature relative"
    :class="classObject">
    <a
      href="#"
      class="feature-card py-3 px-2 sm:px-0 sm:max-w-2xl mx-auto flex flex-col items-center justify-between h-full"
      @click.prevent="$emit('open', feature)">
      <figure class="absolute top-0 right-0 p-3 text-right w-10 sm:w-12">
        <svg
          class="w-full"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <circle
              cx="14"
              cy="14"
              r="13"
              stroke="white"
              stroke-width="2" />
            <rect
              x="13"
              y="9"
              width="2"
              height="10"
              fill="white" />
            <rect
              x="9"
              y="15"
              width="2"
              height="10"
              transform="rotate(-90 9 15)"
              fill="white" />
          </g>
        </svg>

      </figure>
      <div class="flex-grow flex flex-col items-center justify-center">
        <img
          v-if="feature.image"
          :src="feature.image"
          :alt="feature.title">
      </div>
      <div class="block sm:pb-2 text-sm text-center">{{ feature.title }}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      default () {
        return {
          slot: 0
        }
      }
    }
  },

  computed: {
    classObject () {
      return {
        "slot-1": this.feature.slot === 1,
        "slot-2": this.feature.slot === 2,
        "slot-3": this.feature.slot === 3,
        "slot-4": this.feature.slot === 4,
        "slot-5": this.feature.slot === 5,
        "slot-6": this.feature.slot === 6,
        "slot-7": this.feature.slot === 7,
        "slot-8": this.feature.slot === 8,
        "slot-9": this.feature.slot === 9,
      }
    }
  }
}
</script>
