<template>
  <div class="sales-slider">
    <div
      ref="container"
      class="swiper">
      <div class="swiper-wrapper">
        <div
          :class="['swiper-slide', slideClass]"
          v-for="(slide,index) in data"
          :key="index">
          <slot
            :slide="slide"
            :index="index">
            <!-- Fallback content -->
            {{ index }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"
import SwiperCore, { Pagination, Mousewheel, Navigation } from "swiper/core"

export default {
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },

    slideClass: {
      type: String,
      default: null
    },

    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  mounted () {
    SwiperCore.use([Pagination, Mousewheel, Navigation])

    const swiper = new Swiper(this.$refs.container, { ...{
      direction: "horizontal",
      slidesPerView: 1,
      centeredSlides: false,
      spaceBetween: 0,
      freeMode: false,
      mousewheel: {
        invert: false,
        forceToAxis: true
      }
    }, ...this.options })
  }
}
</script>
