<template>
  <span>
    {{ formattedPrice }}
  </span>
</template>

<script>
export default {
  props: {
    price: {
      type: Number,
      default: 0
    },

    currency: {
      type: String,
      default: "NOK"
    }
  },

  computed: {
    formattedPrice () {
      return Number(this.price / 100).toLocaleString("en", { style: "currency", currency: this.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
  }
}
</script>
