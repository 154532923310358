<script>
export default {
  props: {
    prices: {
      type: Object,
      default () {
        return {}
      }
    },

    quantity: {
      type: Number,
      default: 1
    }
  },

  render () {
    return this.$scopedSlots.default({
      price: this.activeCurrencyPrice,
      priceFormatted: this.activeCurrencyPriceFormatted,
      priceFormattedWithoutDecimals: this.activeCurrencyPriceFormattedWithoutDecimals,
      currency: this.activeCurrency
    })
  },

  computed: {
    activeCurrency () {
      return this.$page.props.currency
    },

    activeCurrencyPrice () {
      let amount = this.prices[this.$page.props.currency]
      return (amount * this.quantity) / 100
    },

    activeCurrencyPriceFormatted () {
      return Number(this.activeCurrencyPrice).toLocaleString("en", { style: "currency", currency: this.activeCurrency, minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },

    activeCurrencyPriceFormattedWithoutDecimals () {
      return Number(this.activeCurrencyPrice).toLocaleString("en", { style: "currency", currency: this.activeCurrency, minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
  }
}
</script>
