const data = {
  en: {
    imageGallery: "Image gallery",
    buy: "Buy now",
    specifications: "Specifications",
    showAllSpecifications: "Show all specifications",
    designDetails: "Design details",
    swipeToSeeMore: "Swipe to see more",
    tryEngraving: "Try your personalized engraving",
    charactersRemaining: "characters remaining",
    interactive360Viewing: "Interactive 360° viewing",
    interactiveView: "Interactive view",
    moveSliderToRotate: "Move the slider to rotate",
    moreAboutStraps: "More about straps",
    readAboutMicromilspec: "Read about micromilspec",
    followUs: "Follow us on social",
    tryYourEngraving: "Type your engraving",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    saleEndsAt: "Sale ends in"
  },
  sv: {
    imageGallery: "Bildgalleri",
    buy: "Köp nu",
    specifications: "Specifikationer",
    showAllSpecifications: "Visa alla specifikationer",
    designDetails: "Design detaljer",
    swipeToSeeMore: "Svep för att se mer",
    tryEngraving: "Testa hur din egen gravering kommer se ut",
    charactersRemaining: "återstående karaktärer",
    interactive360Viewing: "Interaktiv 360 ° visning",
    interactiveView: "Interaktiv visning",
    moveSliderToRotate: "Flytta reglaget för att rotera",
    moreAboutStraps: "Mer om de tre olika remmarna",
    readAboutMicromilspec: "Läs om micromilspec",
    followUs: "Följ oss på sociala",
    tryYourEngraving: "Type your engraving",
    days: "Dagar",
    hours: "Timmar",
    minutes: "Minuter",
    seconds: "Sekunder",
    saleEndsAt: "Försäljningen slutar"
  },
  nb: {
    imageGallery: "Bildegalleri",
    buy: "Kjøp nå",
    specifications: "Spesifikasjoner",
    showAllSpecifications: "Vis alle spesifikasjoner",
    designDetails: "Design detaljer",
    swipeToSeeMore: "Swipe for å se mer",
    tryEngraving: "Prøv din personaliserte gravering",
    charactersRemaining: "tegn igjen",
    interactive360Viewing: "Interaktiv 360 visning",
    interactiveView: "Interaktiv visning",
    moveSliderToRotate: "Beveg slideren for å rotere klokken",
    moreAboutStraps: "Mer om remmer",
    readAboutMicromilspec: "Les mer om Micromilspec",
    followUs: "Følg oss",
    tryYourEngraving: "Skriv inngravering her",
    days: "Dager",
    hours: "Timer",
    minutes: "Minutter",
    seconds: "Sekunder",
    saleEndsAt: "Salget stenger"
  }
}

let language = "en"

export function setLanguage(newLanguage) {
  if (!Object.keys(data).includes(newLanguage)) {
    console.warn(
      `setLanguage called with invalid language ${newLanguage}, defaulting to '${language}'`
    )
    return
  }
  language = newLanguage
}

function getStaticString(id) {
  const value = data[language][id]
  if (!value) {
    console.warn(`Missing static string for language:${language} id:${id}`)
    return ""
  }
  return value
}

export const staticStrings = {
  methods: {
    getStaticString
  }
}
