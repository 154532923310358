<template>
  <checkout-total :data="formData">
    <template v-slot:default="slotProps">
      <div v-if="step === 1">
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Tilpass klokken din
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  Du er bare noen få skritt unna å fullføre bestillingen
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Velg pakke"
          subtitle="En av fire alternativer">
          <p class="mb-8 text-sm">
            Alle pakker inkluderer personlig inngravering, displayboks, verktøy for remskifte og ekthetsbevis utstedt av
            MICROMILSPEC. Alle lenker og remmer passer alle håndledd
          </p>
          <radio-list
            :options="products"
            v-model="formData.product">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.product.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.product.id">
                  <span
                    class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Valgt</span>
                </div>
                <div class="flex items-center text-sm justify-between mt-4 mb-2 -ml-4 -mr-4">
                  <div class="w-5/12 px-4">
                    <zoom-image>
                      <img
                        v-if="item.featured_image"
                        :src="item.featured_image.startsWith('http') ? item.featured_image : asset(item.featured_image)"
                        :alt="item.name"
                        class="object-contain h-40 mx-auto">
                      <template v-slot:zoomed>
                        <div>
                          <p class="font-xl block mb-4 text-center">
                            {{ item.name }}
                          </p>
                          <img
                            :src="item.meta.full_size_image.startsWith('http') ? item.meta.full_size_image : asset(item.meta.full_size_image)"
                            alt="">
                        </div>
                      </template>
                    </zoom-image>
                  </div>
                  <div class="w-7/12 px-4">
                    <h2 class="text-lg leading-tight text-white mb-2">
                      {{ item.name }}
                    </h2>
                    <p class="text-gray-mm-300 block mb-4">
                      {{ item.description }}
                    </p>
                    <p class="text-yellow-mm-600">
                      {{ item.price | formatPrice }} nå,
                    </p> eller <span>{{ item.price / 6 | formatPrice }}</span> per mnd i 6 mnd.
                  </div>
                </div>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Legg til ekstra reimer"
          subtitle="Klikk for å legge til">
          <template v-slot:rightcol>
            <div class="block xl:-mr-20 sm:mt-12 sm:mr-12">
              <video
                autoplay
                loop
                muted
                playsinline>
                <source
                  :src="formDefaults.checkout.strap_video_url"
                  type="video/mp4">
              </video>
            </div>
          </template>
          <p class="mb-8 text-sm text-gray-mm-300">
            (Lenke, skinn og gummi rem er inkludert i klokkepakken)
          </p>
          <checkbox-list
            class="flex flex-wrap items-stretch -ml-2 -mr-2 mb-4"
            :options="addons"
            v-model="formData.addons"
            option-class="px-2 mb-4 w-1/2">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 flex text-sm sm:text-base"
                :class="{ 'bg-gray-mm-600': formData.addons.includes(item) }">
                <div>
                  <img
                    :src="asset(item.featured_image)"
                    :alt="item.name"
                    class="object-contain h-24">
                </div>
                <div class="pl-4">
                  <h3 class="font-bold leading-tight">
                    {{ item.name }}
                  </h3>
                  <p class="text-gray-mm-300 text-sm">
                    {{ item.price | formatPrice }}
                  </p>
                  <in-cart
                    :item="item"
                    :cart="formData.addons">
                    Valgt
                  </in-cart>
                </div>
              </div>
            </template>
          </checkbox-list>
        </checkout-section>

        <checkout-section
          title="Tilknytning til avdelingen"
          subtitle="Velg én">
          <div
            v-if="false"
            class="w-full md:px-12">
            <figure class="my-4">
              <img
                :src="formData.relationship_option.image"
                :alt="formData.relationship_option.id">
            </figure>
          </div>
          <div class="w-full md:px-12">
            <figure class="my-4 relative">
              <img
                src="https://micromilspec-assets.s3.eu-north-1.amazonaws.com/brannvakt/dial-without-text.png"
                class="mx-auto"
                alt="Default">

              <div
                class="absolute w-full text-center"
                :style="{ top: '70.5%' }">
                <div class="resizeme font-henderson">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 500 200"
                    preserveAspectRatio="xMinYMin meet">
                    <foreignObject
                      width="100%"
                      height="100%"
                      xmlns="http://www.w3.org/1999/xhtml">
                      <div xmlns="http://www.w3.org/1999/xhtml">
                        <div
                          class="font-henderson uppercase"
                          :style="{ fontSize: '18px', textShadow: '-1px 1px rgba(89, 74, 52, 0.40)', color: '#A99F8C' }">
                          <span v-if="formData.relationship_option.id == 'Uten tekst'" />
                          <span v-else>{{ formData.relationship_option.id }}</span>
                        </div>
                      </div>
                    </foreignObject>
                  </svg>
                </div>
              </div>
            </figure>
          </div>
          <select-field-object
            :options="relationshipOptions"
            v-model="formData.relationship_option"
            :grouped="formData.relationship_options_grouped ? true : false"
            placeholder="Velg avdeling"
            class="mb-4">
            <template slot-scope="{ item }">
              {{ item.id }}
            </template>
          </select-field-object>

          <p>
            Finner du ikke det du leter etter på listen? Ta kontakt med <a
              href="mailto:h@micromilspec.com">h@micromilspec.com</a> for oppføring. Avdelingsnavn kan endres helt frem til produksjonsstart.
          </p>
        </checkout-section>

        <checkout-engraving
          :value="formData.engraving_line_1"
          :image="formDefaults.checkout.caseback_image_url.startsWith('http') ? formDefaults.checkout.caseback_image_url : asset(formDefaults.checkout.caseback_image_url)"
          :is-dark="false"
          caseback-kind="open-caseback-silver">
          <div class="lg:max-w-md py-8 sm:py-12">
            <h3 class="text-xl lg:text-3xl leading-tight mb-4">
              Velg din unike inngravering
            </h3>
            <p class="text-gray-mm-900 lg:text-lg mb-6">
              Du kan endre inngraveringen helt frem til produksjonsstart på https://hq.micromilspec.com
            </p>
            <p class="text-gray-mm-900 mb-3">
              Din personlige tekst
            </p>
            <input-field
              placeholder="Skriv inngravering her"
              :maxlength="30"
              v-model="formData.engraving_line_1"
              class="mb-6"
              bg-class="bg-gray-mm-500" />
            <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
              Eksempel: Navn, motto, datoer eller viktige milepæler.
            </p>
          </div>
        </checkout-engraving>

        <div
          class="container mx-auto bg-gray-mm-600"
          v-if="false">
          <div class="lg:w-1/2">
            <engraving
              :native-width="800"
              :native-height="800"
              :inputs="[
                {
                  label: 'Din personlige tekst',
                  placeholder: 'Skriv inngravering her',
                  maxCharacters: 30,
                  center: 0,
                  radius: .268,
                  value: formData.engraving_line_1
                },
                {
                  label: 'Din personlige tekst',
                  placeholder: 'Skriv inngravering her',
                  maxCharacters: 30,
                  center: 0,
                  radius: .268,
                  value: formData.engraving_line_2
                },
              ]">
              <img
                :src="formDefaults.checkout.caseback_image_url.startsWith('http') ? formDefaults.checkout.caseback_image_url : asset(formDefaults.checkout.caseback_image_url)"
                alt="">
            </engraving>
          </div>
        </div>

        <div class="container mx-auto">
          <div class="mx-auto lg:w-1/3 pt-8 pb-24 sm:pb-32">
            <default-button
              tertiary
              @click.prevent.native="step = 2"
              class="w-full">
              Neste steg
            </default-button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Steg 2 av 3
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  Du er bare noen få skritt unna å fullføre bestillingen
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Bestillingsinformasjon"
          subtitle="Dobbel- og trippelsjekk">
          <input-field
            placeholder="Fornavn"
            name="firstname"
            v-model="formData.customer.first_name"
            class="mb-3" />
          <input-field
            placeholder="Etternavn"
            name="surname"
            v-model="formData.customer.last_name"
            class="mb-3" />
          <input-field
            placeholder="Gateadresse"
            name="street_address"
            v-model="formData.customer.address_line_1"
            class="mb-3" />
          <input-field
            placeholder="Poststed"
            name="city"
            v-model="formData.customer.city"
            class="mb-3" />
          <input-field
            placeholder="Postnummer"
            name="postal_code"
            v-model="formData.customer.postal_code"
            class="mb-3" />
          <select-field
            :options="countries"
            option-key="Code"
            option-value="Name"
            name="country"
            placeholder="Velg land"
            v-model="formData.customer.country"
            class="mb-3" />
          <input-field
            placeholder="Mobilnummer"
            name="phone"
            v-model="formData.customer.phone"
            class="mb-3" />
          <input-field
            placeholder="Epost"
            name="email"
            v-model="formData.customer.email"
            class="mb-3" />
        </checkout-section>

        <checkout-section
          title="Leveringsadresse"
          subtitle="Hvor skal vi sende klokken din?">
          <input-checkbox
            v-model="formData.use_separate_shipping_address"
            class="mb-3">
            Bruk en separat leveringsadresse
          </input-checkbox>
          <div v-if="formData.use_separate_shipping_address">
            <input-field
              placeholder="Fornavn"
              name="shipping_first_name"
              v-model="formData.shipping_first_name"
              class="mb-3" />
            <input-field
              placeholder="Etternavn"
              name="shipping_last_name"
              v-model="formData.shipping_last_name"
              class="mb-3" />
            <input-field
              placeholder="Gateadresse"
              name="shipping_street_address"
              v-model="formData.shipping_address_line_1"
              class="mb-3" />
            <input-field
              placeholder="Gateadresse linje 2"
              name="shipping_street_address_line_2"
              v-model="formData.shipping_address_line_2"
              class="mb-3" />
            <select-field
              :options="countries"
              option-key="Code"
              option-value="Name"
              name="shipping_country"
              placeholder="Land"
              v-model="formData.shipping_country"
              class="mb-3" />
            <input-field
              placeholder="By"
              name="shipping_city"
              v-model="formData.shipping_city"
              class="mb-3" />
            <input-field
              v-if="formData.shipping_country === 'US'"
              placeholder="Delstat"
              name="shipping_state"
              v-model="formData.shipping_state"
              class="mb-3" />
            <input-field
              placeholder="Postnummer"
              name="shipping_postal_code"
              v-model="formData.shipping_postal_code"
              class="mb-3" />
          </div>
        </checkout-section>

        <checkout-section
          title="Leveringsadresse"
          subtitle="Velg én">
          <radio-list
            :options="deliveryOptions"
            v-model="formData.delivery_option">
            <template slot-scope="{ item }">
              <div class="py-2 flex items-center text-sm sm:text-base">
                <span
                  class="radio-icon mr-3"
                  :class="{ 'radio-icon--checked': formData.delivery_option.id === item.id }" />
                <span>{{ item.name }} - {{ item.description }}</span>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Betal i ditt eget tempo"
          subtitle="Ingen renter. Ingen kostnader."
          class="pb-24 sm:pb-32">
          <div v-if="paymentMethods.length > 1">
            <div class="mb-4">
              <h4 class="mb-3 text-sm sm:text-lg font-bold">
                Betalingsvalg
              </h4>
            </div>
            <radio-list
              :options="paymentMethods"
              v-model="formData.payment_method">
              <template slot-scope="{ item }">
                <div
                  class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                  :class="{ 'bg-gray-mm-600': item.id === formData.payment_method }">
                  <div
                    class="absolute top-0 right-0 mx-4 -mt-5"
                    v-if="item === formData.payment_method">
                    <span
                      class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Valgt</span>
                  </div>
                  <div v-if="item === 'stripe-checkout'">
                    <div class="flex font-bold items-center">
                      <span>Betal nå</span>
                    </div>
                    <p class="text-gray-mm-300">
                      Du blir videresendt til vår betalingsside etter du fullfører bestilling.
                    </p>
                  </div>
                  <div v-else>
                    <div class="flex font-bold items-center">
                      <span>Utsett betaling / Faktura</span>
                    </div>
                    <p class="text-gray-mm-300">
                      Del betalingen i to rentefrie nedbetalinger.
                    </p>
                  </div>
                </div>
              </template>
            </radio-list>
          </div>

          <div v-if="formData.payment_method === 'stripe-invoice'">
            <div class="mt-12 mb-4">
              <h4 class="mb-3 text-sm sm:text-lg font-bold">
                Utsett betaling
              </h4>
            </div>
            <input-field
              placeholder="ddmmåå"
              label="Fødselsdato (6-siffer) ddmmåå"
              name="ssn"
              v-model="formData.customer.dob"
              class="mb-3" />
            <p class="text-gray-mm-300 text-sm mb-8">
              Dette feltet må fylles ut ved betalingsutsettelse for at vi skal kunne innvilge kreditt.
            </p>
            <radio-list
              :options="paymentOptions"
              v-model="formData.payment_option">
              <template slot-scope="{ item }">
                <div
                  class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                  :class="{ 'bg-gray-mm-600': item.id === formData.payment_option.id }">
                  <div
                    class="absolute top-0 right-0 mx-4 -mt-5"
                    v-if="item.id === formData.payment_option.id">
                    <span
                      class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Valgt</span>
                  </div>
                  <div class="flex font-bold items-center mb-2">
                    <span>{{ item.title }}</span>
                    <span class="ml-auto pl-3">{{ slotProps.total / item.terms | formatPrice }}</span>
                  </div>
                  <p class="text-gray-mm-300">
                    {{ item.description }}
                  </p>
                </div>
              </template>
            </radio-list>
          </div>

          <div class="my-16">
            <h4 class="mb-3 text-sm sm:text-base">
              Bestillingsvilkår
            </h4>
            <div class="bg-gray-mm-600 p-4 text-white h-48 overflow-y-scroll text-sm sm:text-base">
              <div v-html="salesTerms" />
            </div>
          </div>

          <div class="checkbox-list my-8 text-sm sm:text-base">
            <p class="my-3">
              <input-checkbox v-model="formData.terms">
                Jeg har lest og aksepterer kjøpsbetingelsene.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.privacy_terms">
                Jeg godtar betingelsene i <a
                  :href="route('checkout.privacy-policy')"
                  target="_blank"
                  class="underline">personvernerklæringen</a> knyttet til kjøp hos Micromilspec AS.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.newsletter">
                Jeg ønsker å motta informasjon om fremtidige lanseringer.
              </input-checkbox>
            </p>
          </div>

          <div class="my-8 content text-gray-mm-300 text-sm">
            <p>* Ubetalte bestillinger kan kanselleres uten forvarsel av MICROMILSPEC.</p>
            <p>
              * Klokken er spesialprodusert og satt sammen for hånd i Sveits. Alle bestillinger er derfor bindene og
              ikke refunderbare.
            </p>
            <p>
              * Forventet produksjon og leveringstid er minimum 24 uker. Det er i tillegg beregnet potensielle
              forsinkelser på 60 dager grunnet Covid-19.
            </p>
          </div>

          <validation-errors />

          <default-button
            tertiary
            :loading="submitting"
            @click.prevent.native="submit"
            class="w-full mb-2">
            Fullfør bestilling
          </default-button>
          <default-button
            @click.prevent.native="step = 1"
            class="w-full text-gray-mm-300">
            Gå tilbake
          </default-button>
        </checkout-section>
      </div>
    </template>
  </checkout-total>
</template>

<script>
import countries from "@/utils/countries.json"
import CheckoutSection from "@/Shared/CheckoutSection.vue"
import CheckoutTotal from "@/Shared/CheckoutTotal.vue"
import RadioList from "@/Shared/RadioList.vue"
import CheckboxList from "@/Shared/CheckboxList.vue"
import InCart from "@/Shared/InCart.vue"
import CheckoutEngraving from "@/Shared/CheckoutEngraving.vue"
import ZoomImage from "@/Shared/ZoomImage.vue"

export default {
  components: {
    CheckoutSection,
    CheckoutTotal,
    RadioList,
    CheckboxList,
    InCart,
    CheckoutEngraving,
    ZoomImage
  },

  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },

    addons: {
      type: Array,
      default () {
        return []
      }
    },

    paymentMethods: {
      type: Array,
      default () {
        return []
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    },

    formDefaults: {
      type: Object,
      default () {
        return {}
      }
    },

    siteId: {
      type: String,
      default: null
    },

    salesTerms: {
      type: String,
      default: null
    },

    checkoutFlowSignup: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    this.formData = { ...this.formData, ...this.formDefaults }
    this.countries = countries

    this.relationshipOptions = this.formDefaults.relationship_options
  },

  data () {
    return {
      step: 1,
      checkoutComponent: "checkout-default",
      formData: {
        product: {},
        payment_option: {},
        payment_method: {},
        delivery_option: {},
        relationship_option: {},
        relationship_description: null,
        addons: [],
        customer: {
          first_name: null,
          last_name: null,
          address_line_1: null,
          city: null,
          postal_code: null,
          country: null,
          phone: null,
          email: null
        },
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_address_line_1: null,
        shipping_address_line_2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_postal_code: null,
        shipping_country: null,
        engraving_line_1: null,
        engraving_line_2: null,
        privacy_terms: false,
        terms: false,
        newsletter: false,
        use_separate_shipping_address: false
      },
      relationshipOptions: [],
      paymentOptions: [
        {
          id: "two",
          title: "Del opp betaling i to",
          description: "Betal halvparten nå og resterende om 31 dager.",
          terms: 2
        },
      ],
      submitting: false,
      countries: []
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(`/${this.siteId}`, this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    relationshipDescriptionPlaceholder() {
      let id = this.formData.relationship_option.id
      let active = this.relationshipOptions.find(option => option.id === id)

      if (active) {
        return active.description
      }

      return this.relationshipOptions[0].description
    }
  },

  watch: {
    step () {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'henderson';
  src: url("https://micromilspec-assets.s3.eu-north-1.amazonaws.com/brannvakt/henderson.otf") format("opentype");
}

.font-henderson {
  font-family: 'henderson', Arial, Helvetica, sans-serif;
}

.resizeme {
  resize: both;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
