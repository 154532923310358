<template>
  <layout>
    <figure class="max-w-4xl mx-auto pt-8 md:pt-20">
      <img
        :src="asset('images/home-splash.jpg')"
        alt="Micromilspec"
        class="mx-auto w-full">
    </figure>
    <div class="bg-gray-mm-500 py-8 sm:py-16">
      <div class="container mx-auto">
        <div class="w-full max-w-md md:max-w-lg mx-auto">
          <div class="w-8 text-white mb-8">
            <svg
              class="fill-current text-white"
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.1457 5H30.4497C20.8435 5 12.7322 11.2155 10.3175 19.7049H2.5V21.3292C2.5 30.3293 8.70296 37.9392 17.2437 40.4388V45H18.9397C28.5511 45 36.6363 38.7608 39.0682 30.2951H47.5V28.6708C47.5 19.4749 41.0139 11.6838 32.1457 9.38714V5ZM28.7538 8.32629V12.0077L30.1196 12.2673C37.5668 13.6827 43.2869 19.6797 44.0269 27.0466H36.3464L36.0694 28.346C34.5506 35.4717 28.3164 40.9622 20.6357 41.6734V37.9096L19.3281 37.6149C12.1549 35.9985 6.6947 30.1396 5.97309 22.9534H13.0473L13.321 21.6493C14.8193 14.5113 21.0666 9.03501 28.7538 8.32629Z" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.6583 39.196V29.602H17.2663V39.196H20.6583Z" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.1457 18.2106V10.6741H28.7538V18.2106H32.1457Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.4498 30.295H39.088V27.0465H30.4498V30.295Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.9623 19.7049H7.88191V22.9534H18.9623V19.7049Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.181 26.4618V21.9572H24.0503V26.4618H25.181Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.241 24.7509H26.9672V23.6681H22.241V24.7509Z"
              />
            </svg>
          </div>
          <div class="mb-4">
            <div class="my-8">
              <p class="text-sm uppercase text-yellow-mm-600 tracking-widest">
                Custom professional watches
              </p>
              <h1 class="text-2xl md:text-3xl leading-tight my-4">
                Go behind the scenes of custom watchmaking and gain access to our made-to-order timepieces, material selection tests and exclusive collaborations.
              </h1>
            </div>
          </div>
          <pre-signup :site-id="3" />
          <div class="my-8">
            <p class="mb-4 md:mr-40">
              Interested in collaborating on a custom timepiece? <a
                href="mailto:projects@micromilspec.com"
                class="text-yellow-mm-600 underline">Get in touch.</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import PreSignup from "@/Shared/PreSignup.vue"

export default {
  components: {
    Layout,
    PreSignup
  },

  created () {
    try {
      window.fathom("trackPageview")
    } catch (e) {}
  },

  data () {
    return {
      faq: [
        {
          title: "Why does the N.3 cost NOK 8900?",
          content: "The N.3 is custom made, meaning that there is no existing mold to manufacture the timepiece. The machines used to create components such as the case, straps, dial, index markers, viking ship, glass, crown, hands and back case, are all custom calibrated to manufacture the N.3 timepiece. The movement is Swiss Made, a world renowned seal of approval in quality and craftsmanship, and it consists of sixty-five individual parts coming together in perfect harmony through the utmost precision and handcraft in Switzerland. "
        },
        {
          title: "Do you offer down payments?",
          content: "Yes, you can choose to pay in intervals over 2 or 4 months."
        },
        {
          title: "What is Swiss Made?",
          content: "True to the great tradition of Swiss watchmaking, the N.3 is in compliance with the Swiss Made Ordinance (Adopted 1st January 2019) as defined by the The Swiss Federal Council. Swiss Made is a protected title that requires a watch to have a Swiss Made movement and at least 60% of the watch (and it's parts) made in Switzerland. Final assembly and quality inspection must also be done in Switzerland."
        },
        {
          title: "What is a mechanical movement and what is automatic?",
          content: "A mechanical watch operates without batteries. The N.3 is powered by the movement of your hand, hence it is automatically wind. Kinetic energy automatically transfers to the inner mechanic of your timepiece by a rotor in perfect harmony with 24 jewels and 65 components. Mechanically displayed by sweeping hand motions with a 42 hour power reserve."
        },
        {
          title: "When will my timepiece be finished? ",
          content: "Production time is estimated to 24 weeks. "
        },
        {
          title: "What does my 5 year worldwide warranty cover?",
          content: "Your watch is covered by a five-year international warranty against any defects in manufacturing; this includes the watch movement, indexes, dial, straps and hands. In other words, if the watch parts or mechanics don't work properly, and it's our fault, we will repair or replace your product for free. However, this does not cover any abuse or physical damage inflicted on the watch. The warranty does not cover water damage, normal wear and tear, accidental glass damage, scratches, strap damage, or theft. Please note that leather straps are not waterproof although the watch is water resistant to 100 meters. If the product is returned during the warranty period, Micro Milspec will repair or replace any defective watch or watch-part, at no additional expense, within a reasonable time, after the product is returned. However, the customer may be subject to the costs of shipping the product."
        },
        {
          title: "Do I have to wind my N.3 or use batteries?",
          content: "No, the N.3 is automatic and will power itself once you put it on your wrist. The 42hr power reserve may take up to 4-5 hours to fully wind. You can wind your crown until you sense a change in friction and the watch will be fully powered the next 42hr even on your desk."
        },
        {
          title: "Where can I service my watch and how much does it cost?",
          content: "The N.3 timepiece is made to stand the test of time. Every component can be repaired or replaced. There is no battery in the watch, it is purely mechanical. Maintenance on the mechanical movement is vital in order to improve and uphold the endurance of your timepiece, and the watch mechanics needs to be cleaned, balanced and oiled. Such a maintenance service interval is recommended every 5 years for mechanical watches, regardless of brand. A maintenance service, called a full overhaul where the watch is extensively cared for, would typically cost NOK 3700. A full overhaul is offered to owners of N.3 timepieces for NOK 2500 with our Micro Milspec approved watchmaker."
        },
        {
          title: "Can the watch withstand severe weather and strain?",
          content: "Yes, however the watch will not withstand being dropped directly onto a hard surface such as concrete, bathroom tiles etc. or direct contact with other hard surfaces at high velocity. This may damage the glass and inner workings of the watch. "
        },
        {
          title: "Who can buy the N.3?",
          content: "People affiliated with Telemark Bataljonen."
        },
        {
          title: "How can I look after my MM?",
          content: "A dedicated link with instructions and watch care will be sent to your order email."
        },
        {
          title: "Can a MM be polished?",
          content: "Yes and no, the watch can be polished, but you will remove the brushed finish. However the watch will inevitably look stunning either way."
        },
      ],
      sliderItems: [
        {
          title: "Backpack",
          desktopSrc: this.asset("images/tmbn/backpack.jpg"),
          mobileSrc:  this.asset("images/tmbn/backpack-mob.jpg")
        },
        {
          title: "Soldier",
          desktopSrc: this.asset("images/tmbn/soldier.jpg"),
          mobileSrc:  this.asset("images/tmbn/soldier-mob.jpg")
        },
        {
          title: "Angled",
          desktopSrc: this.asset("images/tmbn/angled.jpg"),
          mobileSrc:  this.asset("images/tmbn/angled-mob.jpg")
        },
        {
          title: "The box",
          desktopSrc: this.asset("images/tmbn/box.jpg"),
          mobileSrc:  this.asset("images/tmbn/box-mob.jpg")
        },
        {
          title: "Closeup",
          desktopSrc: this.asset("images/tmbn/closeup.jpg"),
          mobileSrc:  this.asset("images/tmbn/closeup-mob.jpg")
        },
        {
          title: "Engraved caseback",
          desktopSrc: this.asset("images/tmbn/caseback.jpg"),
          mobileSrc:  this.asset("images/tmbn/caseback-mob.jpg")
        },
      ],

      featureItems: [
        {
          title: "Warranty",
          content: "5 years",
          icon: this.asset("images/icons/warranty.svg")
        },
        {
          title: "Self-winding movement",
          content: "Swiss Made Automatic",
          icon: this.asset("images/icons/self-winding.svg")
        },
        {
          title: "Water resistance",
          content: "10 ATM/100 M",
          icon: this.asset("images/icons/water-resistance.svg")
        },
        {
          title: "Sapphire glass",
          content: "Scratch resistant anti-reflective sapphire crystal",
          icon: this.asset("images/icons/glass.svg")
        },
        {
          title: "Power reserve",
          content: "42 hrs power reserve",
          icon: this.asset("images/icons/power-reserve.svg")
        },
        {
          title: "Certificate of authenticity",
          content: "With your name & serial number",
          icon: this.asset("images/icons/certificate.svg")
        },
        {
          title: "Luminous",
          content: "Renowed Swiss Super-Luminova",
          icon: this.asset("images/icons/luminous.svg")
        },
        {
          title: "Stainless steel case",
          content: "316L surgical grade stainless brushed steel case",
          icon: this.asset("images/icons/case.svg")
        },
        {
          title: "Display box",
          content: "Beautiful wooden box housing your watch",
          icon: this.asset("images/icons/watch-box.svg")
        },
        {
          title: "Surprise object",
          content: "We include a custom item unique to our collaboration",
          icon: this.asset("images/icons/surprise-object.svg")
        },
      ]
    }
  }
}
</script>
