<template>
  <transition
    enter-class="transform translate-y-full"
    enter-active-class="transition-all duration-300 ease-out-quad transform"
    enter-to-class="transform translate-y-0"
    leave-class="opacity-100 transform translate-y-0"
    leave-active-class="transition-all duration-100 ease-in-quad"
    leave-to-class="opacity-0 transform translate-y-full"
    appear
  >
    <div
      v-if="open"
      class="sales-modal">
      <button
        @click.stop="close"
        class="absolute top-0 right-0 p-4 focus:outline-none">
        <CloseButton />
      </button>

      <slot />
    </div>
  </transition>
</template>

<script>
import CloseButton from "./CloseButton.vue"

export default {
  props: ["open"],

  created() {
    const onEscape = (e) => {
      if (this.open && e.keyCode === 27) {
        this.close()
      }
    }

    document.addEventListener("keydown", onEscape)

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape)
    })
  },
  methods: {
    close() {
      this.$emit("close")
    }
  },
  components: {
    CloseButton
  }
}
</script>
