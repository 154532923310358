<template>
  <layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="w-8 text-white mb-8">
            <svg
              class="fill-current text-white"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.575 9.4446C20.6336 4.01384 15.9856 0 10.3705 0H9.4446V4.42583C4.01384 5.36656 0 10.0144 0 15.6295V16.5554H4.40684C5.34757 21.9862 9.99538 26 15.6114 26H16.5373V21.5753C21.9788 20.6351 26 15.9871 26 10.3705V9.4446H21.575V9.4446ZM16.5373 19.7037V17.2037H14.6855V24.1048C10.504 23.7087 7.12821 20.644 6.27858 16.5554H8.79627V14.7036H1.89525C2.2913 10.5229 5.35605 7.1472 9.4446 6.29757V8.79627H11.2964V1.89525C15.4771 2.2913 18.8537 5.35605 19.7033 9.4446H17.1847V11.2964H24.1048C23.7078 15.4778 20.6357 18.8547 16.5373 19.7037Z"
                fill="white" />
            </svg>
          </div>
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Hi {{ customer.first_name }}, these are your orders with Micromilspec.
              </h1>
            </div>
          </div>

          <div class="mb-4">
            <div
              class="mb-2"
              v-for="(order,index) in orders"
              :key="index">
              <div class="py-4 border-t border-gray-800 mb-4">
                <div class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-px">
                  Order Id
                </div>
                <div class="mr-2 mb-4">
                  {{ order.id }}
                </div>
                <div class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-px">
                  Date
                </div>
                <div class="mb-4">
                  {{ order.created_at }}
                </div>
                <div class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-px">
                  Total
                </div>
                <div class="mb-4">
                  {{ order.amount | formatPriceWithDecimals(order.currency) }}
                </div>
                <inertia-link
                  :href="route('account.orders.show', order.id)"
                  class="inline-block w-auto px-4 pt-1 pb-2 border-2 border-yellow-mm-600 bg-yellow-mm-600 text-black font-medium text-sm leading-tight rounded-sm">
                  Manage Order
                </inertia-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"

export default {
  props: {
    customer: {
      type: Object,
      default () {
        return {}
      }
    },

    orders: {
      type: Array,
      default () {
        return []
      }
    }
  },

  components: {
    Layout
  }
}
</script>
