<template>
  <div class="max-w-lg mx-auto px-8 py-12 bg-gray-mm-500 text-white rounded my-8">
    <div
      class="p-3 block text-center"
      v-if="fetching">
      <p>Henter faktura...</p>
    </div>

    <div
      class="invoice-dl"
      v-if="invoice">
      <dl>
        <dt class="font-semibold">
          MICROMILSPEC <br><span class="text-gray-mm-300">Faktura</span>
        </dt>
        <dd>
          {{ customer.first_name }} {{ customer.last_name }} <br>
          {{ customer.address_line_1 }} <br>
          {{ customer.postal_code }} {{ customer.city }}
        </dd>
        <dt>Kontonummer</dt>
        <dd class="font-bold">
          1506.26.45579
        </dd>
        <dt>KID</dt>
        <dd class="font-bold">
          {{ invoice.kid }}
        </dd>
        <dt>Beløp</dt>
        <dd class="font-bold">
          {{ invoice.amount | formatPriceWithDecimals }}
        </dd>
      </dl>
      <p class="text-sm block mt-4">
        For international orders:<br>BIC (Swift: DNBANOKKXXX)<br>Electronic  Iban + bank account number:  NO9415062645579<br>Paperformat Iban + bank account number:  NO94 1506 2645 579<br>Bank Adress: DNB, Postboks 1600 Sentrum, 0021 Oslo
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  props: {
    order: {
      type: Object,
      default () {
        return {}
      }
    },

    customer: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      invoice: null,
      fetchAttempt: 0,
      fetching: true,
      interval: null
    }
  },

  mounted () {
    this.fetchInvoice()
    this.interval = setInterval(this.fetchInvoice, 5000)
  },

  methods: {
    fetchInvoice () {
      if (this.fetchAttempt > 10 || this.invoice !== null) return
      axios.get(`/confirmation/${this.order.id}/invoice`)
        .then((response) => {
          this.invoice = response.data.data
          this.fetching = false
          clearInterval(this.interval)
        })
        .catch(() => {
          this.fetchAttempt = this.fetchAttempt + 1
        })
    }
  }
}
</script>
