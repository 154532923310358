<template>
  <div>
    <slot :total="total" />
    <div class="fixed bottom-0 left-0 w-full p-4 bg-yellow-mm-600 text-black text-center">
      <slot name="copy">
        Din totalsum
      </slot> <span
        v-if="discountAmount > 0"
        class="line-through">{{ subtotal | formatPrice }}</span> <span class="font-bold">{{ total | formatPrice }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    discount: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    productAmount () {
      return this.data.product.price || 0
    },

    subtotal () {
      let productTotal = this.productAmount

      let deliveryTotal = this.data.delivery_option.price || 0
      let addonTotal = this.data.addons.reduce((total, addon) => total + addon.price, 0)

      return this.arrSum([productTotal, addonTotal, deliveryTotal])
    },

    total () {
      return this.subtotal - this.discountAmount
    },

    discountAmount () {
      let discountAmount = 0

      if (this.discount && this.discount.amount && this.discount.type == "percentage") {
        discountAmount = this.productAmount * (this.discount.amount / 100)
      }

      if (this.discount && this.discount.amount && this.discount.type == "fixed") {
        discountAmount = this.discount.amount
      }

      return discountAmount
    }
  },

  methods: {
    arrSum: (arr) => arr.reduce((a,b) => a + b, 0)
  }
}
</script>
