<template>
  <div
    :class="{fading}"
    id="scroll-prompt"
  >
    <vue100vh style="{height: '100rvh'}">
      <img
        v-if="active"
        class="bounce"
        :src="asset('images/sales/scroll-prompt.svg')">
    </vue100vh>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import vue100vh from "vue-100vh"

export default {
  mixins: [prismicMixins, staticStrings],
  components: {
    vue100vh
  },
  data () {
    return {
      active: true,
      fading: false
    }
  },
  created () {
    window.addEventListener("scroll", this.handleScroll.bind(this))
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll.bind(this))
  },
  methods: {
    handleScroll () {
      this.fading = true
      setTimeout(() => {
        this.active = false
      },2000)
    }
  }
}
</script>
