<template>
  <layout>
    <div class="bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto sm:my-48">
          <div class="mb-4">
            <div class="mb-8 text-center">
              <h1 class="text-lg uppercase text-yellow-mm-600 tracking-widest mb-3">
                {{ siteName }} Not Available
              </h1>
              <p>This timepiece if not available for purchase. Use the form below to signup and we will notify you of any cancellations or opportunities to order this timepiece.</p>
            </div>

            <form @submit.prevent="submit">
              <div>
                <div class="mb-4">
                  <input-field
                    v-model="formData.email"
                    type="email"
                    placeholder="Enter email"
                    class="text-sm"
                    autofocus />
                </div>
                <div class="mb-6 text-sm">
                  <input-checkbox v-model="formData.privacy_terms">
                    I have read and agree to the <a
                      :href="route('checkout.privacy-policy')"
                      target="_blank"
                      class="underline">Privacy Policy</a>.
                  </input-checkbox>
                </div>
                <default-button
                  tertiary
                  class="w-full"
                  :loading="submitting"
                  :disabled="submitting">
                  Subscribe for updates
                </default-button>
              </div>
              <validation-errors />
            </form>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"

export default {
  props: {
    siteSlug: {
      type: String,
      default: null
    },

    siteName: {
      type: String,
      default: null
    }
  },

  components: {
    Layout
  },

  created () {
    try {
      window.fathom("trackPageview")
    } catch (e) {}
  },

  data () {
    return {
      formData: {
        email: null,
        privacy_terms: false
      },
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(`/${this.siteSlug}/subscribe`, this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
