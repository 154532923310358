<template>
  <layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <article class="text-center max-w-6xl mx-auto">
          <header class="sm:mt-12 max-w-4xl mx-auto">
            <div class="w-8 text-white mx-auto mb-6">
              <svg
                class="fill-current text-white"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.575 9.4446C20.6336 4.01384 15.9856 0 10.3705 0H9.4446V4.42583C4.01384 5.36656 0 10.0144 0 15.6295V16.5554H4.40684C5.34757 21.9862 9.99538 26 15.6114 26H16.5373V21.5753C21.9788 20.6351 26 15.9871 26 10.3705V9.4446H21.575V9.4446ZM16.5373 19.7037V17.2037H14.6855V24.1048C10.504 23.7087 7.12821 20.644 6.27858 16.5554H8.79627V14.7036H1.89525C2.2913 10.5229 5.35605 7.1472 9.4446 6.29757V8.79627H11.2964V1.89525C15.4771 2.2913 18.8537 5.35605 19.7033 9.4446H17.1847V11.2964H24.1048C23.7078 15.4778 20.6357 18.8547 16.5373 19.7037Z"
                  fill="white" />
                <path
                  d="M13.4576 11.6782H12.5316V12.5399H11.6699V13.4659H12.5316V14.3267H13.4576V13.4659H14.3184V12.5399H13.4576V11.6782Z"
                  fill="white" />
              </svg>
            </div>
            <h2 class="sm:text-xl uppercase leading-tight block text-yellow-mm-600">
              Swiss Made
            </h2>
            <h1 class="text-xl sm:text-5xl tracking-widest uppercase font-black leading-tight block my-2">
              Oscar
            </h1>
            <p class="font-medium block uppercase my-2 sm:text-xl leading-tight">
              &times; <span class="font-bold">MicroMilspec</span>
            </p>
          </header>

          <div class="max-w-3xl mx-auto my-8 sm:my-12 relative">
            <figure>
              <img
                :src="asset('images/oscar/hero.png')"
                alt="Panser">
            </figure>
          </div>

          <div class="max-w-lg mx-auto">
            <div class="flex items-stretch justify-between text-xs mt-8 sm:mt-8 sm:mb-8 flex-wrap sm:flex-no-wrap">
              <div class="w-1/2 sm:w-auto border border-white p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="17"
                    height="20"
                    viewBox="0 0 17 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.2857 2.85714C12.3809 2.85714 9.52376 1.90476 8.09519 0V20C15.7142 17.1429 16.1904 12.381 16.1904 9.52381V2.85714H14.2857Z"
                      fill="#676767" />
                    <mask
                      id="path-2-inside-1"
                      fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.09524 0C6.66667 1.90476 3.80952 2.85714 1.90476 2.85714H0V9.52381C0 12.381 0.47619 17.1429 8.09524 20C15.7143 17.1429 16.1905 12.381 16.1905 9.52381V2.85714H14.2857C12.381 2.85714 9.52381 1.90476 8.09524 0Z" />
                    </mask>
                    <path
                      d="M8.09524 0L8.89524 -0.6L8.09524 -1.66667L7.29524 -0.6L8.09524 0ZM0 2.85714V1.85714H-1V2.85714H0ZM8.09524 20L7.74411 20.9363L8.09524 21.068L8.44636 20.9363L8.09524 20ZM16.1905 2.85714H17.1905V1.85714H16.1905V2.85714ZM1.90476 3.85714C3.00488 3.85714 4.3269 3.58648 5.56663 3.06449C6.80448 2.54329 8.043 1.73631 8.89524 0.6L7.29524 -0.6C6.7189 0.16845 5.81457 0.790042 4.79051 1.22122C3.76834 1.65161 2.70941 1.85714 1.90476 1.85714V3.85714ZM0 3.85714H1.90476V1.85714H0V3.85714ZM1 9.52381V2.85714H-1V9.52381H1ZM8.44636 19.0637C4.8104 17.7002 2.99551 15.9311 2.06205 14.2716C1.11814 12.5936 1 10.8985 1 9.52381H-1C-1 11.0063 -0.880045 13.1207 0.318901 15.2522C1.5283 17.4022 3.76103 19.4427 7.74411 20.9363L8.44636 19.0637ZM8.44636 20.9363C12.4294 19.4427 14.6622 17.4022 15.8716 15.2522C17.0705 13.1207 17.1905 11.0063 17.1905 9.52381H15.1905C15.1905 10.8985 15.0723 12.5936 14.1284 14.2716C13.195 15.9311 11.3801 17.7002 7.74411 19.0637L8.44636 20.9363ZM17.1905 9.52381V2.85714H15.1905V9.52381H17.1905ZM16.1905 1.85714H14.2857V3.85714H16.1905V1.85714ZM14.2857 1.85714C13.4811 1.85714 12.4221 1.65161 11.4 1.22122C10.3759 0.790042 9.47158 0.16845 8.89524 -0.6L7.29524 0.6C8.14747 1.73631 9.38599 2.54329 10.6238 3.06449C11.8636 3.58648 13.1856 3.85714 14.2857 3.85714V1.85714Z"
                      fill="white"
                      mask="url(#path-2-inside-1)" />
                  </svg>
                </div>
                <div>
                  5 års garanti
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white border-l-0 p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.5 0.5H13.1364V19.5H0.5V0.5Z"
                      stroke="white" />
                    <path
                      d="M6.36365 6.81818H10V12.2727L8.18183 10.9091L6.36365 12.2727V6.81818Z"
                      fill="#676767" />
                    <circle
                      cx="8.18177"
                      cy="5"
                      r="2.63636"
                      fill="#676767"
                      stroke="#333030"
                      stroke-width="2" />
                  </svg>
                </div>
                <div>
                  Spesiallaget
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white sm:border-l-0 border-t-0 sm:border-t p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="17.5"
                      y="9.5"
                      width="1"
                      height="7.5"
                      rx="0.5"
                      transform="rotate(90 17.5 9.5)"
                      fill="white" />
                    <circle
                      cx="10"
                      cy="10"
                      r="9.5"
                      stroke="#676767" />
                    <path
                      d="M5 15L10 10"
                      stroke="white"
                      stroke-linecap="round" />
                    <path
                      d="M11.5 8.5L10 10"
                      stroke="white"
                      stroke-linecap="round" />
                    <circle
                      cx="10"
                      cy="10"
                      r="1"
                      fill="#676767"
                      stroke="white"
                      stroke-width="2" />
                  </svg>
                </div>
                <div>
                  GMT urverk
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white border-l-0 border-t-0 sm:border-t p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="22"
                    height="23"
                    viewBox="0 0 22 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 22.4499C17.0751 22.4499 22 17.525 22 11.4499H0C0 17.525 4.92487 22.4499 11 22.4499Z"
                      fill="#676767" />
                    <path
                      d="M11 1C5.22867 1 0.550049 5.67862 0.550049 11.45H21.45C21.45 5.67862 16.7714 1 11 1Z"
                      stroke="white" />
                    <circle
                      cx="11"
                      cy="11.4499"
                      r="2.25"
                      fill="#333030"
                      stroke="white" />
                  </svg>
                </div>
                <div>
                  Swiss made
                </div>
              </div>
            </div>
          </div>
          <div class="max-w-4xl mx-auto">
            <div class="mt-16 pb-48 sm:px-16">
              <h2 class="mb-6 font-bold text-3xl leading-tight">
                Tilgjengelig fra 15. mai til og med 30. mai
              </h2>
              <p class="mb-6 font-bold text-xl">
                Begrenset område, åpner 15. mai 12:00
              </p>

              <div class="sm:flex items-center max-w-xs mx-auto">
                <input-field
                  disabled
                  placeholder="Pin"
                  class="flex-grow mb-2 sm:mb-0 opacity-75" />
                <default-button
                  disabled
                  class="sm:ml-2 text-base rounded-sm w-full sm:w-auto opacity-50"
                  tertiary>
                  Logg inn
                </default-button>
              </div>
            </div>
          </div>

          <div
            v-if="false"
            class="z-50 py-12 fixed bottom-0 left-0 w-full">
            <inertia-link
              :href="route('customize')"
              class="inline-block w-auto px-4 shadow-lg pt-1 pb-2 border-2 border-yellow-mm-600 bg-yellow-mm-600 text-black font-medium text-lg leading-tight rounded-full">
              Order Now
            </inertia-link>
          </div>
        </article>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import PreSignup from "@/Shared/PreSignup.vue"

export default {
  components: {
    Layout,
    PreSignup,
  },

  data () {
    return {
    }
  }
}
</script>
