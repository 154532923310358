<template>
  <portal to="modals">
    <div
      v-if="showModal"
      class="modal overflow-y-auto fixed inset-0 flex items-center justify-center z-20">
      <transition
        @before-leave="backdropLeaving = true"
        @after-leave="backdropLeaving = false"
        enter-active-class="transition-all transition-fast ease-out-quad"
        leave-active-class="transition-all transition-fast ease-in-quad"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <div
          v-if="showBackdrop"
          @click="close">
          <div class="fixed inset-0 bg-black opacity-25" />
        </div>
      </transition>

      <transition
        @before-leave="cardLeaving = true"
        @after-leave="cardLeaving = false"
        enter-active-class="transition-all duration-300 ease-out-quad transform"
        leave-active-class="transition-all duration-100 ease-in-quad"
        enter-class="transform translate-y-full"
        enter-to-class="transform translate-y-0"
        leave-class="opacity-100 transform translate-y-0"
        leave-to-class="opacity-0 transform translate-y-full"
        appear
      >
        <div
          v-if="showContent"
          class="relative bg-gray-mm-600 p-6 py-8 sm:p-10 shadow-lg modal-content h-cover lg:h-auto overflow-auto">
          <button
            @click="close"
            class="absolute top-0 right-0 p-4 focus:outline-none">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.5">
                <circle
                  cx="14"
                  cy="14"
                  r="13"
                  stroke="white"
                  stroke-width="2" />
                <rect
                  x="9.75732"
                  y="11.1716"
                  width="2"
                  height="10"
                  transform="rotate(-45 9.75732 11.1716)"
                  fill="white" />
                <rect
                  x="11.1714"
                  y="18.2427"
                  width="2"
                  height="10"
                  transform="rotate(-135 11.1714 18.2427)"
                  fill="white" />
              </g>
            </svg>
          </button>
          <slot />
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
export default {
  props: ["open"],

  data() {
    return {
      showModal: false,
      showBackdrop: false,
      showContent: false,
      backdropLeaving: false,
      cardLeaving: false,
    }
  },
  created() {
    const onEscape = (e) => {
      if (this.open && e.keyCode === 27) {
        this.close()
      }
    }

    document.addEventListener("keydown", onEscape)

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape)
    })
  },
  watch: {
    open: {
      handler: function (newValue) {
        if (newValue) {
          this.show()
        } else {
          this.close()
        }
      },
      immediate: true
    },

    leaving(newValue) {
      if (newValue === false) {
        this.showModal = false
        this.$emit("close")
      }
    }
  },

  computed: {
    leaving() {
      return this.backdropLeaving || this.cardLeaving
    }
  },

  methods: {
    show() {
      this.showModal = true
      this.showBackdrop = true
      this.showContent = true
    },

    close() {
      this.showBackdrop = false
      this.showContent = false
    }
  }
}
</script>
