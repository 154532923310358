<template>
  <div>
    <label
      class="block text-gray-mm-700 text-sm mb-2"
      v-if="label">
      {{ label }}
    </label>
    <div class="inline-block relative w-full">
      <select
        v-model="model"
        :disabled="disabled"
        :name="name"
        class="block appearance-none w-full bg-gray-mm-600 px-4 py-4 pr-8 rounded-sm leading-tight focus:outline-none focus:shadow-outline placeholder-white"
        :class="{ 'text-gray-mm-100': disabled, 'text-white': !disabled }">
        <option
          :value="{}"
          disabled>
          {{ placeholder }}
        </option>
        <template v-if="grouped">
          <optgroup
            :label="group.label"
            v-for="(group,gIndex) in options"
            :key="`group-${gIndex}`">
            <option
              v-for="(option,index) in group.options"
              :value="option"
              :key="index">
              <slot :item="option">
                {{ option }}
              </slot>
            </option>
          </optgroup>
        </template>
        <template v-else>
          <option
            v-for="(option,index) in options"
            :value="option"
            :key="index">
            <slot :item="option">
              {{ option }}
            </slot>
          </option>
        </template>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
        <svg
          class="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    },

    optionValue: {
      type: String,
      default: "name"
    },

    optionKey: {
      type: String,
      default: "id"
    },

    label: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: ""
    },

    name: {
      type: String,
      default: null
    },

    value: {
      type: [String, Number, Object],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    grouped: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
