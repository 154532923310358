<template>
  <div
    id="internals-box"
    class="box white rounded internals text-center">
    <video
      autoplay
      loop
      muted
      playsinline>
      <source
        :src="data.internals_video.url"
        type="video/mp4">
    </video>
    <div class="copy py-8 px-8 lg:px-24">
      <h2 class="mb-4 font-bold text-2xl leading-tight">
        {{ data.internals_headline }}
      </h2>
      <div
        class="content text-lg"
        v-html="asHtml(data.internals_content)" />
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mixins: [prismicMixins, staticStrings]
}
</script>
