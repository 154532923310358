<template>
  <div
    id="design-details-box"
    class="box white rounded lg:px-8 xl:px-16">
    <div class="top">
      <div class="mb-8 font-bold">
        <div class="flex items-start">
          <div>
            <div class="title">
              <h2 class="text-2xl leading-tight">
                {{ data.design_details_title }}
              </h2>
            </div>
            <p class="subtitle text-2xl leading-tight">
              {{ data.design_details_subtitle }}
            </p>
          </div>
          <div class="ml-auto pl-8">
            <img
              :src="data.design_details_icon.url"
              class="h-12">
          </div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="controls container mx-auto">
        <div class="pagination-dd" />
        <div class="arrow-nav">
          <div :class="`swiper-arrow-prev swiper-arrow-prev-dd`">
            <img
              :src="asset('images/sales/slider-left.svg')">
          </div>
          <div
            :class="`swiper-arrow-next swiper-arrow-next-dd`">
            <img
              :src="asset('images/sales/slider-right.svg')">
          </div>
        </div>
      </div>

      <slider
        class="container mx-auto overflow-visible"
        :data="data.design_details"
        slide-class=""
        :options="{ spaceBetween: 20, centeredSlides: true, pagination: {el: '.pagination-dd'}, navigation: {nextEl: '.swiper-arrow-next-dd', prevEl: '.swiper-arrow-prev-dd'}}">
        <template v-slot:default="{ slide }">
          <div>
            <figure class="mb-4">
              <img
                :src="slide.image.url"
                :alt="slide.image.alt">
            </figure>

            <div
              class="content"
              v-html="asHtml(slide.content)" />
          </div>
        </template>
      </slider>
    </div>
    <div class="desktop">
      <div
        v-for="(slide,index) in data.design_details"
        :key="index"
        class="slide"
      >
        <figure class="mb-4">
          <img
            :src="slide.image.url"
            :alt="slide.image.alt">
        </figure>

        <div
          class="content"
          v-html="asHtml(slide.content)" />
      </div>
    </div>
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import Slider from "../Slider.vue"

export default {
  mixins: [prismicMixins, staticStrings],

  components: {
    Slider
  },

  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>
