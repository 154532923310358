<template>
  <layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="w-8 text-white mb-8">
            <svg
              class="fill-current text-white"
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.1457 5H30.4497C20.8435 5 12.7322 11.2155 10.3175 19.7049H2.5V21.3292C2.5 30.3293 8.70296 37.9392 17.2437 40.4388V45H18.9397C28.5511 45 36.6363 38.7608 39.0682 30.2951H47.5V28.6708C47.5 19.4749 41.0139 11.6838 32.1457 9.38714V5ZM28.7538 8.32629V12.0077L30.1196 12.2673C37.5668 13.6827 43.2869 19.6797 44.0269 27.0466H36.3464L36.0694 28.346C34.5506 35.4717 28.3164 40.9622 20.6357 41.6734V37.9096L19.3281 37.6149C12.1549 35.9985 6.6947 30.1396 5.97309 22.9534H13.0473L13.321 21.6493C14.8193 14.5113 21.0666 9.03501 28.7538 8.32629Z" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.6583 39.196V29.602H17.2663V39.196H20.6583Z" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.1457 18.2106V10.6741H28.7538V18.2106H32.1457Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.4498 30.295H39.088V27.0465H30.4498V30.295Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.9623 19.7049H7.88191V22.9534H18.9623V19.7049Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.181 26.4618V21.9572H24.0503V26.4618H25.181Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.241 24.7509H26.9672V23.6681H22.241V24.7509Z"
              />
            </svg>
          </div>
          <div class="mb-4">
            <div class="my-8">
              <p class="text-sm uppercase text-yellow-mm-600 tracking-widest">
                Customer Portal — Restricted Area
              </p>
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Enter the pin sent to your phone number.
              </h1>
            </div>

            <form @submit.prevent="submit">
              <div>
                <div class="mb-2 w-full">
                  <input-field
                    v-model="formData.code"
                    type="text"
                    placeholder="Pin code"
                    autofocus />
                  <validation-errors />
                </div>
                <default-button
                  tertiary
                  :loading="submitting"
                  :disabled="submitting">
                  Submit
                </default-button>
              </div>
            </form>

            <div class="mt-8">
              <p>
                If you have troubles signing in, please reach out to us by email. <a
                  href="sos@micromilspec.com"
                  class="text-yellow-mm-600 underline">sos@micromilspec.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"

export default {
  components: {
    Layout
  },

  data () {
    return {
      formData: {
        code: null
      },
      error: null,
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post("/verify-otp", this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
