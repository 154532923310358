<template>
  <checkout-total :data="formData">
    <template v-slot:copy>
      Din total
    </template>
    <template v-slot:default="slotProps">
      <div v-if="step === 1">
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Anpassa din klocka
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  Ett par steg till så är klockan snart på väg.
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Välj ditt paket"
          subtitle="Ett av fyra alternativ">
          <p class="mb-8 text-sm">
            Alla paket inkluderar: Personlig gravyr, vackert klocketui, verktyg för byte av remmar, äkthetscertifikat. Länk och armband passar alla handleder.
          </p>
          <radio-list
            :options="products"
            v-model="formData.product">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.product.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.product.id">
                  <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Vald</span>
                </div>
                <div class="flex items-center text-sm justify-between mt-4 mb-2 -ml-4 -mr-4">
                  <div class="w-5/12 px-4">
                    <zoom-image>
                      <img
                        v-if="item.featured_image"
                        :src="item.featured_image"
                        :alt="item.name"
                        class="object-contain h-40 mx-auto">
                      <template v-slot:zoomed>
                        <div>
                          <p class="font-xl block mb-4 text-center">
                            {{ item.name }}
                          </p>
                          <img
                            :src="item.meta.full_size_image"
                            alt="">
                        </div>
                      </template>
                    </zoom-image>
                  </div>
                  <div class="w-7/12 px-4">
                    <h2 class="text-lg leading-tight text-white mb-2">
                      {{ item.name }}
                    </h2>
                    <p class="text-gray-mm-300 block mb-4">
                      {{ item.description }}
                    </p>
                    <p class="text-yellow-mm-600">
                      {{ item.price | formatPrice }}, inkl moms,
                    </p> eller <span>{{ item.price / 6 | formatPrice }}</span> /mo i sex månader.
                  </div>
                </div>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Lägg till de armband du vill ha"
          subtitle="Om du valt stål och vill ha ytterligare ett par armband">
          <template v-slot:rightcol>
            <div class="block xl:-mr-20 sm:mt-12 sm:mr-12">
              <video
                autoplay
                loop
                muted
                playsinline>
                <source
                  :src="formDefaults.checkout.strap_video_url"
                  type="video/mp4">
              </video>
            </div>
          </template>
          <p class="mb-8 text-sm text-gray-mm-300">
            De s.k. Natoremmarna är mjuka, behagliga och ger klockan ett tufft uttryck.
          </p>
          <checkbox-list
            class="flex flex-wrap items-stretch -ml-2 -mr-2 mb-4"
            :options="addons"
            v-model="formData.addons"
            option-class="px-2 mb-4 w-1/2">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 flex text-sm sm:text-base"
                :class="{ 'bg-gray-mm-600': formData.addons.includes(item) }">
                <div>
                  <img
                    :src="asset(item.featured_image)"
                    :alt="item.name"
                    class="object-contain h-24">
                </div>
                <div class="pl-4">
                  <h3 class="font-bold leading-tight">
                    {{ item.name }}
                  </h3>
                  <p class="text-gray-mm-300 text-sm">
                    {{ item.price | formatPrice }}
                  </p>
                  <in-cart
                    :item="item"
                    :cart="formData.addons">
                    Vald
                  </in-cart>
                </div>
              </div>
            </template>
          </checkbox-list>
        </checkout-section>

        <checkout-engraving
          :value="formData.engraving_line_1"
          :image="formDefaults.checkout.caseback_image_url"
          :is-dark="false">
          <div class="lg:max-w-md py-8 sm:py-12">
            <h3 class="text-xl lg:text-3xl leading-tight mb-4">
              Välj din egen, unika gravering
            </h3>
            <p class="text-gray-mm-900 lg:text-lg mb-6">
              Du kan ändra din gravyr när som helst innan produktionen startar på https://hq.micromilspec.com
            </p>
            <p class="text-gray-mm-900 mb-3">
              Välj din personliga gravyr - namn, personligt motto, datum eller annat som är viktigt för dig.
            </p>
            <input-field
              placeholder="Din gravyr här"
              :maxlength="30"
              v-model="formData.engraving_line_1"
              class="mb-6"
              bg-class="bg-gray-mm-500" />
          </div>
        </checkout-engraving>

        <div class="container mx-auto">
          <div class="mx-auto lg:w-1/3 pt-8 pb-24 sm:pb-32">
            <default-button
              primary
              @click.prevent.native="step = 2"
              class="w-full">
              Nästa steg
            </default-button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Steg 2 av 3
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  Ett par steg till så är vi klara.
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Din kontaktinformation">
          <input-field
            placeholder="Förnamn"
            name="firstname"
            v-model="formData.customer.first_name"
            class="mb-3" />
          <input-field
            placeholder="Efternamn"
            name="surname"
            v-model="formData.customer.last_name"
            class="mb-3" />
          <input-field
            placeholder="Gatuadress"
            name="street_address"
            v-model="formData.customer.address_line_1"
            class="mb-3" />
          <select-field
            :options="countries"
            option-key="Code"
            option-value="Name"
            name="country"
            placeholder="Välj land"
            v-model="formData.customer.country"
            class="mb-3" />
          <input-field
            placeholder="City"
            name="city"
            v-model="formData.customer.city"
            class="mb-3" />
          <input-field
            placeholder="postnummer"
            name="postal_code"
            v-model="formData.customer.postal_code"
            class="mb-3" />
          <input-field
            placeholder="Telefon"
            name="phone"
            v-model="formData.customer.phone"
            class="mb-3" />
          <input-field
            placeholder="E-post"
            name="email"
            v-model="formData.customer.email"
            class="mb-3" />
        </checkout-section>

        <checkout-section
          title="Leverans"
          subtitle="Vart ska vi skicka din klocka?">
          <input-checkbox
            v-model="formData.use_separate_shipping_address"
            class="mb-3">
            Använd en separat leveransadress
          </input-checkbox>
          <div v-if="formData.use_separate_shipping_address">
            <input-field
              placeholder="Förnamn"
              name="shipping_first_name"
              v-model="formData.shipping_first_name"
              class="mb-3" />
            <input-field
              placeholder="Efternamn"
              name="shipping_last_name"
              v-model="formData.shipping_last_name"
              class="mb-3" />
            <input-field
              placeholder="Gatuadress"
              name="shipping_street_address"
              v-model="formData.shipping_address_line_1"
              class="mb-3" />
            <input-field
              placeholder="Gatuadress linje 2"
              name="shipping_street_address_line_2"
              v-model="formData.shipping_address_line_2"
              class="mb-3" />
            <select-field
              :options="countries"
              option-key="Code"
              option-value="Name"
              name="shipping_country"
              placeholder="Välj land"
              v-model="formData.shipping_country"
              class="mb-3" />
            <input-field
              placeholder="By"
              name="shipping_city"
              v-model="formData.shipping_city"
              class="mb-3" />
            <input-field
              placeholder="Postnummer"
              name="shipping_postal_code"
              v-model="formData.shipping_postal_code"
              class="mb-3" />
          </div>
        </checkout-section>

        <checkout-section
          v-if="false"
          title="Din anslutning till enheten"
          subtitle="Välj en">
          <radio-list
            class="mb-4"
            :options="relationshipOptions"
            v-model="formData.relationship_option">
            <template slot-scope="{ item }">
              <div class="py-2 flex items-center text-sm sm:text-base">
                <span
                  class="radio-icon mr-3"
                  :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                <span>{{ item.title }}</span>
              </div>
            </template>
          </radio-list>

          <input-field
            v-if="formData.relationship_option.id === 'other'"
            :placeholder="relationshipDescriptionPlaceholder"
            v-model="formData.relationship_description"
            class="mb-3" />
        </checkout-section>

        <checkout-section
          title="Leverans">
          <p>Din klocka skickas med spårning</p>
        </checkout-section>

        <checkout-section
          title="Betala i din egen takt"
          subtitle="Inga avgifter. Ingen ränta."
          class="pb-24 sm:pb-32">
          <radio-list
            :options="paymentOptions"
            v-model="formData.payment_option">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.payment_option.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.payment_option.id">
                  <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Vald</span>
                </div>
                <div class="flex font-bold items-center mb-2">
                  <span>{{ item.title }}</span>
                  <span class="ml-auto pl-3">{{ slotProps.total / item.terms | formatPrice }}</span>
                </div>
                <p class="text-gray-mm-300">
                  {{ item.description }}
                </p>
              </div>
            </template>
          </radio-list>

          <div v-if="paymentMethods.length > 1">
            <div class="my-16">
              <h4 class="mb-3 text-sm sm:text-base">
                Betalningsmetod
              </h4>
            </div>
            <radio-list
              :options="paymentMethods"
              v-model="formData.payment_method">
              <template slot-scope="{ item }">
                <div
                  class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                  :class="{ 'bg-gray-mm-600': item.id === formData.payment_method }">
                  <div
                    class="absolute top-0 right-0 mx-4 -mt-5"
                    v-if="item === formData.payment_method">
                    <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Vald</span>
                  </div>
                  <div class="flex font-bold items-center mb-2">
                    <span>{{ item }}</span>
                  </div>
                </div>
              </template>
            </radio-list>
          </div>

          <div class="my-16">
            <h4 class="mb-3 text-sm sm:text-base">
              Köpvillkor
            </h4>
            <div class="bg-gray-mm-600 p-4 text-white h-48 overflow-y-scroll text-sm sm:text-base">
              <div v-html="salesTerms" />
            </div>
          </div>

          <div class="checkbox-list my-8 text-sm sm:text-base">
            <p class="my-3">
              <input-checkbox v-model="formData.terms">
                Jag har läst och accepterat köpvillkoren.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.privacy_terms">
                I accept the <a
                  :href="route('checkout.privacy-policy')"
                  target="_blank"
                  class="underline">privacy policy</a> när det gäller inköp från Micromilspec AS.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.newsletter">
                Jag vill få information om framtida lanseringar.
              </input-checkbox>
            </p>
          </div>

          <div class="my-8 content text-gray-mm-300 text-sm">
            <p>* Obetalda beställningar kan avbokas utan föregående meddelande av MICROMILSPEC.</p>
            <p>* Detta ur är specialtillverkat och monterat för hand i Schweiz. Alla beställningar är bindande och återbetalas inte.</p>
            <p>* Uppskattad produktion och leveranstid är minst 24 veckor. Dessutom kan ytterligare 60 dagar med potentiella förseningar på grund av Covid-19 inträffa.</p>
          </div>

          <validation-errors />

          <default-button
            primary
            :loading="submitting"
            @click.prevent.native="submit"
            class="w-full mb-2">
            Avsluta beställning
          </default-button>
          <default-button
            @click.prevent.native="step = 1"
            class="w-full text-gray-mm-300">
            Go back
          </default-button>
        </checkout-section>
      </div>
    </template>
  </checkout-total>
</template>

<script>
import countries from "@/utils/countries.json"
import CheckoutSection from "@/Shared/CheckoutSection.vue"
import CheckoutTotal from "@/Shared/CheckoutTotal.vue"
import RadioList from "@/Shared/RadioList.vue"
import CheckboxList from "@/Shared/CheckboxList.vue"
import InCart from "@/Shared/InCart.vue"
import CheckoutEngraving from "@/Shared/CheckoutEngraving.vue"
import ZoomImage from "@/Shared/ZoomImage.vue"

export default {
  components: {
    CheckoutSection,
    CheckoutTotal,
    RadioList,
    CheckboxList,
    InCart,
    CheckoutEngraving,
    ZoomImage,
  },

  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },

    addons: {
      type: Array,
      default () {
        return []
      }
    },

    paymentMethods: {
      type: Array,
      default () {
        return []
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    },

    formDefaults: {
      type: Object,
      default () {
        return {}
      }
    },

    siteId: {
      type: String,
      default: null
    },

    salesTerms: {
      type: String,
      default: null
    }
  },

  mounted () {
    this.formData = { ...this.formData, ...this.formDefaults }
    this.countries = countries
  },

  data () {
    return {
      step: 1,
      checkoutComponent: "checkout-default-swedish",
      formData: {
        product: {},
        payment_option: {},
        payment_method: {},
        delivery_option: {},
        relationship_option: {},
        relationship_description: null,
        addons: [],
        customer: {
          first_name: null,
          last_name: null,
          address_line_1: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          phone: null,
          email: null,
        },
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_address_line_1: null,
        shipping_address_line_2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_postal_code: null,
        shipping_country: null,
        engraving_line_1: null,
        engraving_line_2: null,
        terms: false,
        privacy_terms: false,
        newsletter: false,
        use_separate_shipping_address: false
      },
      relationshipOptions: [
        {
          id: "active",
          title: "I aktiv tjänst",
          description: "Vilken division arbetar du i?"
        },
        {
          id: "past",
          title: "Veteran",
          description: "Vilken division arbetade du i?"
        },
        {
          id: "invited",
          title: "Speciellt inbjuden",
          description: "Namn på personen som bjöd in dig"
        },
        {
          id: "other",
          title: "Övrig",
          description: "Ange din anslutning"
        }
      ],
      paymentOptions: [
        {
          id: "delayed",
          title: "Betala inom 45 dagar",
          description: "En faktura som ska betalas om 45 dagar skickas till din e -post.",
          terms: 1
        },
        {
          id: "two",
          title: "Betala hälften inom 45 dagar",
          description: "En andra faktura skickas ut inom 30 dagar efter den första.",
          terms: 2
        },
        {
          id: "six",
          title: "Sex delbetalningar",
          description: "Betala 1/6 på 45 dagar. Under de kommande 6 månaderna får du ytterligare 5 fakturor.",
          terms: 6
        },
      ],
      submitting: false,
      countries: []
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(`/${this.siteId}`, this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    relationshipDescriptionPlaceholder() {
      let id = this.formData.relationship_option.id
      let active = this.relationshipOptions.find(option => option.id === id)

      if (active) {
        return active.description
      }

      return this.relationshipOptions[0].description
    }
  },

  watch: {
    step () {
      window.scrollTo(0,0)
    }
  }
}
</script>
