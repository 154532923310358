<template>
  <layout :cart-is-open-by-default="cartIsOpenByDefault">
    <div class="container mx-auto">
      <div
        class="sm:mx-auto max-w-7xl bg-contain bg-no-repeat pt-20 sm:pt-36 lg:pt-48 -ml-4 -mr-4 px-4 sm:px-0"
        :style="{ backgroundImage: `url('https://micromilspec-assets.s3.eu-north-1.amazonaws.com/shop/hero-banner.png')` }">
        <div class="mx-auto md:max-w-5xl xl:max-w-6xl">
          <header class="mb-8 md:mb-12 md:pt-8 font-medium">
            <h1 class="text-3xl sm:text-5xl md:text-6xl leading-tight mb-1 md:mb-2">
              Shop
            </h1>
            <div class="text-gray-mm-300 leading-tight md:text-lg">
              <p>Shop accessories and parts for your Micromilspec watch <br> or get some of our new merch.</p>
            </div>
          </header>

          <nav class="flex items-center justify-start md:text-xl">
            <a
              v-for="(collection,index) in collections.data.collections.edges"
              :key="index"
              :href="`#${collection.node.handle}`"
              class="mr-2 underline">{{ collection.node.title }}</a>
          </nav>

          <div
            v-for="(collection,index) in collections.data.collections.edges"
            :key="index"
            class="my-8 md:my-20"
            :id="collection.node.handle">
            <div class="font-medium">
              <h2 class="sm:text-3xl md:text-4xl leading-tight mb-1 md:mb-2">
                {{ collection.node.title }}
              </h2>
              <div class="text-gray-mm-300 leading-tight">
                <p>{{ collection.node.description }}</p>
              </div>
            </div>

            <div
              v-if="collection.node.products"
              class="my-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <product-card
                v-for="product in collection.node.products.edges"
                :product="product.node"
                :key="product.id" />
            </div>
            <div
              v-else
              class="my-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <div class="pb-full bg-gray-mm-600" />
              <div class="pb-full bg-gray-mm-600 hidden sm:block" />
              <div class="pb-full bg-gray-mm-600 hidden lg:block" />
              <div class="pb-full bg-gray-mm-600 hidden lg:block" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Pages/Webshop/Layout.vue"
import ProductCard from "@/Pages/Webshop/Partials/ProductCard.vue"

export default {
  props: {
    collections: {
      type: Object,
      default () {
        return {}
      }
    },

    cartIsOpenByDefault: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Layout,
    ProductCard
  }
}
</script>
