<template>
  <div class="grid gap-4">
    <div>
      <label
        class="block text-white text-sm mb-2"
        for="street_address">
        Address Line 1
      </label>
      <input
        type="search"
        name="street_address"
        autocomplete="off"
        placeholder="Address Line 1"
        ref="origin"
        class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
        v-model="formData.address_line_1">
    </div>

    <div>
      <label
        class="block text-white text-sm mb-2"
        for="street_address_2">
        Address Line 2
      </label>
      <input
        type="text"
        name="street_address_2"
        autocomplete="off"
        placeholder="Address Line 2"
        class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
        v-model="formData.address_line_2">
    </div>

    <div>
      <label
        class="block text-white text-sm mb-2"
        for="city">
        City
      </label>
      <input
        type="text"
        name="city"
        placeholder="City"
        v-model="formData.city"
        class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400">
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div>
        <label
          class="block text-white text-sm mb-2"
          for="state">
          Province / State
        </label>
        <input
          type="text"
          name="state"
          placeholder="State"
          v-model="formData.state"
          class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400">
      </div>

      <div>
        <label
          class="block text-white text-sm mb-2"
          for="state">
          Postal Code
        </label>
        <input
          type="text"
          name="postal_code"
          placeholder="Postal code"
          v-model="formData.postal_code"
          class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400">
      </div>
    </div>

    <div>
      <label
        class="block text-white text-sm mb-2"
        for="country">
        Country
      </label>
      <select-field
        :options="countries"
        option-key="Code"
        option-value="Name"
        name="country"
        placeholder="Choose Country"
        v-model="formData.country" />
    </div>

    <div
      v-if="errors"
      class="bg-yellow-300 text-black px-3 py-2 rounded-sm relative text-sm flex gap-2 mb-6"
      role="alert">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
      </div>

      <ul>
        <li
          v-for="(error,index) in errors"
          :key="index">
          <span v-if="Array.isArray(error)">
            <span
              v-for="(e,eIndex) in error"
              :key="`e-${eIndex}`">{{ e }}</span>
          </span>
          <span v-else>
            {{ error }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import countries from "@/utils/countries.json"
import axios from "axios"
import debounce from "lodash/debounce"

export default {
  mounted() {
    this.countries = countries

    const autocomplete = new google.maps.places.Autocomplete(this.$refs["origin"])

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace()

      this.formData.geo.lat = place.geometry.location.lat()
      this.formData.geo.lng = place.geometry.location.lng()

      if (place.address_components) {
        let route = place.address_components.filter(item => {
          return item.types.includes("route")
        })[0]?.long_name
        let streetNumber = place.address_components.filter(item => {
          return item.types.includes("street_number")
        })[0]?.long_name

        let addressLine1 = streetNumber ? `${route} ${streetNumber}` : route
        this.formData.address_line_1 = addressLine1

        let city = place.address_components.filter(item => {
          return item.types.includes("postal_town") || item.types.includes("locality")
        })[0]?.long_name
        this.formData.city = city

        this.formData.state = place.address_components.filter(item => {
          return item.types.includes("administrative_area_level_1")
        })[0]?.long_name
        this.formData.country = place.address_components.filter(item => {
          return item.types.includes("country")
        })[0]?.short_name
        this.formData.postal_code = place.address_components.filter(item => {
          return item.types.includes("postal_code")
        })[0]?.short_name
      }
    })
  },

  data () {
    return {
      formData: {
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        postal_code: null,
        country: null,
        geo: {
          lat: null,
          lng: null
        }
      },
      countries: null,
      errors: null
    }
  },

  methods: {
    validate () {
      this.errors = null
      this.submitting = true
      axios.post(this.route("checkout.validate-address"), this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then((response) => {
          this.validationData = response.data.data
          this.submitting = false
        })
        .catch(e => {
          this.errors = e.response.data.errors
          this.submitting = false
        })
    },
  },

  watch: {
    "formData": {
      handler: debounce(function (value) {

        if (this.formData.address_line_1 == null) return

        // this.validate()
        this.$emit("input", value)
      }, 1000),
      deep: true
    }
  }
}
</script>
