<template>
  <div>
    <slot
      :features="features"
      :handleClick="handleClick"
      @open="handleOpen" />

    <modal
      :open="modalOpen"
      @close="modalOpen = false">
      <transition
        :name="transition"
        mode="out-in">
        <div
          class="sm:px-12 py-4 overflow-hidden"
          :key="activeFeature.slot"
          v-if="activeFeature">
          <figure class="w-auto">
            <img
              v-if="activeFeature.modalImage"
              :src="activeFeature.modalImage"
              :alt="activeFeature.title"
              class="my-8">
          </figure>

          <div class="block my-3 sm:my-4 mt-auto text-center font-medium text-xl sm:text-2xl">
            {{ activeFeature.title }}
          </div>
          <div class="block my-4 mt-auto text-center text-sm sm:text-base">
            {{ activeFeature.description }}
          </div>
        </div>
      </transition>
      <button
        v-if="hasPrev"
        @click.prevent="prev"
        class="modal-prev uppercase text-sm p-2 flex flex-col items-center text-gray-mm-300 mx-4 focus:outline-none">
        <span>
          <svg
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path
                d="M9.75 3.5H1"
                stroke="white"
                stroke-linecap="round" />
              <path
                d="M3.5 1L1 3.5L3.5 6"
                stroke="white"
                stroke-linecap="round" />
            </g>
          </svg>
        </span>
        <span>
          Back
        </span>
      </button>
      <button
        v-if="hasNext"
        @click.prevent="next"
        class="modal-next uppercase text-sm p-2 flex flex-col items-center text-gray-mm-300 mx-4 focus:outline-none">
        <span>
          <svg
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
              <path
                d="M1 3.5H9.75"
                stroke="white"
                stroke-linecap="round" />
              <path
                d="M7.25 1L9.75 3.5L7.25 6"
                stroke="white"
                stroke-linecap="round" />
            </g>
          </svg>
        </span>
        <span>Next</span>
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  data () {
    return {
      features: [
        {
          title: "Mechanical movement",
          description: "Operating without batteries, the N.3 is powered by the movement of your hand. Kinetic energy automatically transfers to the inner mechanic of your timepiece by a rotor in perfect harmony with 24 jewels and 65 components. Mechanically displayed by sweeping hand motions with 42 hour power reserve.",
          image: this.asset("images/tmbn/feature-movement.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-movement.jpg"),
          slot: 1
        },
        {
          title: "N.3 Swiss Made",
          description: "Effortless functionality and elegance paired with industry leading materials. The rounded diamond shaped case is made of surgical grade stainless steel, housed by a sapphire crystal - the hardest mineral on earth next to diamond. Its inner workings are protected by 100m water resistant seal.",
          image: this.asset("images/tmbn/feature-watch.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-watch.jpg"),
          slot: 2
        },
        {
          title: "Custom hands",
          description: "To accompany the N.3 timepiece tactical expression, a distinguished set of hands have been designed with a mechanised profile inspired by instruments in TMBN's armoured personnel carriers and infantry fighting vehicles.",
          image: this.asset("images/tmbn/feature-hands.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-hands.jpg"),
          slot: 3
        },
        {
          title: "Hidden inscriptions",
          description: "A hidden message on the inside of the bezel ring only visible to the bearer: Respekt - Ansvar - Mot - Disiplin - Lojal - Selvstendig - Robust - Omsorgsfull",
          image: this.asset("images/tmbn/feature-chapter.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-chapter.jpg"),
          slot: 4
        },
        {
          title: "Bespoke Dial",
          description: "An intricate geometrical pattern of a \"Fylking\" adds to the dial's visual flare and depth. The dial's outer edge is surrounded by a brass ring in emerald green coating, mounted with index markers floating around the viking ship known from the heraldic symbol, resting on the outlines of a crest shaped as a shield.",
          image: this.asset("images/tmbn/feature-dial.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-dial.jpg"),
          slot: 5
        },
        {
          title: "Stainless steel bracelet",
          description: "With its seamless lines, the N.3 is recognised by its holistic transition to the stainless steel strap - cut within a tolerance of +-0.05m.",
          image: this.asset("images/tmbn/feature-bracelet.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-bracelet.jpg"),
          slot: 6
        },
        {
          title: "Dual time zones",
          description: "A bi-directional bezel adds to the functionality of the N.3 timekeeper by adjusting to a different time zone - allowing for two timezones on one timepiece.",
          image: this.asset("images/tmbn/feature-bezel.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-bezel.jpg"),
          slot: 7
        },
        {
          title: "TMBN case back",
          description: "A bold departure from traditional case backs, taking custom engraving to the next level.",
          image: this.asset("images/tmbn/feature-caseback.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-caseback.jpg"),
          slot: 8
        },
        {
          title: "Decorated crown",
          description: "Designed to give easy access to the inner workings of the movement for setting time and manual rewinding. ",
          image: this.asset("images/tmbn/feature-crown.jpg"),
          modalImage: this.asset("images/tmbn/feature-modal-crown.jpg"),
          slot: 9
        },
      ],
      activeFeature: null,
      modalOpen: false,
      forward: true
    }
  },

  computed: {
    transition () {
      return this.forward ? "slide-left" : "slide-right"
    },

    hasPrev() {
      let index = this.features.indexOf(this.activeFeature)
      return index > 0
    },

    hasNext() {
      let index = this.features.indexOf(this.activeFeature)
      return this.features.length - 1 > index
    }
  },

  created () {
    this.features
  },

  methods: {
    handleClick (feature) {
      this.modalOpen = true
      this.activeFeature = feature
    },

    next () {
      this.forward = true
      let index = this.features.indexOf(this.activeFeature)
      if (this.features.length - 1 > index) {
        this.activeFeature = this.features[index + 1]
      }
    },

    prev () {
      this.forward = false
      let index = this.features.indexOf(this.activeFeature)
       if (index > 0) {
         this.activeFeature = this.features[index - 1]
       }
    }
  }
}
</script>
