<template>
  <layout :cart-is-visible="false">
    <div class="container mx-auto">
      <div class="mx-auto max-w-5xl">
        <div class="grid sm:grid-cols-8 gap-4 md:gap-0">
          <div class="sm:col-span-4">
            <header class="mb-8 md:mt-8 font-medium">
              <h1 class="text-2xl sm:text-3xl md:text-4xl leading-tight mb-1">
                Checkout
              </h1>
              <div class="text-gray-mm-400 text-sm mt-2 leading-tight flex">
                <span class="mr-3">Information</span>
                <span class="mr-3">Shipping</span>
                <span class="mr-3 text-white">Payment</span>
              </div>
            </header>

            <div class="my-8 md:my-16 text-sm">
              <div class="p-2 sm:p-3 flex items-center border border-gray-mm-500 rounded-sm">
                <div class="w-20 flex-shrink-0 text-gray-mm-300">
                  Contact
                </div>
                <div class="ml-2">
                  {{ checkout.email }}
                </div>
                <div class="ml-auto pl-4 text-xs font-semibold">
                  <inertia-link :href="route('shop.checkout.contact-information', { id: checkout.id })">
                    Change
                  </inertia-link>
                </div>
              </div>
              <div class="p-2 sm:p-3 flex items-center border border-t-0 border-gray-mm-500 rounded-sm rounded-t-none">
                <div class="w-20 flex-shrink-0 text-gray-mm-300">
                  Ship to
                </div>
                <div class="ml-2">
                  {{ checkout.shipping_address_line_1 }}, {{ checkout.shipping_postal_code }} {{ checkout.shipping_city }}, {{ checkout.shipping_country }}
                </div>
                <div class="ml-auto pl-4 text-xs font-semibold">
                  <inertia-link :href="route('shop.checkout.contact-information', { id: checkout.id })">
                    Change
                  </inertia-link>
                </div>
              </div>
              <div class="p-2 sm:p-3 flex items-center border border-t-0 border-gray-mm-500 rounded-sm rounded-t-none">
                <div class="w-20 flex-shrink-0 text-gray-mm-300">
                  Method
                </div>
                <div class="ml-2">
                  {{ checkout.shipping_method.name }} - {{ checkout.shipping_method.description }}
                </div>
                <div class="ml-auto pl-4 text-xs font-semibold">
                  <inertia-link :href="route('shop.checkout.shipping', { id: checkout.id })">
                    Change
                  </inertia-link>
                </div>
              </div>
            </div>

            <div class="my-8 md:my-16">
              <h2 class="sm:text-2xl leading-tight mb-1 font-medium">
                Payment method
              </h2>
              <div class="text-gray-mm-300 leading-tight">
                <p>Choose your preferred payment method.</p>
              </div>
              <div class="my-6">
                <radio-list
                  :options="paymentMethods"
                  v-model="formData.payment_method">
                  <template slot-scope="{ item }">
                    <div class="py-2 flex items-center text-sm sm:text-base">
                      <span
                        class="radio-icon mr-3"
                        :class="{ 'radio-icon--checked': formData.payment_method.slug === item.slug }" />
                      <div>
                        <div>{{ item.name }}</div>
                      </div>
                    </div>
                  </template>
                </radio-list>
              </div>
            </div>

            <div class="md:mb-12 pt-8 border-t border-gray-mm-500 text-sm sm:text-base">
              <p class="my-3">
                <input-checkbox v-model="formData.terms">
                  I have read and accepted the terms of purchase.
                </input-checkbox>
              </p>
              <p class="my-3">
                <input-checkbox v-model="formData.privacy_terms">
                  I accept the <a
                    :href="route('checkout.privacy-policy')"
                    target="_blank"
                    class="underline">privacy policy</a> in regards to purchases from Micromilspec AS.
                </input-checkbox>
              </p>
              <p class="my-3">
                <input-checkbox v-model="formData.newsletter">
                  I wish to receive information of future launches.
                </input-checkbox>
              </p>
            </div>

            <validation-errors />

            <div class="flex flex-col items-center mt-8 mb-8 sm:mb-16">
              <default-button
                tertiary
                :loading="submitting"
                class="mb-4 w-full text-base"
                @click.prevent.native="submit">
                Continue with payment
              </default-button>
            </div>

            <div class="pb-8 md:pb-16 content text-gray-mm-300 text-sm">
              <p>* Unpaid orders can be cancelled without notice by MICROMILSPEC.</p>
              <p>* Each timepiece is custom made and assembled by hand in Switzerland. All orders are binding and non-refundable.</p>
              <p>* Estimated production and delivery time is minimum 24 weeks. In addition, another 60 days of potential delays due to Covid-19 may occur.</p>
              <p>** Tax calculations are estimates at this point but will be finalized when you proceed with payment.</p>
            </div>
          </div>

          <div class="sm:col-span-3 sm:col-start-6 sm:pt-28 mt-2">
            <div>
              <h2 class="sm:text-2xl leading-tight mb-1 font-medium">
                Summary
              </h2>

              <checkout-items-summary
                :items="checkout.items"
                class="mt-4 mb-8" />

              <div class="flex my-1 justify-between">
                <span>Items</span>
                <span>{{ checkout.items_count }}</span>
              </div>
              <div class="flex my-1 justify-between">
                <span>Subtotal</span>
                <total-price
                  :price="checkout.subtotal"
                  :currency="checkout.currency" />
              </div>
              <div class="flex my-1 justify-between">
                <span>Shipping</span>
                <total-price
                  :price="checkout.total_shipping"
                  :currency="checkout.currency" />
              </div>
              <div class="flex my-1 justify-between">
                <span>Tax</span>
                <total-price
                  :price="checkout.total_tax"
                  :currency="checkout.currency" />
              </div>
              <div class="flex mt-4 justify-between border-t border-gray-mm-500 pt-3">
                <span>Total</span>
                <total-price
                  :price="checkout.total"
                  :currency="checkout.currency"
                  class="font-medium" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Pages/Shop/Layout.vue"
import CheckoutItemsSummary from "@/Pages/Shop/Partials/CheckoutItemsSummary.vue"
import RadioList from "@/Shared/RadioList.vue"
import TotalPrice from "@/Pages/Shop/Partials/TotalPrice"

export default {
  props: {
    checkout: {
      type: Object,
      default () {
        return {}
      }
    },

    paymentMethods: {
      type: Array,
      default () {
        return []
      }
    },
  },

  components: {
    Layout,
    CheckoutItemsSummary,
    RadioList,
    TotalPrice
  },

  mounted () {
    this.formData.payment_method = this.paymentMethods[0]
  },

  data () {
    return {
      formData: {
        payment_method: {
          slug: null
        },
        terms: false,
        privacy_terms: false,
        newsletter: false
      },
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(this.route("shop.checkout.payment-methods.store", { checkout: this.checkout.id }), this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
