<template>
  <figure class="my-4">
    <img
      :src="selectedOption.image"
      :alt="selectedOption.id">
  </figure>
</template>

<script>
  export default {
    props: {
      selected: {
        type: String,
        default: null
      },

      options: {
        type: Array,
        default () {
          return []
        }
      }
    },

    computed: {
      selectedOption () {
        let index = this.options.findIndex(option => option.id === this.selected)
        if (index >= 0) {
          return this.options[index]
        }

        return {}
      }
    }
  }
</script>
