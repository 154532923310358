<template>
  <Layout>
    <hero-action-bar
      v-if="false"
      :data="data"
      :slug="slug" />
    <div
      v-if="data.show_countdown"
      class="relative w-full bg-yellow-mm-600 mb-8 text-center text-black z-20">
      <div class="container mx-auto">
        <div class="flex gap-4 md:gap-8 justify-center items-center pt-1 pb-3">
          <div class="text-sm md:text-base uppercase block font-bold leading-tight text-left md:text-center">
            {{ getStaticString('saleEndsAt') }}
          </div>
          <countdown
            :date="data.countdown_date"
            number-class="font-bold text-xl md:text-2xl leading-tight"
            class="text-xs" />
        </div>
      </div>
    </div>
    <div id="page-sales">
      <ScrollPrompt />
      <TopMenu :data="data" />
      <Intro :data="data" />
      <div id="slide-over">
        <div
          id="main-column"
          class="relative">
          <Logos
            v-if="data.logos_enable"
            :data="data" />
          <div
            class="fixed bottom-0 mb-2 px-4 mx-auto max-w-sm inset-x-0 "
            style="z-index:2000;">
            <IntersectionCta>
              <div class="flex justify-between">
                <a
                  href="#gallery-box"
                  class="bg-white px-4 py-3 rounded-md text-black text-center font-bold text-sm leading-tight inline-flex items-center">
                  <svg
                    class="inline-block mr-2"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="6.5"
                      y="4.5"
                      width="11"
                      height="9"
                      rx="1.5"
                      fill="white"
                      stroke="#949494" />
                    <rect
                      x="3.5"
                      y="2.5"
                      width="11"
                      height="13"
                      rx="1.5"
                      fill="white"
                      stroke="#636363" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="11"
                      height="17"
                      rx="1.5"
                      fill="white"
                      stroke="black" />
                  </svg>
                  <span>{{ getStaticString('imageGallery') }}</span>
                </a>
                <a
                  :href="data.checkout_url"
                  class="bg-yellow-mm-600 ml-2 px-8 flex-1 py-3 rounded-md font-bold leading-tight text-black text-center text-sm">
                  {{ getStaticString('buy') }}
                </a>
              </div>
            </IntersectionCta>
          </div>
          <div
            id="showBuyButton"
            class="absolute h-full top-32" />
          <div
            id="top-grid"
            class="relative">
            <IntroText :data="data" />
            <Shop :data="data" />
          </div>
          <Specifications :data="data" />
          <Gallery :data="data" />
          <Timepiece :data="data" />
          <DesignDetails :data="data" />
          <div id="visualization-grid">
            <Engraving :data="data" />
            <Internals :data="data" />
            <Straps :data="data" />
            <Turntable :data="data" />
          </div>
          <MoreInfo :data="data" />
          <InBox :data="data" />
          <Facebook
            :data="data"
            v-if="data.facebook_group_id" />
          <FooterV2
            v-if="data.footer_v2_enable"
            :data="data" />
          <Footer
            v-else
            :data="data" />
          <div
            class="text-white my-8 pb-8 text-center mx-auto max-w-3xl text-sm"
            v-html="asHtml(data.footer_disclaimer)" />
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import prismicMixins from "../../utils/prismic-mixins"
import Layout from "@/Shared/Layout.vue"
import MoreInfo from "./Boxes/MoreInfo.vue"
import TopMenu from "./Fragments/TopMenu.vue"
import ScrollPrompt from "./Fragments/ScrollPrompt.vue"
import IntersectionCta from "./Fragments/IntersectionCta.vue"
import HeroActionBar from "./Fragments/HeroActionBar.vue"
import Countdown from "@/Pages/Sales/Fragments/Countdown.vue"
import IntroText from "./Boxes/IntroText.vue"
import Intro from "./Boxes/Intro.vue"
import Logos from "./Boxes/Logos.vue"
import Footer from "./Boxes/Footer.vue"
import FooterV2 from "./Boxes/FooterV2.vue"
import Engraving from "./Boxes/Engraving.vue"
import Internals from "./Boxes/Internals.vue"
import Straps from "./Boxes/Straps.vue"
import Turntable from "./Boxes/Turntable.vue"
import Shop from "./Boxes/Shop.vue"
import Specifications from "./Boxes/Specifications.vue"
import Gallery from "./Boxes/Gallery.vue"
import Timepiece from "./Boxes/Timepiece.vue"
import DesignDetails from "./Boxes/DesignDetails.vue"
import Facebook from "./Boxes/Facebook.vue"
import InBox from "./Boxes/InBox.vue"
import { setLanguage, staticStrings } from "./staticStrings"

const fieldDefaults = {
  gallery_title: [{ spans: [], text: "Gallery", type: "heading1" }],
  specifications_title: [{ spans: [], text: "Specifications", type: "heading1" }],
  shop_terms_line_a: "Pay in 45 days",
  shop_terms_line_b: "Split payment over 2 months. First invoice due in 45 days.",
  shop_terms_line_c: "Split payment over 6 months. First invoice due in 45 days.",
  engraving_caseback_kind: "closed-caseback",
  titles_alignment: "top",
}

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },

    slug: {
      type: String,
      default: null
    }
  },

  created: function () {
    // Apply default values to optional Prismic fields
    for (const [field, value] of Object.entries(fieldDefaults)) {
      if (this.data[field] === undefined || this.data[field] === null || (this.data[field] && this.data[field].length === 0)) {
        this.data[field] = value
      }
    }
    setLanguage(this.data.static_string_language)
  },

  mounted () {
    try {
      fbq("track", "ViewContent", {
        content_name: this.data.title,
        content_category: "Jewelry & Watches//Watches",
        content_type: "product"
      })
    } catch (e) {}
  },

  components: {
    Layout,
    HeroActionBar,
    Footer,
    FooterV2,
    MoreInfo,
    TopMenu,
    InBox,
    IntroText,
    Intro,
    Logos,
    Engraving,
    Internals,
    Straps,
    Turntable,
    Shop,
    Specifications,
    Gallery,
    Timepiece,
    DesignDetails,
    Facebook,
    IntersectionCta,
    ScrollPrompt,
    Countdown
  },

  mixins: [prismicMixins, staticStrings]
}
</script>
