<template>
  <div
    class="accordion"
    :class="{ 'accordion--inverted': inverted }">
    <a
      href="#"
      class="py-4 border-b border-gray-mm-500 flex items-center"
      :class="{ 'sm:text-2xl': headerSize === 'large' }"
      @click.prevent="open = !open">
      <slot name="header" />
      <div
        class="arrow ml-auto"
        :class="{ 'is-open': open }" />
    </a>
    <div
      class="block w-full accordion-content"
      v-if="open">
      <div class="py-4">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inverted: {
      type: Boolean,
      default: false
    },

    headerSize: {
      type: String,
      default: "large"
    }
  },

  data () {
    return {
      open: false
    }
  }
}
</script>
