<template>
  <div class="py-3 px-4 bg-black z-10 relative">
    <div class="sm:max-w-xl mx-auto flex items-center justify-center w-full">
      <div
        v-if="showHeroSignup"
        class="flex items-center w-full">
        <button
          class="w-12 h-12 flex-shrink-0 mr-2 flex items-center justify-center"
          @click.prevent="showHeroSignup = false">
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 9L2 5L6 1"
              stroke="#FFD300"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>

        <form
          v-if="!signupSuccess"
          @submit.prevent="submit"
          class="w-full">
          <div class="flex items-center w-full">
            <input-field
              key="email-signup"
              :placeholder="placeholder"
              class="flex-grow mr-1 sm:mr-2"
              v-model="formData.email" />
            <default-button
              :loading="submitting"
              class="rounded-sm"
              tertiary>
              {{ buttonText }}
            </default-button>
          </div>

          <div
            v-if="signupStatus"
            class="bg-red-700 text-white px-3 py-2 rounded-sm relative text-sm my-2"
            role="alert">
            {{ signupStatus }}
          </div>

          <validation-errors />
        </form>
        <div
          v-else
          class="w-full text-center">
          {{ locale == 'nb' ? 'Takk!' : 'Thank you!' }}
        </div>
      </div>
      <div
        v-else-if="showInviteLink"
        class="flex items-center w-full">
        <button
          class="w-12 h-12 flex-shrink-0 mr-2 flex items-center justify-center"
          @click.prevent="showInviteLink = false">
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 9L2 5L6 1"
              stroke="#FFD300"
              stroke-width="2"
              stroke-linecap="round" />
          </svg>
        </button>

        <input-field
          key="share-url"
          class="flex-grow mr-1 sm:mr-2"
          :value="shareUrl"
          readonly />
        <default-button
          v-if="shareUrlCopied"
          @click.prevent.native="copyLink"
          class="rounded-sm"
          tertiary>
          {{ locale == 'nb' ? 'Kopiert' : 'Copied!' }}
        </default-button>
        <default-button
          v-else
          @click.prevent.native="copyLink"
          class="rounded-sm"
          tertiary>
          {{ locale == 'nb' ? 'Kopier' : 'Copy Link' }}
        </default-button>
      </div>
      <div
        v-else
        class="flex items-center justify-center gap-2">
        <span class="font-bold uppercase text-sm inline-block truncate font-mussels pt-4 pb-3 mr-2 sm:mr-4">{{ locale == 'nb' ? 'Hold deg oppdatert' : 'Stay up to date' }}</span>
        <button
          @click.prevent="showHeroSignup = !showHeroSignup"
          class="default-button bg-yellow-mm-600 px-3 py-3 rounded-sm font-semibold text-black text-center text-sm inline-block border border-transparent whitespace-no-wrap truncate">
          {{ locale == 'nb' ? 'Abonner' : 'Get notified' }}
        </button>
        <button
          @click.prevent="fetchAndShowInviteLink"
          :disabled="fetchingInviteLink"
          :class="{ 'loading': fetchingInviteLink }"
          class="default-button bg-white px-3 py-3 rounded-sm font-semibold text-black text-center text-sm inline-block border border-transparent whitespace-no-wrap truncate">
          {{ locale == 'nb' ? 'Inviter' : 'Invite others' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    },

    slug: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      showHeroSignup: false,
      showInviteLink: false,
      fetchingInviteLink: false,
      shareUrl: null,
      shareUrlCopied: false,
      formData: {
        email: null,
        slug: this.slug,
        privacy_terms: true
      },
      submitting: false,
      signupSuccess: false,
      signupStatus: null,
    }
  },

  methods: {
    fetchAndShowInviteLink () {
      this.fetchingInviteLink = true
      axios.post(`/collections/${this.slug}/share`, { slug: this.slug }, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then((response) => {
          this.shareUrl = response.data.data.url
          this.showInviteLink = true
          this.fetchingInviteLink = false
        })
        .catch(e => {
          this.fetchingInviteLink = false
        })
    },

    submit () {
      this.submitting = true
      this.signupStatus = null
      axios.post(`/collections/${this.slug}/signup`, this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then((response) => {
          this.signupSuccess = response.data.success
          this.submitting = false
        })
        .catch(e => {
          this.submitting = false
          this.signupStatus = e.response.data.message
        })
    },

    copyLink () {
      navigator.clipboard.writeText(this.shareUrl)

      this.shareUrlCopied = true
      setTimeout(() => {
        this.shareUrlCopied = false
      }, 2000)
    }
  },

  computed: {
    signupText () {
      switch (this.locale) {
        case "nb":
          return "Fyll inn epost for å fortsette og lagre handlekurven din til senere."
        default:
          return "Enter your email to customize your watch"
      }
    },

    placeholder () {
      switch (this.locale) {
        case "nb":
          return "Din E-post"
        default:
          return "Your Email"
      }
    },

    buttonText () {
      switch (this.locale) {
        case "nb":
          return "Send"
        default:
          return "Submit"
      }
    },

    locale () {
      return window.locale
    }
  }
}
</script>
