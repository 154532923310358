<template>
  <layout :cart-is-open-by-default="cartIsOpenByDefault">
    <div class="container mx-auto">
      <div class="mx-auto md:max-w-5xl xl:max-w-6xl">
        <header class="mb-8 md:mb-12 md:mt-8 font-medium">
          <h1 class="text-3xl sm:text-5xl md:text-6xl leading-tight mb-1 md:mb-2">
            Shop
          </h1>
          <div class="text-gray-mm-300 leading-tight md:text-lg">
            <p>Shop accessories and parts for your Micromilspec watch <br> or get some of our new merch.</p>
          </div>
        </header>

        <nav class="flex items-center justify-start md:text-xl">
          <a
            v-for="(collection,index) in collections"
            :key="index"
            :href="`#${collection.slug}`"
            class="mr-2 underline">{{ collection.title }}</a>
        </nav>

        <div
          v-for="(collection,index) in collections"
          :key="index"
          class="my-8 md:my-20"
          :id="collection.slug">
          <div class="font-medium">
            <h2 class="sm:text-3xl md:text-4xl leading-tight mb-1 md:mb-2">
              {{ collection.title }}
            </h2>
            <div class="text-gray-mm-300 leading-tight">
              <p>{{ collection.description }}</p>
            </div>
          </div>

          <div
            v-if="collection.products.length"
            class="my-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <product-card
              v-for="product in collection.products"
              :product="product"
              :key="product.id" />
          </div>
          <div
            v-else
            class="my-8 grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <div class="pb-full bg-gray-mm-600" />
            <div class="pb-full bg-gray-mm-600 hidden sm:block" />
            <div class="pb-full bg-gray-mm-600 hidden lg:block" />
            <div class="pb-full bg-gray-mm-600 hidden lg:block" />
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Pages/Shop/Layout.vue"
import ProductCard from "@/Pages/Shop/Partials/ProductCard.vue"

export default {
  props: {
    collections: {
      type: Array,
      default () {
        return []
      }
    },

    products: {
      type: Array,
      default () {
        return []
      }
    },

    cartIsOpenByDefault: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Layout,
    ProductCard
  }
}
</script>
