<template>
  <div
    class="turntable"
    v-if="manifest !== null">
    <RangeSequence
      v-if="mode === 'sequence'"
      :manifest="manifest" />
    <video
      v-if="mode === 'video'"
      autoplay
      loop
      muted
      playsinline>
      <source
        :src="manifest.video"
        type="video/mp4">
    </video>
  </div>
</template>

<script>
import RangeSequence from "./RangeSequence.vue"

export default {
  props: {
    manifest: {
      type: Object,
    },
    mode: {
      type: String,
    }
  },
  components: {
    RangeSequence
  },
}

</script>
