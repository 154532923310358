<template>
  <div>
    <span
      v-if="type === 'warranty'"
      class="block w-6 h-6"><img
        class="mx-auto"
        :src="asset('images/icons/warranty.svg')"
        alt="Warranty"></span>
    <span
      v-if="type === 'self-winding'"
      class="block w-6 h-6"><img
        class="mx-auto"
        :src="asset('images/icons/self-winding.svg')"
        alt="Self-winding"></span>
    <span
      v-if="type === 'water-resistance'"
      class="block w-6 h-6"><img
        class="mx-auto"
        :src="asset('images/icons/water-resistance.svg')"
        alt="Water resistance"></span>
    <span
      v-if="type === 'glass'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/glass.svg')"
        alt="Sapphire glass"></span>
    <span
      v-if="type === 'power-reserve'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/power-reserve.svg')"
        alt="Power reserve"></span>
    <span
      v-if="type === 'certificate'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/certificate.svg')"
        alt="Certificate of authenticity"></span>
    <span
      v-if="type === 'luminous'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/luminous.svg')"
        alt="Luminous"></span>
    <span
      v-if="type === 'case'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/case.svg')"
        alt="Stainless steel case"></span>
    <span
      v-if="type === 'watch-box'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/watch-box.svg')"
        alt="Display box"></span>
    <span
      v-if="type === 'surprise-object'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/surprise-object.svg')"
        alt="Surprise object"></span>
    <span
      v-if="type === 'swiss-made'"
      class="block w-6 h-6 text-center"><img
        class="mx-auto"
        :src="asset('images/icons/swiss-made.svg')"
        alt="Swiss made"></span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: null
    }
  }
}
</script>
