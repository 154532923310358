<template>
  <layout>
    <div class="bg-gray-mm-500 text-white py-1">
      <div class="container mx-auto">
        <div class="top-bar mx-auto">
          <div class="flex items-center">
            <div class="flex-shrink-0 w-6">
              <svg
                class="fill-current text-white"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.575 9.4446C20.6336 4.01384 15.9856 0 10.3705 0H9.4446V4.42583C4.01384 5.36656 0 10.0144 0 15.6295V16.5554H4.40684C5.34757 21.9862 9.99538 26 15.6114 26H16.5373V21.5753C21.9788 20.6351 26 15.9871 26 10.3705V9.4446H21.575V9.4446ZM16.5373 19.7037V17.2037H14.6855V24.1048C10.504 23.7087 7.12821 20.644 6.27858 16.5554H8.79627V14.7036H1.89525C2.2913 10.5229 5.35605 7.1472 9.4446 6.29757V8.79627H11.2964V1.89525C15.4771 2.2913 18.8537 5.35605 19.7033 9.4446H17.1847V11.2964H24.1048C23.7078 15.4778 20.6357 18.8547 16.5373 19.7037Z"
                  fill="white" />
              </svg>
            </div>
            <div class="ml-auto pl-8 text-sm py-3">
              <a :href="formDefaults.sales_site_url">{{ $t('checkout.header.link_text') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <checkout-flow-signup>
      <template v-slot:default>
        <component
          :is="checkoutComponent"
          v-bind="$props" />
      </template>
    </checkout-flow-signup>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import CheckoutSection from "@/Shared/CheckoutSection.vue"
import CheckoutTotal from "@/Shared/CheckoutTotal.vue"
import RadioList from "@/Shared/RadioList.vue"
import CheckboxList from "@/Shared/CheckboxList.vue"
import Terms from "@/Shared/Terms.vue"
import InCart from "@/Shared/InCart.vue"
import CheckoutEngraving from "@/Shared/CheckoutEngraving.vue"
import ZoomImage from "@/Shared/ZoomImage.vue"
import CheckoutFlowSignup from "@/Shared/CheckoutFlowSignup.vue"

export default {
  components: {
    Layout,
    CheckoutSection,
    CheckoutTotal,
    RadioList,
    CheckboxList,
    Terms,
    InCart,
    CheckoutEngraving,
    ZoomImage,
    CheckoutFlowSignup,
    "checkout-default": () => import(/* webpackChunkName: "checkout-default" */ "@/Shared/Checkout/Default.vue"),
    "checkout-awg": () => import(/* webpackChunkName: "checkout-awg" */ "@/Shared/Checkout/Awg.vue"),
    "checkout-oscar": () => import(/* webpackChunkName: "checkout-oscar" */ "@/Shared/Checkout/Oscar.vue"),
    "checkout-shooter": () => import(/* webpackChunkName: "checkout-shooter" */ "@/Shared/Checkout/Shooter.vue"),
    "checkout-spectrum": () => import(/* webpackChunkName: "checkout-spectrum" */ "@/Shared/Checkout/Spectrum.vue"),
    "checkout-beredskap": () => import(/* webpackChunkName: "checkout-beredskap" */ "@/Shared/Checkout/Beredskap.vue"),
    "checkout-nansen": () => import(/* webpackChunkName: "checkout-nansen" */ "@/Shared/Checkout/Nansen.vue"),
    "checkout-narvik": () => import(/* webpackChunkName: "checkout-narvik" */ "@/Shared/Checkout/Narvik.vue"),
    "checkout-rex": () => import(/* webpackChunkName: "checkout-narvik" */ "@/Shared/Checkout/Rex.vue"),
    "checkout-tmbn": () => import(/* webpackChunkName: "checkout-tmbn" */ "@/Shared/Checkout/Tmbn.vue"),
    "checkout-voyageur": () => import(/* webpackChunkName: "checkout-voyageur" */ "@/Shared/Checkout/Voyageur.vue"),
    "checkout-flightdeck": () => import(/* webpackChunkName: "checkout-flightdeck" */ "@/Shared/Checkout/Flightdeck.vue"),
    "checkout-nrf": () => import(/* webpackChunkName: "checkout-nrf" */ "@/Shared/Checkout/Nrf.vue"),
    "checkout-norisen": () => import(/* webpackChunkName: "checkout-norisen" */ "@/Shared/Checkout/Norisen.vue"),
    "checkout-luna-dude": () => import(/* webpackChunkName: "checkout-luna-dude" */ "@/Shared/Checkout/LunaDude.vue"),
    "checkout-fallskjerm-se": () => import(/* webpackChunkName: "checkout-fallskjerm-se" */ "@/Shared/Checkout/FallskjermSe.vue"),
    "checkout-default-english": () => import(/* webpackChunkName: "checkout-default-english" */ "@/Shared/Checkout/DefaultEnglish.vue"),
    "checkout-default-swedish": () => import(/* webpackChunkName: "checkout-default-swedish" */ "@/Shared/Checkout/DefaultSwedish.vue"),
    "checkout-sensitive-english": () => import(/* webpackChunkName: "checkout-sensitive-english" */ "@/Shared/Checkout/SensitiveEnglish.vue"),
    "checkout-norwegian": () => import(/* webpackChunkName: "checkout-norwegian" */ "@/Shared/Checkout/Norwegian.vue"),
    "checkout-awacs": () => import(/* webpackChunkName: "checkout-awacs" */ "@/Shared/Checkout/Awacs.vue"),
    "checkout-investigator": () => import(/* webpackChunkName: "checkout-awacs" */ "@/Shared/Checkout/Investigator.vue"),
    "checkout-offshore": () => import(/* webpackChunkName: "checkout-offshore" */ "@/Shared/Checkout/Offshore.vue"),
    "checkout-heimevernet": () => import(/* webpackChunkName: "checkout-heimevernet" */ "@/Shared/Checkout/Heimevernet.vue"),
    "checkout-australian": () => import(/* webpackChunkName: "checkout-australian" */ "@/Shared/Checkout/Australian.vue"),
    "checkout-brannvakt": () => import(/* webpackChunkName: "checkout-brannvakt" */ "@/Shared/Checkout/Brannvakt.vue"),
    "checkout-sanity": () => import(/* webpackChunkName: "checkout-sanity" */ "@/Shared/Checkout/SanityEnglish.vue"),
    "checkout-sanity-v2": () => import(/* webpackChunkName: "checkout-sanity" */ "@/Shared/Checkout/SanityEnglishV2.vue"),
  },

  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },

    addons: {
      type: Array,
      default () {
        return []
      }
    },

    paymentMethods: {
      type: Array,
      default () {
        return []
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    },

    formDefaults: {
      type: Object,
      default () {
        return {}
      }
    },

    siteId: {
      type: String,
      default: null
    },

    siteSlug: {
      type: String,
      default: null
    },

    salesTerms: {
      type: String,
      default: null
    },

    isSensitive: {
      type: Boolean,
      default: false
    },

    sanityData: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  created () {
    try {
      setTimeout(() => {
        window.fbq("track", "InitiateCheckout")

        window.gtag("event", "begin_checkout")
      }, 3000)
    } catch (e) {}
  },

  data () {
    return {
      checkoutComponent: this.formDefaults.checkout.component || "checkout-default"
    }
  }
}
</script>

<style>
  .top-bar {
    max-width: 1100px;
  }
</style>
