<template>
  <div class="responsive-image-slider">
    <Slider
      :data="slides"
      :options="{pagination: {el: `.pagination-${id}`}, navigation: {nextEl: `.swiper-arrow-next-${id}`, prevEl: `.swiper-arrow-prev-${id}`}}">
      <template v-slot:default="{ slide }">
        <ResponsiveImage :image="slide.image" />
      </template>
    </Slider>
    <div class="label">
      {{ label }}
    </div>
    <div class="arrow-nav">
      <div :class="`swiper-arrow-prev swiper-arrow-prev-${id}`">
        <img
          :src="asset('images/sales/slider-left.svg')">
      </div>
      <div
        :class="`swiper-arrow-next swiper-arrow-next-${id}`">
        <img
          :src="asset('images/sales/slider-right.svg')">
      </div>
    </div>
    <div :class="`pagination-${id}`" />
  </div>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import {staticStrings} from "../staticStrings"
import Slider from "../Slider"
import ResponsiveImage from "../Fragments/ResponsiveImage.vue"

export default {
  mixins: [prismicMixins, staticStrings],

  props: {
    id: {
      type: String,
      default () {
        return ""
      }
    },
    label: {
      type: String,
      default () {
        return ""
      }
    },
    slides: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: {
    Slider,
    ResponsiveImage
  },
}
</script>
