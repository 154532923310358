<template>
  <div class="bg-gray-mm-500 p-6">
    <figure class="px-4 mb-4">
      <img
        v-if="model && model.id === 'left'"
        :src="asset('images/panser/bezel-left.png')"
        alt="Bezel Left">
      <img
        v-else
        :src="asset('images/panser/bezel-right.png')"
        alt="Bezel Right">
    </figure>

    <p class="text-xl block text-center mb-2 font-semibold">
      Choose direction of the Mink
    </p>

    <radio-list
      :options="[{ title: 'Left', id: 'left' }, { title: 'Right', id: 'right' }]"
      v-model="model"
      class="flex items-center justify-center">
      <template slot-scope="{ item }">
        <div class="py-2 flex items-center text-sm sm:text-base px-6">
          <span
            class="radio-icon mr-3"
            :class="{ 'radio-icon--checked': model && model.id === item.id }" />
          <span>{{ item.title }}</span>
        </div>
      </template>
    </radio-list>
  </div>
</template>

<script>
import RadioList from "@/Shared/RadioList.vue"

export default {
  props: {
    value: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    }
  },

  components: {
    RadioList
  }
}
</script>
