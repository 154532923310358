<template>
  <div class="relative">
    <figure class="aspect-ratio-16x9 hidden sm:block">
      <img
        :src="asset('images/tmbn/lume-night-desktop.jpg')"
        class="absolute inset-0"
        alt="TMBN Night">
      <img
        :src="asset('images/tmbn/lume-day-desktop.jpg')"
        class="absolute inset-0"
        :style="{ opacity: computedWidth }"
        alt="TMBN Day">
    </figure>
    <figure class="aspect-ratio-1x1 block sm:hidden">
      <img
        :src="asset('images/tmbn/lume-night-mob.jpg')"
        class="absolute inset-0"
        alt="TMBN Night">
      <img
        :src="asset('images/tmbn/lume-day-mob.jpg')"
        class="absolute inset-0"
        :style="{ opacity: computedWidth }"
        alt="TMBN Day">
    </figure>

    <div class="absolute w-full">
      <div class="flex items-center justify-center mx-auto -mt-3">
        <div class="w-16">
          <svg
            class="mx-auto"
            width="19"
            height="28"
            viewBox="0 0 19 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.5714 0.76334C13.0841 2.65808 9.14278 7.86877 9.14278 14C9.14278 20.1312 13.0841 25.3419 18.5714 27.2367C17.1388 27.7313 15.6008 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C15.6008 0 17.1388 0.268656 18.5714 0.76334Z"
              fill="white" />
          </svg>
        </div>
        <div class="range-wrapper">
          <input
            type="range"
            min="1"
            max="100"
            v-model="sliderValue"
            @input="changeWidth"
            class="slider">
        </div>
        <div class="w-16">
          <svg
            class="mx-auto"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="14"
              cy="14"
              r="6"
              fill="white" />
            <rect
              x="13.3335"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              fill="white" />
            <rect
              x="13.3335"
              y="23.3334"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              fill="white" />
            <rect
              y="14.6666"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              transform="rotate(-90 0 14.6666)"
              fill="white" />
            <rect
              x="23.3335"
              y="14.6665"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              transform="rotate(-90 23.3335 14.6665)"
              fill="white" />
            <rect
              x="3.62891"
              y="4.5719"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              transform="rotate(-45 3.62891 4.5719)"
              fill="white" />
            <rect
              x="20.1279"
              y="21.071"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              transform="rotate(-45 20.1279 21.071)"
              fill="white" />
            <rect
              x="4.57178"
              y="24.3707"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              transform="rotate(-135 4.57178 24.3707)"
              fill="white" />
            <rect
              x="21.0708"
              y="7.87146"
              width="1.33333"
              height="4.66667"
              rx="0.666667"
              transform="rotate(-135 21.0708 7.87146)"
              fill="white" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sliderValue: 1,
      width: 0
    }
  },

  methods: {
    changeWidth () {
      this.width = this.sliderValue
    }
  },

  computed: {
    computedWidth() {
      return this.width / 100
    }
  }
}
</script>
