<script>
export default {
  props: {
    cartIsOpen: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      items: [],
      errors: [],
      submitting: false,
      open: this.cartIsOpen,
    }
  },

  methods: {
    add (item, variantId) {
      this.submitting = true
      this.$inertia.post("/cart", { ...item, id: variantId })
        .then(() => {
          if (!Object.keys(this.$page.props.errors).length) {
            this.open = true
          }
          this.submitting = false
        })
    },

    remove (item) {
      this.submitting = true
      let id = btoa(item.id)
      this.$inertia.delete(`/cart/${id}`)
        .then(() => {
          this.submitting = false
        })
    },

    increment (item) {
      this.submitting = true

      this.$inertia.post("/cart/quantity", { id: item.id, quantity: item.quantity + 1 })
        .then(() => {
          this.submitting = false
        })
    },

    decrement (item) {
      this.submitting = true
      this.$inertia.post("/cart/quantity", { id: item.id, quantity: item.quantity - 1 })
        .then(() => {
          this.submitting = false
        })
    },

    openCart () {
      this.open = true
    },

    closeCart () {
      this.open = false
    }
  },

  render () {
    return this.$scopedSlots.default({
      add: this.add,
      remove: this.remove,
      increment: this.increment,
      decrement: this.decrement,
      open: this.openCart,
      close: this.closeCart,
      cartIsOpen: this.open,
      submitting: this.submitting,
      errors: this.errors
    })
  },

  computed: {
    count () {
      return this.items.length
    }
  }
}
</script>
