<template>
  <footer
    id="footer-v2-box"
    class="box bg-yellow-mm-600 text-black rounded">
    <div class="flex flex-col md:flex-row justify-between">
      <div class="md:w-3/5 lg:px-14 px-4 py-12 md:pt-20 md:pb-24">
        <div class="text-xl md:text-2xl leading-tight font-bold mb-4 md:mb-10">
          {{ data.footer_v2_title }}
        </div>
        <div class="max-w-md md:text-2xl my-8 md:my-12">
          {{ asText(data.footer_v2_content) }}
        </div>
        <div>
          <div class="md:text-2xl my-6 hidden md:block">
            {{ data.footer_v2_list_title }}
          </div>
          <ul class="grid grid-cols-2 gap-x-4 gap-y-1 text-sm md:w-10/12">
            <li
              v-for="i in data.footer_v2_list"
              :key="i.item"
              class="mb-1">
              {{ i.item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="rounded-xl relative md:w-2/5">
        <div class="px-8 py-12 mx-auto text-center mb-64 lg:mb-0 sm:pb-64 lg:pb-0 relative z-10">
          <figure class="mb-6 lg:mb-20 z-20 w-40 h-40 mx-auto">
            <svg
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_6536_81143)">
                <path
                  d="M7.28115 57.9058C5.85 58.5757 4.28949 58.1236 3.69297 56.8498C3.18214 55.755 3.64354 54.3385 4.70598 53.7395L5.21476 54.8285C4.43986 55.2328 4.28084 55.7187 4.61535 56.4348C5.00383 57.2689 5.52414 57.4281 6.47206 56.9842L7.09206 56.6938C8.04122 56.2492 8.25215 55.7476 7.86202 54.9151C7.52792 54.1982 7.03645 54.0076 6.23889 54.3475L5.73094 53.2594C6.87084 52.8262 8.25956 53.3773 8.77039 54.4721C9.36568 55.7467 8.71231 57.2367 7.28115 57.9058Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M4.55874 49.1676L1.2235 49.8293L1.00681 48.7328L4.63207 48.0134C5.22077 47.8971 5.39502 47.5679 5.25784 46.8749C5.12107 46.1819 4.84464 45.9567 4.24812 46.0755L0.623689 46.7949L0.405762 45.7001L3.741 45.0369C4.99954 44.7869 5.98619 45.4362 6.23295 46.6802C6.48095 47.9334 5.82429 48.916 4.55874 49.1676Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M3.59072 40.7657L3.63686 39.6025C4.3891 39.6239 4.64204 39.383 4.67294 38.5778C4.6993 37.9236 4.50651 37.6043 4.0966 37.5878L3.90669 37.5804C3.6109 37.5689 3.49061 37.7091 3.38762 38.2223L3.23725 38.9548C3.03045 39.9366 2.40592 40.5438 1.63184 40.5124C0.697512 40.4761 -0.0403104 39.5406 0.0017097 38.4607C0.0470254 37.328 0.840463 36.4914 1.89138 36.4353L1.84482 37.6052C1.18486 37.5788 0.962815 37.8139 0.93439 38.5209C0.912144 39.0984 1.09094 39.3789 1.47818 39.3946L1.65285 39.4012C1.95688 39.4135 2.10683 39.2518 2.20282 38.761L2.36966 37.9987C2.60201 36.9278 3.11696 36.4073 3.92935 36.4386C4.89375 36.4774 5.65135 37.4814 5.60603 38.6372C5.55742 39.8764 4.70919 40.7566 3.59072 40.7657Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M2.19883 30.6586L1.82518 32.0949L0.839355 31.8367L1.87173 27.8824L2.85797 28.1407L2.48226 29.577L6.77613 30.7006L6.4927 31.783L2.19883 30.6586Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M5.88403 24.8657C4.47925 24.1257 3.92392 22.5706 4.59954 21.2861C5.27392 20.0007 6.86574 19.5849 8.2697 20.3249C9.67325 21.0641 10.2319 22.6127 9.55708 23.8972C8.88229 25.1825 7.288 25.6049 5.88403 24.8657ZM8.02788 21.5195L7.42271 21.2019C6.58313 20.7589 5.91699 20.9495 5.49267 21.7563C5.06835 22.564 5.28916 23.2215 6.12874 23.6629L6.7335 23.9822C7.5669 24.4202 8.24004 24.2338 8.66395 23.4278C9.08415 22.6267 8.86045 21.9593 8.02788 21.5195Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M8.8877 14.6949L10.4692 13.0301L14.1423 15.6338L14.3099 15.4572L11.5753 11.866L13.1569 10.2003L17.1121 13.9656L16.3833 14.732L13.1556 11.6589L12.9035 11.9237L15.5977 15.5603L14.2885 16.9389L10.5726 14.3781L10.3221 14.6429L13.5503 17.7169L12.8429 18.4619L8.8877 14.6949Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M27.0587 4.08225C27.4723 5.0541 26.9758 6.15548 25.9422 6.59602L25.1752 6.92355L25.8401 8.48857L24.8135 8.92582L22.6812 3.90735L24.4542 3.15165C25.5093 2.70285 26.6467 3.1104 27.0587 4.08225ZM25.9369 4.3116C25.7643 3.90653 25.4326 3.82403 24.8608 4.06823L24.0999 4.39245L24.783 6.00038L25.5649 5.66708C26.1235 5.42948 26.2875 5.13743 26.112 4.7241L25.9369 4.3116Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M34.9992 5.79804L33.5718 4.0705L32.913 4.186L33.2484 6.10412L32.1472 6.29634L31.2075 0.915701L33.1536 0.574976C34.2766 0.378627 35.2612 0.978401 35.4318 1.96097C35.55 2.635 35.2064 3.27437 34.5901 3.67615L36.2792 5.57282L34.9992 5.79804ZM32.7458 3.2257L33.6278 3.07142C34.1749 2.97655 34.401 2.72822 34.3281 2.3083L34.277 2.01542C34.2041 1.5955 33.8989 1.44123 33.3604 1.53445L32.4768 1.68955L32.7458 3.2257Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M39.9045 2.63021C40.01 1.04704 41.2034 -0.0922829 42.6494 0.00589191C44.0954 0.102417 45.1204 1.38942 45.0141 2.97424C44.9078 4.55824 43.7214 5.69673 42.2754 5.60021C40.8294 5.50286 39.7982 4.21504 39.9045 2.63021ZM43.8252 3.23741L43.8705 2.55514C43.9347 1.60639 43.4907 1.07427 42.5819 1.01404C41.6739 0.952166 41.1639 1.42077 41.1005 2.36869L41.0543 3.05096C40.9925 3.99146 41.435 4.53101 42.3429 4.59124C43.2443 4.65229 43.7625 4.17626 43.8252 3.23741Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M50.1913 1.29114L53.4499 2.28856L53.159 3.24061L50.9682 2.56989L50.5892 3.81233L52.5633 4.41706L52.2717 5.36911L50.2976 4.76438L49.6656 6.83596L48.5986 6.50926L50.1913 1.29114Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M58.0654 4.27429L61.053 5.94986L60.5628 6.82601L58.5425 5.69247L57.9863 6.68741L59.8072 7.70959L59.3211 8.57914L57.4994 7.55614L56.8584 8.70371L58.9248 9.86449L58.4346 10.739L55.4009 9.03701L58.0654 4.27429Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M62.7019 11.4834L63.5645 12.2622C63.0669 12.8257 63.09 13.1747 63.6873 13.7142C64.1726 14.1515 64.5409 14.2076 64.8153 13.9031L64.9421 13.7621C65.1407 13.5418 65.1102 13.3603 64.7831 12.9519L64.3176 12.3686C63.6955 11.5832 63.6296 10.7145 64.1479 10.1387C64.7732 9.44401 65.9622 9.47371 66.7622 10.1972C67.6026 10.9546 67.7369 12.0997 67.1091 12.9453L66.2406 12.1607C66.6831 11.6707 66.6452 11.3489 66.1212 10.8762C65.6919 10.4885 65.3615 10.4464 65.1028 10.7351L64.9866 10.8655C64.7823 11.0907 64.8103 11.3102 65.1259 11.6971L65.6029 12.3125C66.2769 13.1771 66.3469 13.9048 65.8031 14.5087C65.1572 15.2248 63.9023 15.1662 63.0455 14.3924C62.1276 13.5632 61.9941 12.348 62.7019 11.4834Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M68.5382 17.3598L69.2031 18.3143C68.5909 18.7508 68.5349 19.0956 68.9955 19.7556C69.3695 20.2927 69.7156 20.4313 70.0517 20.1953L70.2066 20.0873C70.4497 19.9173 70.4612 19.7342 70.2338 19.2614L69.9125 18.5874C69.4849 17.6807 69.6167 16.8203 70.2511 16.3772C71.0166 15.8418 72.1692 16.1405 72.7855 17.0249C73.4339 17.9538 73.3054 19.0989 72.5029 19.7804L71.8339 18.8201C72.376 18.4422 72.4106 18.1205 72.0069 17.5413C71.6765 17.0678 71.3643 16.9514 71.047 17.1734L70.9037 17.274C70.6549 17.4473 70.631 17.6684 70.8518 18.1163L71.1789 18.8242C71.6403 19.8175 71.543 20.5435 70.8773 21.0096C70.0863 21.5615 68.8768 21.2208 68.2169 20.2729C67.5091 19.2565 67.6533 18.0429 68.5382 17.3598Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M76.2529 23.1973L76.7085 24.2178L71.7312 26.4469L71.2764 25.4264L76.2529 23.1973Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M75.8015 29.4887C77.3505 29.148 78.7759 29.9788 79.0865 31.3961C79.398 32.8143 78.4496 34.159 76.9007 34.4997C75.3509 34.8405 73.9271 34.0179 73.6165 32.5998C73.3042 31.1816 74.2517 29.8303 75.8015 29.4887ZM76.3148 33.4297L76.983 33.2837C77.91 33.0791 78.2964 32.5041 78.1003 31.6131C77.905 30.7229 77.3143 30.3624 76.3873 30.567L75.72 30.713C74.8005 30.9151 74.4066 31.4926 74.6019 32.3828C74.7964 33.2655 75.3962 33.6318 76.3148 33.4297Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M79.9999 39.1083L79.9142 41.3556L75.4304 42.3043L75.4188 42.6005L79.8614 42.768L79.8227 43.7926L74.3799 43.5872L74.4606 41.4315L78.9469 40.4596L78.9609 40.0958L74.5175 39.9275L74.5562 38.9028L79.9999 39.1083Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M74.0612 50.9618L74.6033 49.1081L73.463 48.4332L73.7844 47.3302L78.5887 50.339L78.0655 52.1276L72.4043 52.0541L72.7372 50.9148L74.0612 50.9618ZM75.0499 50.9899L77.3289 51.0724L77.4154 50.7803L75.4503 49.6179L75.0499 50.9899Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M75.5946 58.1922L75.0517 59.1681L71.1809 57.0058L70.1567 58.8431L69.2686 58.3473L70.8365 55.5332L75.5946 58.1922Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M67.2029 69.4805L66.332 70.1554L63.2184 66.9346L62.9185 67.1664L65.1793 71.0489L63.6781 72.2121L60.4928 69.0458L60.1921 69.2792L62.5246 73.1048L61.6661 73.7697L58.8887 69.0202L60.5942 67.6985L63.7251 70.9061L63.9179 70.7576L61.615 66.9082L63.3197 65.5865L67.2029 69.4805Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M52.6654 73.5025L54.45 72.7699L54.2663 71.4573L55.3267 71.0217L55.9751 76.6548L54.2531 77.3618L50.7803 72.8871L51.8761 72.4366L52.6654 73.5025ZM53.2595 74.2928L54.6181 76.1276L54.8991 76.0121L54.5802 73.7508L53.2595 74.2928Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M47.1585 78.3098L48.6177 78.0573L48.7915 79.0614L44.7741 79.7577L44.6011 78.7536L46.0594 78.5012L45.3014 74.1254L46.4022 73.9348L47.1585 78.3098Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M40.3576 77.3333C40.2715 78.9107 39.136 80.0739 37.7341 79.9964C36.5291 79.9304 35.5009 78.8537 35.4771 77.6344L36.6741 77.7004C36.6642 78.5757 37.0166 78.9453 37.8046 78.9882C38.7217 79.0385 39.1076 78.6566 39.1652 77.6088L39.2028 76.9265C39.2597 75.8804 38.9185 75.4572 38.001 75.4061C37.213 75.3632 36.8135 75.7072 36.7364 76.5701L35.5392 76.5041C35.6969 75.2955 36.8365 74.3303 38.0415 74.3963C39.4434 74.4738 40.4449 75.7534 40.3576 77.3333Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M30.3584 78.8542L29.291 78.5267L29.9164 76.4782L27.9213 75.8669L27.2959 77.917L26.2285 77.5895L27.8212 72.3722L28.8885 72.6981L28.2121 74.9157L30.2072 75.5262L30.8837 73.3102L31.9511 73.6361L30.3584 78.8542Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M21.3568 75.4025L18.3965 73.679L18.9003 72.8128L20.9012 73.9777L21.4738 72.9918L19.6694 71.9416L20.1704 71.0811L21.9744 72.1313L22.6347 70.9945L20.5869 69.8032L21.0907 68.9353L24.0956 70.686L21.3568 75.4025Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M16.8449 68.1295L15.9934 67.3366C16.5001 66.7798 16.4815 66.4316 15.892 65.8822C15.4137 65.4375 15.0454 65.3748 14.7665 65.6759L14.6376 65.8145C14.4353 66.0323 14.4629 66.2146 14.7855 66.628L15.2415 67.2195C15.8512 68.0148 15.9044 68.8843 15.3771 69.4536C14.7406 70.1383 13.5513 70.0905 12.7607 69.3554C11.9331 68.5832 11.8153 67.4356 12.4563 66.6007L13.3132 67.3969C12.8629 67.8811 12.8967 68.2037 13.4145 68.6855C13.8368 69.0782 14.1667 69.1252 14.4304 68.8414L14.549 68.7144C14.7563 68.4908 14.7319 68.2714 14.4217 67.8787L13.9533 67.2558C13.2926 66.3805 13.2341 65.6512 13.7865 65.0555C14.4436 64.3485 15.6992 64.4252 16.5446 65.2123C17.4521 66.0562 17.5662 67.2748 16.8449 68.1295Z"
                  fill="white"
                  fill-opacity="0.5" />
                <path
                  d="M51.9645 35.0195C50.6508 27.4308 44.1644 21.822 36.3284 21.822H35.0362V28.0065C27.4574 29.321 21.856 35.8157 21.856 43.662V44.9558H28.0058C29.3187 52.5445 35.8048 58.1533 43.6422 58.1533H44.9343V51.9704C52.5281 50.6567 58.1398 44.1617 58.1398 36.3133V35.0195H51.9645ZM44.9343 49.3551V45.8618H42.35V55.5049C36.5146 54.9515 31.8036 50.669 30.6179 44.9558H34.1314V42.3681H24.5008C25.0535 36.5262 29.3305 31.8092 35.0362 30.622V34.1135H37.6205V24.4704C43.4548 25.0238 48.1669 29.3063 49.3526 35.0195H45.8378V37.6072H55.4949C54.941 43.45 50.6538 48.1688 44.9343 49.3551Z"
                  fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_6536_81143">
                  <rect
                    width="80"
                    height="80"
                    fill="white" />
                </clipPath>
              </defs>
            </svg>
          </figure>
          <a
            href="https://www.micromilspec.com"
            class="font-bold my-4 underline mx-auto lg:py-12 pb-6">
            {{ getStaticString("readAboutMicromilspec") }}
          </a>
          <p class="text-black opacity-50 text-center my-6 uppercase tracking-widest text-sm lg:mb-4">
            {{ getStaticString("followUs") }}
          </p>
          <div class="flex text-center justify-center">
            <a
              href="https://www.instagram.com/micromilspec/"
              target="_blank"
              class="inline-block mx-4">
              <svg
                class="w-8 md:w-10 text-white md:text-black"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.9957 10.6644C13.0578 10.6644 10.6602 13.062 10.6602 16C10.6602 18.938 13.0578 21.3356 15.9957 21.3356C18.9336 21.3356 21.3312 18.938 21.3312 16C21.3312 13.062 18.9336 10.6644 15.9957 10.6644ZM31.9982 16C31.9982 13.7905 32.0182 11.601 31.8941 9.39554C31.7701 6.83381 31.1857 4.56027 29.3125 2.68701C27.4352 0.809737 25.1657 0.229345 22.604 0.105261C20.3946 -0.018823 18.2052 0.00119058 15.9997 0.00119058C13.7902 0.00119058 11.6008 -0.018823 9.39536 0.105261C6.83368 0.229345 4.56019 0.813739 2.68696 2.68701C0.809722 4.56427 0.22934 6.83381 0.105259 9.39554C-0.0188226 11.605 0.00119056 13.7945 0.00119056 16C0.00119056 18.2055 -0.0188226 20.399 0.105259 22.6045C0.22934 25.1662 0.813725 27.4397 2.68696 29.313C4.56419 31.1903 6.83368 31.7707 9.39536 31.8947C11.6048 32.0188 13.7943 31.9988 15.9997 31.9988C18.2092 31.9988 20.3986 32.0188 22.604 31.8947C25.1657 31.7707 27.4392 31.1863 29.3125 29.313C31.1897 27.4357 31.7701 25.1662 31.8941 22.6045C32.0222 20.399 31.9982 18.2095 31.9982 16ZM15.9957 24.2095C11.4527 24.2095 7.78631 20.5431 7.78631 16C7.78631 11.4569 11.4527 7.79045 15.9957 7.79045C20.5387 7.79045 24.2051 11.4569 24.2051 16C24.2051 20.5431 20.5387 24.2095 15.9957 24.2095ZM24.5413 9.37152C23.4806 9.37152 22.6241 8.51494 22.6241 7.45423C22.6241 6.39351 23.4806 5.53693 24.5413 5.53693C25.602 5.53693 26.4586 6.39351 26.4586 7.45423C26.4589 7.7061 26.4095 7.95556 26.3133 8.18832C26.217 8.42108 26.0758 8.63256 25.8977 8.81066C25.7196 8.98876 25.5082 9.12998 25.2754 9.22622C25.0426 9.32246 24.7932 9.37184 24.5413 9.37152Z"
                  fill="currentColor" />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/micromilspec/"
              target="_blank"
              class="inline-block mx-4">
              <svg
                class="w-8 md:w-10 text-white md:text-black"
                viewBox="0 0 30 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M28.3582 0.00195312H1.65292C0.742126 0.00195312 0 0.744079 0 1.65487V28.3489C0 29.2597 0.742126 30.0018 1.65292 30.0018H16.0232V18.3864H12.1101V13.855H16.0232V10.5266C16.0232 6.64735 18.3957 4.54466 21.8477 4.54466C23.5007 4.54466 24.9287 4.66835 25.3447 4.72457V8.77253H22.9497C21.0719 8.77253 20.7008 9.66083 20.7008 10.9764V13.8662H25.1873L24.6026 18.3977H20.7008V30.0243H28.347C29.2577 30.0243 29.9999 29.2822 29.9999 28.3714V1.65487C30.0111 0.744079 29.269 0.00195312 28.3582 0.00195312Z"
                  fill="currentColor" />
              </svg>
            </a>
          </div>
        </div>
        <div class="footer-v2-bg" />
      </div>
    </div>
  </footer>
</template>

<script>
import prismicMixins from "../../../utils/prismic-mixins"
import { staticStrings } from "../staticStrings"

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  mixins: [prismicMixins, staticStrings]
}
</script>
