<template>
  <div class="flex flex-wrap">
    <template v-for="(link, key) in links">
      <div
        v-if="link.url === null"
        :key="key"
        class="mr-1 mb-1 px-3 py-2 text-sm border border-gray-mm-300 rounded text-gray-700"
        :class="{ 'ml-auto': key === links.length - 1 }">
        {{ link.label }}
      </div>
      <inertia-link
        v-else
        :key="key"
        class="mr-1 mb-1 px-3 py-2 text-sm border border-gray-mm-400 rounded text-gray-700 hover:bg-white focus:border-gray-mm-600 focus:text-gray-800"
        :class="{ 'bg-white': link.active, 'ml-auto': key === links.length - 1 }"
        :href="link.url">
        {{ link.label }}
      </inertia-link>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default () {
        return []
      }
    },
  },
}
</script>
