<template>
  <layout>
    <div class="bg-yellow-mm-600 text-black py-16">
      <div class="container mx-auto">
        <div class="max-w-md mx-auto">
          <div class="font-medium">
            <h2 class="text-2xl leading-tight">
              {{ $t('checkout.confirmation.headline') }}
            </h2>
            <p class="text-2xl text-gray-mm-400 leading-tight">
              {{ $t('checkout.confirmation.subtitle') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="py-16">
      <div class="container mx-auto">
        <div class="max-w-md mx-auto">
          <p class="mb-6">
            {{ $t('checkout.confirmation.summary') }}
          </p>
          <p>{{ $t('checkout.confirmation.info_details') }}: <br><span class="text-gray-mm-300">{{ customer.phone }} or {{ customer.email }}</span></p>
          <p class="text-sm">
            {{ $t('checkout.confirmation.disclaimer') }}
          </p>
        </div>

        <confirmation-invoice
          v-if="order.payment_method === 'invoice'"
          v-bind="$props" />
        <confirmation-stripe-invoice
          v-else-if="order.payment_method === 'stripe-invoice'"
          v-bind="$props" />

        <div class="max-w-lg mx-auto px-8 py-12 bg-gray-mm-500 text-white rounded my-8">
          <p class="font-bold">
            {{ $t('checkout.confirmation.customer_portal_headline') }}
          </p>
          <p class="text-gray-mm-900">
            {{ $t('checkout.confirmation.customer_portal_text1') }} <a
              href="https://hq.micromilspec.com"
              class="text-gray-mm-900 hover:underline">https://hq.micromilspec.com</a> {{ $t('checkout.confirmation.customer_portal_text2') }}
          </p>
        </div>
        <div class="max-w-md mx-auto content">
          <p>{{ $t('checkout.confirmation.footer_title') }} <br> <span class="text-gray-mm-300">{{ $t('checkout.confirmation.footer_details') }} <a href="mailto:sos@micromilspec.com">sos@micromilspec.com</a></span></p>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import ConfirmationInvoice from "@/Shared/ConfirmationInvoice.vue"
import ConfirmationStripeInvoice from "@/Shared/ConfirmationStripeInvoice.vue"

export default {
  components: {
    Layout,
    ConfirmationInvoice,
    ConfirmationStripeInvoice
  },

  props: {
    order: {
      type: Object,
      default () {
        return {}
      }
    },

    customer: {
      type: Object,
      default () {
        return {}
      }
    },

    siteName: {
      type: String,
      default: null
    }
  },

  created () {
    try {
      window.fathom("trackPageview")

      window.fbq("track", "Purchase", {
        value: this.order.amount,
        currency: this.order.currency
      })
    } catch (e) {}

    try {
      let items = this.order.order_items.map(item => {
        return {
          item_id: item.id,
          item_name: item.product.name,
          item_category: this.siteName,
          price: item.amount,
          quantity: item.quantity
        }
      })
      window.gtag("event", "purchase", {
        transaction_id: this.order.id,
        value: this.order.total,
        tax: 0,
        currency: this.order.currency,
        items,
      })
    } catch (e) {}
  }
}
</script>
