<template>
  <layout>
    <template slot-scope="{ addToCart, submitting }">
      <div class="container mx-auto">
        <div class="mx-auto md:max-w-5xl xl:max-w-6xl md:my-8 lg:my-16">
          <slider
            v-if="false"
            :inset-scrollbar="true"
            :data="slider">
            <template v-slot:default="{ slide, index }">
              <div>Slide {{ slide }} {{ index }}</div>
            </template>
          </slider>

          <div>
            <div class="lg:grid grid-cols-2 gap-16">
              <div>
                <div class="bg-gray-mm-600 p-3 max-w-xl mx-auto">
                  <figure
                    v-if="product.images.edges.length"
                    class="pb-full relative">
                    <div
                      v-for="(image,index) in product.images.edges"
                      :key="index"
                      class="absolute inset-0 flex items-center justify-center">
                      <img
                        :src="image.node.featuredImage"
                        :alt="image.node.altText"
                        class="object-fill mx-auto">
                    </div>
                  </figure>
                </div>
              </div>
              <div>
                <div
                  v-if="$page.props.app.customer === null && requiresAssociatedOrder"
                  class="mx-auto max-w-md bg-white rounded-sm p-8 text-black my-8">
                  <h2 class="text-2xl leading-tight font-bold mb-4">
                    Which timepiece do you have?
                  </h2>
                  <p class="text-gray-mm-400">
                    This product is unique to the timepiece you bought, therefore we need to know which one you have.<br>Please sign in using the email or phone number you purchased your item with.
                  </p>

                  <auth class="mt-8" />
                </div>
                <div v-else>
                  <header class="mb-8 md:mb-12 mt-8">
                    <div class="text-gray-mm-300">
                      {{ product.collections.edges.length ? product.collections.edges[0].node.title : "" }}
                      {{ defaultCollection.title }}
                    </div>
                    <h1 class="text-2xl sm:text-3xl md:text-5xl leading-tight mb-1 font-medium">
                      {{ product.title }}
                    </h1>
                    <div class="text-gray-mm-300 leading-tight font-medium">
                      <p>{{ product.description }}</p>
                    </div>
                  </header>
                  <div class="my-8">
                    <ul
                      class="font-medium md:text-lg"
                      v-if="webshopProduct.features">
                      <li
                        v-for="(feature,fIndex) in $options.filters.splitNewline(webshopProduct.features)"
                        :key="fIndex"
                        class="flex items-center">
                        <span class="inline-block w-3 mr-2">
                          <svg
                            viewBox="0 0 9 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M8.42797 3.61201L5.31997 5.978V3.99H0.909973V2.674H5.31997V0.686005L8.42797 3.052V3.61201Z"
                              fill="white" />
                          </svg>
                        </span>
                        {{ feature }}
                      </li>
                    </ul>
                  </div>
                  <div class="my-8">
                    <div v-if="$page.props.market !== 'NO'">
                      <div class="text-yellow-mm-600 text-lg">
                        <nok-to-foreign :amount="product.priceRange.minVariantPrice.amount" />
                      </div>
                      <div class="text-gray-mm-300 text-sm mb-2">
                        {{ product.priceRange.minVariantPrice.amount | formatPriceWithDecimals(product.priceRange.minVariantPrice.currencyCode) }}
                      </div>
                    </div>
                    <div
                      v-else
                      class="text-yellow-mm-600 text-lg">
                      {{ product.priceRange.minVariantPrice.amount | formatPriceWithDecimals(product.priceRange.minVariantPrice.currencyCode) }}
                    </div>
                    <div
                      v-if="webshopProduct && webshopProduct.priceDescription"
                      class="text-gray-mm-300 text-sm">
                      {{ webshopProduct.priceDescription }}
                    </div>
                  </div>

                  <div
                    v-for="(field,fIndex) in requiredFields"
                    :key="fIndex">
                    <div
                      v-if="field.type === 'project'"
                      class="my-6">
                      <order-projects>
                        <template slot-scope="{ projects }">
                          <select-field
                            :options="projects"
                            label="Design / Project"
                            placeholder="Select project"
                            @input="setProjectProperty('project', $event)" />
                        </template>
                      </order-projects>
                    </div>

                    <div
                      class="my-6"
                      v-if="field.type === 'engraving'">
                      <input-field
                        label="Engraving"
                        @input="setProjectProperty('engraving_line_1', $event)" />
                    </div>
                  </div>

                  <validation-errors />

                  <div class="flex flex-col items-center mt-8 md:mb-8">
                    <div
                      v-if="product.availableForSale"
                      class="w-full">
                      <div
                        v-for="{ node: variant } in product.variants.edges"
                        :key="variant.id"
                        class="w-full">
                        <default-button
                          :loading="submitting"
                          tertiary
                          class="mb-4 w-full text-base"
                          @click.prevent.native="addToCart(formData, variant.id)">
                          Add to cart
                        </default-button>
                      </div>
                    </div>
                    <default-button
                      v-else-if="!product.availableForSale"
                      secondary
                      class="mb-4 w-full text-base">
                      Sold out
                    </default-button>
                    <div
                      v-if="webshopProduct && webshopProduct.shippingDescription"
                      class="font-medium text-sm">
                      {{ webshopProduct.shippingDescription }}
                    </div>
                  </div>

                  <accordion
                    v-for="(item,baIndex) in webshopProduct.accordion"
                    header-size="none"
                    :inverted="true"
                    :key="baIndex">
                    <template v-slot:header>
                      {{ item.title }}
                    </template>
                    <template v-slot:content>
                      <div v-html="$options.filters.portablePlainText(item.content)" />
                    </template>
                  </accordion>
                </div>
              </div>
            </div>
          </div>


          <div
            v-if="productRecommendations.length"
            class="my-8 md:my-20">
            <div class="font-medium">
              <h2 class="sm:text-3xl leading-tight mb-1">
                Related Products
              </h2>
            </div>
            <div class="my-8 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              <product-card
                v-for="(relatedProduct,rIndex) in productRecommendations"
                :product="relatedProduct"
                :key="rIndex" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "@/Pages/Webshop/Layout.vue"
import Auth from "@/Pages/Webshop/Partials/Auth.vue"
import OrderProjects from "@/Pages/Webshop/Partials/OrderProjects.vue"
import Slider from "@/Pages/Sales/Slider.vue"
import Accordion from "@/Shared/Accordion.vue"
import ProductCard from "@/Pages/Webshop/Partials/ProductCard.vue"
import NokToForeign from "@/Pages/Webshop/Partials/NokToForeign.vue"

export default {
  props: {
    product: {
      type: Object,
      default () {
        return {}
      }
    },

    webshopProduct: {
      type: Object,
      default () {
        return {}
      }
    },

    productRecommendations: {
      type: Array,
      default () {
        return []
      }
    },

    defaultCollection: {
      type: Object,
      default () {
        return {}
      }
    },

    requiresAssociatedOrder: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Layout,
    Auth,
    OrderProjects,
    Slider,
    Accordion,
    ProductCard,
    NokToForeign
  },

  data () {
    return {
      formData: {
        id: this.product.id,
        quantity: 1,
        properties: []
      }
    }
  },

  methods: {
    setProjectProperty (key, value) {
      this.formData.properties.push({ key, value })
    },

    todo () {
      console.log("Register engraving requirements somehow")
    }
  },

  computed: {
    requiredFields () {
      if (this.product.meta && this.product.meta.required_fields) {
        return this.product.meta.required_fields
      }

      return []
    }
  }
}
</script>
