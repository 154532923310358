<template>
  <div class="bg-black text-white py-6 sm:py-8">
    <div class="container mx-auto">
      <div class="bg-gray-mm-600">
        <div class="lg:flex items-center">
          <div class="lg:w-1/2 lg:pr-4">
            <div :class="{ 'p-4 md:p-8 lg:p-12': casebackKind !== 'closed-caseback-checkout' }">
              <Engraving
                :native-width="1000"
                :native-height="1000"
                :inputs="[
                  {
                    label: 'Din personlige tekst',
                    placeholder: 'Skriv inngravering her',
                    maxCharacters: 30,
                    center: 0
                  },
                ]"
                :value="value"
                :caseback-kind="casebackKind"
                :is-dark="isDark">
                <img
                  :src="image"
                  alt="">
              </Engraving>
            </div>
          </div>
          <div class="lg:w-1/2 px-4">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Engraving from "@/Shared/Engraving.vue"

export default {
  props: {
    value: {
      type: String,
      default: ""
    },

    image: {
      type: String,
      default: ""
    },

    isDark: {
      type: Boolean,
      default: false
    },

    casebackKind: {
      type: String,
      default: "closed-caseback-checkout"
    },
  },

  components: {
    Engraving
  }
}
</script>
