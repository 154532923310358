<template>
  <hq-layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Modify Your Relationship with {{ site.name }}
              </h1>
            </div>

            <form @submit.prevent="submit">
              <div class="my-8">
                <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
                  Relationship
                </p>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    Unit / Company
                  </p>

                  <div
                    v-if="formData.relationship_option && formData.relationship_option.id && formData.relationship_option.dial"
                    class="w-full my-4">
                    <figure class="pb-full relative">
                      <img
                        :src="formData.relationship_option.dial"
                        :alt="formData.relationship_option.id"
                        class="absolute inset-0">
                    </figure>
                  </div>
                  <div
                    v-if="formData.relationship_option && formData.relationship_option.id && formData.relationship_option.image"
                    class="w-full my-4">
                    <figure class="pb-full relative">
                      <img
                        :src="formData.relationship_option.image"
                        :alt="formData.relationship_option.id"
                        class="absolute inset-0">
                    </figure>
                  </div>
                  <div
                    v-if="false"
                    class="w-full my-4">
                    <figure class="opacity-50 pb-full" />
                  </div>
                  <select-field-object
                    v-if="site.meta.relationship_options"
                    :options="site.meta.relationship_options"
                    :grouped="site.meta.relationship_options_grouped ? true : false"
                    v-model="formData.relationship_option"
                    placeholder="Select one"
                    class="mb-4">
                    <template slot-scope="{ item }">
                      {{ item.title ? item.title : item.id }}
                    </template>
                  </select-field-object>
                </div>
                <div class="mb-4">
                  <default-button
                    tertiary
                    :loading="submitting"
                    :disabled="submitting">
                    Save Changes
                  </default-button>
                </div>
                <div
                  class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center"
                  v-if="$page.props.flash.success">
                  {{ $page.props.flash.success }}
                </div>
                <validation-errors unique />
              </div>
            </form>

            <div class="my-8 text-sm">
              <nav>
                <a
                  :href="route('account.orders.show', { id: order.id })"
                  class="block p-2 border-t border-gray-mm-600 hover:underline">Return to order</a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hq-layout>
</template>

<script>
import HqLayout from "@/Shared/HqLayout.vue"

export default {
  props: {
    customer: {
      type: Object,
      default () {
        return {}
      }
    },

    order: {
      type: Object,
      default () {
        return {}
      }
    },

    site: {
      type: Object,
      default () {
        return {
          meta: {
            relationship_options: []
          }
        }
      }
    }
  },

  components: {
    HqLayout
  },

  mounted () {
    if (this.site.meta.relationship_options && !this.site.meta.relationship_options_grouped) {
      let currentSelectionIndex = this.site.meta.relationship_options.findIndex(item => item.id === this.order.meta.relationship_option.id)

      if (currentSelectionIndex >= 0) {
        this.formData.relationship_option = this.site.meta.relationship_options[currentSelectionIndex]
      }
    }

    if (this.site.meta.relationship_options && this.site.meta.relationship_options_grouped) {
      this.site.meta.relationship_options.map(groupOptions => {
        let options = groupOptions.options ?? []
        let currentSelectionIndex = options.findIndex(item => item.id === this.order.meta.relationship_option.id)
        if (currentSelectionIndex >= 0) {
          this.formData.relationship_option = options[currentSelectionIndex]
        }
      })
    }
  },

  data () {
    return {
      formData: {
        relationship_option: {}
      },
      error: null,
      submitting: false,
      editMode: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.patch(`/orders/${this.order.id}/edit-relationship`, { relationship_option: this.formData.relationship_option })
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
