<template>
  <inertia-link
    :href="route('shop.products.show', { product: product.id })"
    :key="product.id"
    class="block">
    <div class="bg-gray-mm-600 p-3 md:p-5 relative">
      <div
        v-if="product.requires_associated_order"
        class="absolute top-0 left-0 flex items-center p-3 md:p-4 text-xs text-gray-mm-300">
        <figure class="w-3 mr-2">
          <svg
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.81899 6.2733V11.1819C9.81899 11.6338 9.45103 12 8.99911 12H0.818101C0.36618 12 0 11.6338 0 11.1819V6.2733C0 5.82138 0.36618 5.45519 0.818101 5.45519H1.0914V3.81899C1.0914 1.72194 2.81334 0 4.9086 0C7.00566 0 8.7276 1.72194 8.7276 3.81899V5.45519H8.99911C9.45103 5.45519 9.81899 5.82138 9.81899 6.2733ZM2.03275 5.47663H7.78625V3.86722C7.78625 2.25603 6.493 0.941352 4.9086 0.941352C3.32599 0.941352 2.03275 2.25603 2.03275 3.86722V5.47663Z"
              fill="#676767" />
          </svg>
        </figure>
        Requires authentication
      </div>
      <figure class="-mx-3 md:-mx-5">
        <img
          :src="product.featured_image.startsWith('http') ? product.featured_image : asset(product.featured_image)"
          :alt="product.name"
          class="object-contain h-40 mx-auto">
        <img
          v-if="product.meta && product.meta.full_size_image"
          :src="product.meta.full_size_image.startsWith('http') ? product.meta.full_size_image : asset(product.meta.full_size_image)"
          :alt="product.name"
          class="object-contain h-40 mx-auto">
      </figure>
      <h3 class="mt-4 mb-px block font-medium whitespace-no-wrap overflow-hidden truncate">
        {{ product.name }}
      </h3>

      <div class="text-yellow-mm-600 text-sm">
        <product-price :prices="product.prices">
          <template slot-scope="{ priceFormattedWithoutDecimals }">
            {{ priceFormattedWithoutDecimals }}
          </template>
        </product-price>
      </div>
    </div>
  </inertia-link>
</template>

<script>
import ProductPrice from "@/Pages/Shop/Partials/ProductPrice"

export default {
  props: {
    product: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    ProductPrice
  }
}
</script>
