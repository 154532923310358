const MIN_FRAMEDROP = 1
const MAX_FRAMEDROP = 16
const FRAME_NAME_PADDING = 3

const BACKGROUND_COLOR = "#000000"

function pad(number) {
  const zeros = "0".repeat(FRAME_NAME_PADDING - number.toString().length)
  return `${zeros}${number}`
}

function range(minI, maxI, minO, maxO, val) {
  let t = (val - minI) / (maxI - minI)
  t = 1 - Math.max(0, Math.min(1, t))
  const o = minO + (maxO - minO) * t
  return Math.max(minO, Math.min(maxO, o))
}

function loadImage(imagePath) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = imagePath
    image.onload = () => resolve(image)
    image.onerror = reject
  })
}

export class ImageSequence {
  constructor(config) {
    // Initialize state
    this.config = config

    this.progress = 0
    this.loadOrder = []
    this.loadedImageIndexes = []
    this.cachedImages = new Map()
    this.offset = 0
    this.renderedFrame = -1

    // Select asset resolution
    const sizes = this.config.sizes.sort((a, b) => b[1] - a[1])
    for (const size of sizes) {
      if (size[1] >= window.innerWidth) {
        this.size = size
      }
    }
    if (!this.size) {
      this.size = sizes[0]
    }

    // Generate array of image paths with the appropraite length and size prefix
    this.images = Array.from({length: config.length}, (_, i) => `${config.root}/${this.size[0]}/frame_${pad(i)}.jpg`)
  }

  async mount(frame, canvas) {
    this.frame = frame
    this.canvas = canvas
    this.context = this.canvas.getContext("2d")
    this.canvas.width = this.size[1]
    this.canvas.height = this.size[2]
    this.scaleCanvas()
    this.loadImages()
  }

  async loadImages() {
    // Generate image loading queue order
    let division = MAX_FRAMEDROP
    while (division >= MIN_FRAMEDROP) {
      for (let i = 0; i < this.config.length; i += division) {
        if (this.loadOrder.indexOf(i) === -1) {
          this.loadOrder.push(i)
        }
      }
      if (division === MAX_FRAMEDROP) {
        this.loadOrder.push(this.config.length - 1)
      }
      division = division / 2
    }

    for (const i of this.loadOrder) {
      const path = this.images[i]
      const image = await loadImage(path)

      this.loadedImageIndexes.push(i)
      this.loadedImageIndexes.sort((a, b) => b - a)
      this.cachedImages.set(path, image)
      this.renderFrame()
    }
  }

  destroy() {
  }

  scaleCanvas() {
    const aspect = this.size[2] / this.size[1]
    let width = this.frame.clientWidth
    const height = width * aspect
    this.frame.style.height = height + "px"

    // Fit
    const xFitScale = width / this.size[1]
    const yFitScale = height / this.size[2]
    let scale = Math.min(xFitScale, yFitScale)

    // Vertical alignments
    //const top = (height / 2) - (this.size[2] * scale / 2);

    // Center horizontally
    //const left = (width / 2) - (this.size[1] * scale) / 2;

    this.canvas.style.transform = `scale(${scale})`
    //this.canvas.style.top = `${top}px`;
    //this.canvas.style.left = `${left}px`;
  }

  setProgress(x) {
    this.progress = parseFloat(x)
    this.renderFrame()
  }

  renderFrame() {
    const desiredFrame = Math.round(this.progress * this.images.length)
    let frame = 0

    // Select nearest loaded frame that is lower than the desired one
    for (const loadedFrame of this.loadedImageIndexes) {
      if (loadedFrame <= desiredFrame) {
        frame = loadedFrame
        break
      }
    }

    // If this frame is already rendered or invalid, do nothing
    if (frame === this.renderedFrame || frame >= this.images.length || frame < 0) {
      return
    }

    // Grab cached frame image
    const image = this.cachedImages.get(this.images[frame])
    if (!image) {
      return
    }

    // Draw the image to canvas
    this.context.drawImage(image, 0, 0)

    // Draw black line drawn around border to hide half-white edges created by antialiasing
    this.context.strokeStyle = BACKGROUND_COLOR
    this.context.lineWidth = 2
    this.context.strokeRect(0, 0, this.canvas.width, this.canvas.height)

    this.renderedFrame = frame
  }
}
