<template>
  <div>
    <label
      class="block text-white text-sm mb-2"
      v-if="label">
      {{ label }}
    </label>
    <input
      v-model="model"
      ref="input"
      :name="name"
      class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent"
      :class="[{ 'text-center': centered }, bgClass]"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :autocomplete="autocomplete"
      @focus="$emit('focus')">

    <p
      v-if="maxlength"
      class="my-3 text-xs text-gray-mm-300 uppercase">
      {{ remaining }} {{ $t('globals.input.maxlength') }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },

    name: {
      type: String,
      default: null
    },

    value: {
      type: [String, Number],
      default: null
    },

    centered: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    autocomplete: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      default: "text"
    },

    bgClass: {
      type: String,
      default: "bg-gray-mm-600"
    },

    placeholder: {
      type: String,
      default: null
    },

    maxlength: {
      type: Number,
      default: null
    }
  },

  mounted () {
    if (this.autofocus) {
      this.$refs.input.focus()
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    remaining () {
      if (this.maxlength && this.value) {
        return this.maxlength - this.value.length
      }

      if (this.maxlength) {
        return this.maxlength
      }

      return null
    }
  }
}
</script>
