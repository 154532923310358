<template>
  <layout>
    <div class="global-header">
      <header>
        <div class="mm-container">
          <div class="mm-content">
            <div id="logo">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.2557 7.99158C17.4592 3.39633 13.5263 0 8.77506 0H7.99158V3.74493C3.39633 4.54093 0 8.4737 0 13.2249V14.0084H3.72886C4.52487 18.6037 8.45763 22 13.2096 22H13.9931V18.256C18.5975 17.4605 22 13.5275 22 8.77506V7.99158H18.2557ZM13.9931 16.6723V14.557H12.4262V20.3963C8.88801 20.0612 6.03156 17.468 5.31264 14.0084H7.443V12.4415H1.60367C1.93879 8.90398 4.53204 6.04763 7.99158 5.32871V7.443H9.55853V1.60367C13.096 1.93879 15.9531 4.53204 16.6721 7.99158H14.5409V9.55853H20.3963C20.0604 13.0966 17.461 15.954 13.9931 16.6723Z"
                  fill="white" />
              </svg>
            </div>
            <div class="mm-flex-one" />
            <nav>
              <a href="https://shop.micromilspec.com/">Shop</a>
              <a href="https://hq.micromilspec.com/">Login</a>
              <a href="https://www.micromilspec.com/#contact">Contact</a>
            </nav>
          </div>
        </div>
      </header>
    </div>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4 text-center">
                Sign in to manage your order.
              </h1>
            </div>

            <form @submit.prevent="submit">
              <div class="mb-2">
                <div class="mb-2 w-full">
                  <input-field
                    v-model="formData.emailOrPhone"
                    type="text"
                    placeholder="Your email or phone number"
                    autofocus />
                  <validation-errors />
                </div>
                <default-button
                  tertiary
                  class="w-full"
                  :loading="submitting"
                  :disabled="submitting">
                  Send Code
                </default-button>
              </div>
              <div
                class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-6 font-semibold text-sm text-center"
                v-if="$page.props.flash.success">
                {{ $page.props.flash.success }}
              </div>
            </form>

            <div
              class="mt-8"
              v-if="false">
              <p class="p-4 border-gray-mm-400 border-2 border-dotted">
                The edit window for orders have now closed. Please contact support for any questions regarding your order.
              </p>
            </div>
            <div class="mt-8 text-center">
              <p>
                If you have troubles signing in, please reach out to us by email. <a
                  href="sos@micromilspec.com"
                  class="text-yellow-mm-600 underline">sos@micromilspec.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"

export default {
  components: {
    Layout
  },

  data () {
    return {
      formData: {
        emailOrPhone: null
      },
      error: null,
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post("/auth", this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss">
  .global-header {
    .mm-container {
      width: 100%;
      max-width: 90rem;
      padding: 0 .81rem;
      margin-left: auto;
      margin-right: auto;
    }

    header {
      font-family: 'NB Architekt';
      font-size: .875rem;
      line-height: 1.15;
      color: #000;
    }

    .mm-content {
      align-items: center;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #3d3d3d;
    }

    #logo {
      width: 1.37rem;
      height: 1.37rem;
      display: block;
      margin: .5rem 0;
    }

    nav a {
      color: #EEEDE7;
      text-decoration: none;
      text-transform: uppercase;
      padding-left: .7rem;
    }

    .mm-flex-one {
      flex: 1;
    }
  }
</style>
