<template>
  <div class="range-sequence">
    <div class="sequence">
      <Sequence
        :time="time"
        :manifest="manifest" />
    </div>
    <ToggleTimeline
      size="large"
      @change="onProgress">
      <template v-slot:a>
        <span class="label">{{ manifest.left }}</span>
      </template>
      <template v-slot:b>
        <span class="label">{{ manifest.right }}</span>
      </template>
    </ToggleTimeline>
  </div>
</template>

<script>
import Vue from "vue"
import ToggleTimeline from "./ToggleTimeline.vue"
import Sequence from "./Sequence.vue"

export default Vue.extend({
  data: function() {
    return {time: 0}
  },
  props: ["manifest"],
  methods: {
    onProgress: function(t) {
      this.time = t
    }
  },
  components: {Sequence, ToggleTimeline}
})
</script>
