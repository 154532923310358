<template>
  <div>
    <form
      v-if="!$page.props.query.verifyOtp"
      @submit.prevent="submitOtpRequest">
      <div class="mb-2">
        <div
          class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-6 font-semibold text-sm text-center"
          v-if="$page.props.flash.success">
          {{ $page.props.flash.success }}
        </div>
        <div class="mb-2 w-full">
          <input-field
            v-model="formData.email"
            type="email"
            placeholder="Your email"
            autofocus
            bg-class="bg-gray-mm-100 text-black placeholder-gray-mm-400"
          />
          <validation-errors />
        </div>
        <default-button
          tertiary
          :loading="submitting"
          :disabled="submitting"
          class="w-full">
          Send Code
        </default-button>
      </div>
    </form>
    <form
      v-else
      @submit.prevent="submitOtp">
      <div>
        <div
          class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-6 font-semibold text-sm text-center"
          v-if="$page.props.flash.success">
          {{ $page.props.flash.success }}
        </div>
        <div class="mb-2 w-full">
          <input-field
            v-model="formData.code"
            type="text"
            placeholder="Pin code"
            autofocus
            bg-class="bg-gray-mm-100 text-black placeholder-gray-mm-400" />
          <validation-errors />
        </div>
        <default-button
          tertiary
          :loading="submitting"
          :disabled="submitting"
          class="w-full">
          Submit
        </default-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      formData: {
        email: null,
        code: null
      },
      error: null,
      submitting: false
    }
  },

  methods: {
    submitOtpRequest () {
      this.submitting = true
      this.$inertia.post("/auth", { email: this.formData.email }, {
        withCredentials: true
      })
        .then(() => {
          this.submitting = false
        })
    },

    submitOtp () {
      this.submitting = true
      this.$inertia.post("/verify-otp", { code: this.formData.code }, {
        withCredentials: true,
        preserveState: false
      })
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
