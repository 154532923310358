<template>
  <div>
    <default-button
      primary
      @click.prevent.native="swapEngraving"
      :loading="swappingEngraving">
      Swap Engraving
    </default-button>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      swappingEngraving: false
    }
  },

  methods: {
    swapEngraving () {
      let goAhead = window.confirm("Are you sure?")
      if (goAhead) {
        this.swappingEngraving = true
        // this.$inertia.patch(this.route("admin.orders.swap-engraving", { id: this.order.id }))
        this.$inertia.patch(`/orders/${this.id}/swap-engraving`, { redirect: "index" })
          .then(() => {
            this.swappingEngraving = false
          })
      }
    }
  },
}
</script>
