import PrismicDOM from "prismic-dom"

const mixins = {
  methods: {
    asHtml: PrismicDOM.RichText.asHtml,
    asText: PrismicDOM.RichText.asText,
  }
}

export default mixins
