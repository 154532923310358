<template>
  <div>
    <div
      v-for="(item,index) in checkoutItemsWithoutShipping"
      :key="index"
      class="border border-gray-mm-500 px-3 py-2 rounded-sm"
      :class="{ 'border-t-0': index > 0 }">
      <div
        class="flex items-start mb-1">
        <div>
          <img
            :src="item.featured_image.startsWith('http') ? item.featured_image : asset(item.featured_image)"
            :alt="item.name"
            class="w-24 h-24 object-contain">
        </div>
        <div class="px-4 pt-3">
          <div class="font-medium mb-px">
            {{ item.quantity }} &times; {{ item.name }}
          </div>
          <div class="text-yellow-mm-600 text-sm">
            <product-price
              :prices="item.prices"
              :quantity="item.quantity">
              <template slot-scope="{ priceFormatted }">
                {{ priceFormatted }}
              </template>
            </product-price>
          </div>
          <div
            v-if="Object.keys(item.properties).length > 0"
            class="mt-2">
            <div
              v-for="(property, key, index) in item.properties"
              :key="index"
              class="text-gray-mm-300 text-xs">
              <span class="capitalize">{{ key.replaceAll('_', ' ') }}</span> - {{ property }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductPrice from "@/Pages/Shop/Partials/ProductPrice"

export default {
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },

  computed: {
    checkoutItemsWithoutShipping () {
      return this.items.filter(item => item.type != "shipping")
    }
  },

  components: {
    ProductPrice
  }
}
</script>
