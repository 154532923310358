<template>
  <pre>
    {{ pretty }}
  </pre>
</template>

<script>
export default {
  props: {
    json: {
      type: String,
      default: null
    }
  },

  computed: {
    pretty () {
      return JSON.stringify(JSON.parse(this.json), null, 2)
    }
  }
}
</script>
