<template>
  <div class="pb-20">
    <cart-provider :cart-is-open="cartIsOpenByDefault">
      <template slot-scope="{ add, submitting, remove, errors, increment, decrement, open, close, cartIsOpen }">
        <main>
          <div class="text-white py-4">
            <div class="container mx-auto">
              <div class="mx-auto md:max-w-5xl xl:max-w-7xl">
                <div class="flex items-center justify-end h-12">
                  <inertia-link
                    :href="route('shop.index')"
                    class="block flex-shrink-0 w-8 h-8 mr-auto">
                    <svg
                      viewBox="0 0 26 26"
                      fill="#ffffff"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.575 9.4446C20.6336 4.01384 15.9856 0 10.3705 0H9.4446V4.42583C4.01384 5.36656 0 10.0144 0 15.6295V16.5554H4.40684C5.34757 21.9862 9.99538 26 15.6114 26H16.5373V21.5753C21.9788 20.6351 26 15.9871 26 10.3705V9.4446H21.575ZM16.5373 19.7037V17.2037H14.6855V24.1047C10.504 23.7087 7.12821 20.644 6.27858 16.5554H8.79627V14.7036H1.89525C2.2913 10.5229 5.35605 7.1472 9.4446 6.29757V8.79627H11.2964V1.89525C15.4771 2.2913 18.8537 5.35605 19.7033 9.4446H17.1847V11.2964H24.1047C23.7078 15.4778 20.6357 18.8547 16.5373 19.7037Z"
                      />
                    </svg>
                  </inertia-link>
                  <div
                    v-if="$page.props.app.customer"
                    class="flex text-sm text-right items-center">
                    <span class="text-gray-mm-300 inline-block mr-3">Logged in as <span class="text-white">{{ $page.props.app.customer.name }}</span></span>
                    <a
                      :href="route('shop.logout')"
                      class="font-bold inline-block px-3 py-2 leading-tight border border-gray-mm-500 rounded-sm">Log out</a>
                  </div>
                  <div
                    v-if="cartIsVisible"
                    class="pl-8 text-sm py-3">
                    <a
                      href="#"
                      @click.prevent="open"
                      class="block w-6 h-6 relative">
                      <svg
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M3 7L1 23H23L21 7H3Z"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-linejoin="round" />
                        <path
                          d="M17 6C17 3.23858 14.7614 1 12 1C9.23858 1 7 3.23858 7 6"
                          stroke="#ffffff"
                          stroke-width="2" />
                        <path
                          d="M7 6V10"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-linecap="round" />
                        <path
                          d="M17 6V10"
                          stroke="#ffffff"
                          stroke-width="2"
                          stroke-linecap="round" />
                      </svg>

                      <span
                        v-if="$page.props.cart.count"
                        class="inline-flex items-center justify-center absolute top-0 left-0 text-black bg-yellow-mm-600 rounded-full p-1 text-xs leading-none w-5 h-5 text-center -ml-3 -mt-1">{{ $page.props.cart.count }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <slot
            :add-to-cart="add"
            :submitting="submitting" />
          <!-- v-if="$page.props.cart && cartIsOpen" -->
          <div
            v-if="$page.props.cart && cartIsOpen"
            class="fixed inset-0 bg-black opacity-50 z-30"
            @click.prevent="close" />
          <div
            class="fixed top-0 right-0 h-full w-10/12 sm:w-full sm:max-w-md bg-black shadow-xl z-40 flex flex-col  transform transition-transform duration-300"
            :class="{ 'translate-x-full': !cartIsOpen, 'translate-x-0': $page.props.cart && cartIsOpen }">
            <div class="bg-yellow-mm-600 text-black p-4 md:px-5">
              <h2 class="text-lg md:text-xl leading-tight font-medium">
                Cart
              </h2> <div>
                {{ $page.props.cart.count }} items in your cart
              </div>
            </div>
            <div class="px-4 mt-3">
              <validation-errors />
            </div>
            <div
              class="p-4 overflow-y-auto"
              v-if="$page.props.cart.count > 0">
              <div
                v-for="(item,index) in $page.props.cart.items"
                :key="index"
                class="bg-gray-mm-600 mb-4 px-4 py-2 rounded-sm">
                <div class="flex items-start mb-1">
                  <div>
                    <img
                      :src="item.featured_image.startsWith('http') ? item.featured_image : asset(item.featured_image)"
                      :alt="item.name"
                      class="w-32 h-24 object-contain">
                  </div>
                  <div class="px-4 pt-3">
                    <div class="font-medium mb-px">
                      {{ item.name }}
                    </div>
                    <div class="text-yellow-mm-600 text-sm">
                      <product-price :prices="item.product.prices">
                        <template slot-scope="{ priceFormattedWithoutDecimals }">
                          {{ priceFormattedWithoutDecimals }}
                        </template>
                      </product-price>
                    </div>
                    <div
                      v-if="Object.keys(item.properties).length > 0"
                      class="mt-2">
                      <div
                        v-for="(property, key, index) in item.properties"
                        :key="index"
                        class="text-gray-mm-300 text-xs">
                        <span class="capitalize">{{ key.replaceAll('_', ' ') }}</span> - {{ property }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-between items-center">
                  <div class="flex items-center">
                    <button
                      @click="decrement(item)"
                      :disabled="item.quantity === 1"
                      :class="{ 'opacity-50': item.quantity === 1 }"
                      class="block w-12 h-12 inline-flex justify-center items-center focus:outline-white">
                      <svg
                        width="29"
                        height="28"
                        viewBox="0 0 29 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26.5 14C26.5 20.6274 21.1274 26 14.5 26C7.87258 26 2.5 20.6274 2.5 14C2.5 7.37258 7.87258 2 14.5 2C21.1274 2 26.5 7.37258 26.5 14ZM28.5 14C28.5 21.732 22.232 28 14.5 28C6.76801 28 0.5 21.732 0.5 14C0.5 6.26801 6.76801 0 14.5 0C22.232 0 28.5 6.26801 28.5 14ZM9.5 13V15H19.5V13H9.5Z"
                          fill="white" />
                      </svg>
                    </button>
                    <div class="block w-8 text-center">
                      {{ item.quantity }}
                    </div>
                    <button
                      @click="increment(item)"
                      :disabled="item.quantity >= 10"
                      :class="{ 'opacity-50': item.quantity >= 10 }"
                      class="block w-12 h-12 inline-flex justify-center items-center focus:outline-white">
                      <svg
                        width="29"
                        height="28"
                        viewBox="0 0 29 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M26.5 14C26.5 20.6274 21.1274 26 14.5 26C7.87258 26 2.5 20.6274 2.5 14C2.5 7.37258 7.87258 2 14.5 2C21.1274 2 26.5 7.37258 26.5 14ZM28.5 14C28.5 21.732 22.232 28 14.5 28C6.76801 28 0.5 21.732 0.5 14C0.5 6.26801 6.76801 0 14.5 0C22.232 0 28.5 6.26801 28.5 14ZM13.5 13V9H15.5V13H19.5V15H15.5V19H13.5V15H9.5V13H13.5Z"
                          fill="white" />
                      </svg>
                    </button>
                  </div>
                  <div>
                    <a
                      href="#"
                      @click.prevent="remove(item)"
                      class="text-white p-2 leading-none border border-transparent bg-black hover:bg-white hover:text-black inline-flex items-center rounded-sm text-xs">Remove</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="p-6">
              <p class="text-gray-mm-200 text-center text-sm">
                Your cart is empty.
              </p>
            </div>
            <div
              v-if="$page.props.cart.count > 0"
              class="mt-auto pt-6 px-6 flex justify-between font-bold">
              <div>Total</div>
              <div>
                <total-price
                  :price="$page.props.cart.total"
                  :currency="$page.props.currency" />
              </div>
            </div>
            <div
              v-if="$page.props.cart.count > 0"
              class="p-6">
              <default-button-link
                tertiary
                class="mb-4 w-full text-base text-center"
                :href="route('shop.checkout')">
                Proceed to checkout
              </default-button-link>
            </div>
          </div>

          <div class="mt-12 pt-8 border-t border-gray-mm-500">
            <footer class="container mx-auto">
              <div class="mx-auto md:max-w-5xl xl:max-w-6xl text-sm">
                <div class="flex items-center leading-none">
                  <figure class="text-gray-mm-200 mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                    </svg>
                  </figure>
                  <a
                    href="#"
                    class="text-gray-mm-200"
                    @click.prevent="showCountrySelector = true">Currency <span class="font-semibold text-white mt-1 block">{{ $page.props.currency }}</span></a>
                </div>
              </div>
            </footer>
          </div>

          <portal to="modals">
            <div
              v-if="showCountrySelector || !$page.props.currency"
              class="fixed inset-0 flex items-center justify-center z-20">
              <div
                class="absolute inset-0 bg-black opacity-25"
                @click.prevent="showCountrySelector = false" />
              <div class="relative bg-white text-black p-8 md:pt-12 md:pb-16 md:px-16 w-full max-w-lg rounded shadow-2xl">
                <div class="text-2xl font-semibold text-center mb-2">
                  Welcome to Micromilspec
                </div>
                <div class="text-lg text-gray-mm-800 text-center leading-snug">
                  Choose your location for more accurate shipping rates, taxes and duties.
                </div>
                <div class="pt-8 grid gap-1 text-lg">
                  <inertia-link
                    :href="route('shop.currency', { currency: 'USD' })"
                    class="font-medium border border-gray-mm-200 px-4 py-2 rounded-sm hover:bg-gray-mm-100">
                    USD
                  </inertia-link>
                  <inertia-link
                    :href="route('shop.currency', { currency: 'CAD' })"
                    class="font-medium border border-gray-mm-200 px-4 py-2 rounded-sm hover:bg-gray-mm-100">
                    CAD
                  </inertia-link>
                  <inertia-link
                    :href="route('shop.currency', { currency: 'NOK' })"
                    class="font-medium border border-gray-mm-200 px-4 py-2 rounded-sm hover:bg-gray-mm-100">
                    NOK
                  </inertia-link>
                  <inertia-link
                    :href="route('shop.currency', { currency: 'EUR' })"
                    class="font-medium border border-gray-mm-200 px-4 py-2 rounded-sm hover:bg-gray-mm-100">
                    EUR
                  </inertia-link>
                  <inertia-link
                    :href="route('shop.currency', { currency: 'SEK' })"
                    class="font-medium border border-gray-mm-200 px-4 py-2 rounded-sm hover:bg-gray-mm-100">
                    SEK
                  </inertia-link>
                </div>
              </div>
            </div>
          </portal>
        </main>
      </template>
    </cart-provider>
    <portal-target name="modals" />
  </div>
</template>

<script>
import CartProvider from "@/Pages/Shop/CartProvider"
import ProductPrice from "@/Pages/Shop/Partials/ProductPrice"
import TotalPrice from "@/Pages/Shop/Partials/TotalPrice"

export default {
  props: {
    cartIsVisible: {
      type: Boolean,
      default: true
    },

    cartIsOpenByDefault: {
      type: Boolean,
      default: false
    },
  },

  components: {
    CartProvider,
    ProductPrice,
    TotalPrice
  },

  data () {
    return {
      showCountrySelector: false
    }
  }
}
</script>
