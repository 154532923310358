<template>
  <div>
    <form @submit.prevent="upload">
      <input
        type="file"
        id="file"
        ref="file"
        @change="upload">
      <default-button v-if="false">
        Upload
      </default-button>

      <div
        v-if="uploadProgress > 0"
        class="h-1 bg-gray-mm-600 rounded-full relative mt-2">
        <span
          class="bg-white h-1 rounded-full inline-block absolute top-0 left-0"
          :style="{ width: uploadProgress + '%' }" />
      </div>
      <div
        v-else
        class="h-1 mt-2" />
    </form>
  </div>
</template>

<script>
import Vapor from "laravel-vapor"

export default {
  data () {
    return {
      uploadProgress: 0
    }
  },

  methods: {
    upload () {
      Vapor.store(this.$refs.file.files[0], {
        progress: progress => {
          this.uploadProgress = Math.round(progress * 100)
        }
      }).then(response => {
        this.$emit("uploaded", {
          uuid: response.uuid,
          key: response.key,
          bucket: response.bucket,
          name: this.$refs.file.files[0].name,
          content_type: this.$refs.file.files[0].type,
        })
        // axios.post("/image", {
        //   uuid: response.uuid,
        //   key: response.key,
        //   bucket: response.bucket,
        //   name: this.$refs.file.files[0].name,
        //   content_type: this.$refs.file.files[0].type,
        // })
      })
    }
  }
}
</script>
