<template>
  <div class="max-w-lg mx-auto px-8 py-12 bg-gray-mm-500 text-white rounded my-8">
    <div
      class="p-3 block text-center"
      v-if="fetching">
      <p>{{ $t('checkout.confirmation.stripe_invoice.fetching') }}</p>
    </div>

    <div
      class="invoice-dl"
      v-if="invoice">
      <dl>
        <dt class="font-semibold">
          MICROMILSPEC <br><span class="text-gray-mm-300">{{ $t('checkout.confirmation.stripe_invoice.invoice') }}</span>
        </dt>
        <dd>
          {{ customer.first_name }} {{ customer.last_name }} <br>
          {{ customer.address_line_1 }} <br>
          {{ customer.postal_code }} {{ customer.city }}
        </dd>
        <dt>{{ $t('checkout.confirmation.stripe_invoice.amount') }}</dt>
        <dd class="font-bold">
          {{ order.amount }}
        </dd>
        <dt>{{ $t('checkout.confirmation.stripe_invoice.number_of_installments') }}</dt>
        <dd class="font-bold">
          {{ order.number_of_installments }}
        </dd>
        <dt v-if="order.number_of_installments > 1">
          {{ $t('checkout.confirmation.stripe_invoice.first_installment') }}
        </dt>
        <dt v-else>
          {{ $t('checkout.confirmation.stripe_invoice.invoice_amount') }}
        </dt>
        <dd class="font-bold">
          {{ invoice.amount }}
        </dd>
        <dt>{{ $t('checkout.confirmation.stripe_invoice.invoice_due_date') }}</dt>
        <dd class="font-bold">
          {{ invoice.invoice_due_date }}
        </dd>
      </dl>

      <div class="flex items-center justify-center py-8">
        <a
          :href="invoice.invoice_url"
          target="_blank"
          class="inline-block px-4 py-3 font-semibold text-sm rounded-sm focus:outline-none focus:shadow-outline whitespace-no-wrap text-black bg-yellow-mm-600 hover:bg-yellow-700">
          {{ $t('checkout.confirmation.stripe_invoice.button') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  props: {
    order: {
      type: Object,
      default () {
        return {}
      }
    },

    customer: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  data () {
    return {
      invoice: null,
      fetchAttempt: 0,
      fetching: true,
      interval: null
    }
  },

  mounted () {
    this.fetchInvoice()
    this.interval = setInterval(this.fetchInvoice, 5000)
  },

  methods: {
    fetchInvoice () {
      if (this.fetchAttempt > 10 || this.invoice !== null) return
      axios.get(`/confirmation/${this.order.id}/stripe-invoice`)
        .then((response) => {
          this.invoice = response.data.data
          this.fetching = false
          clearInterval(this.interval)
        })
        .catch(() => {
          this.fetchAttempt = this.fetchAttempt + 1
        })
    }
  }
}
</script>
