<template>
  <layout :cart-is-visible="true">
    <div class="container mx-auto">
      <div class="mx-auto max-w-5xl">
        <div class="max-w-lg mx-auto">
          <header class="mb-8 md:mb-16 md:mt-8 font-medium text-center">
            <h1 class="sm:text-3xl leading-tight mb-1">
              Your cart is empty
            </h1>
          </header>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Pages/Shop/Layout.vue"

export default {
  components: {
    Layout
  }
}
</script>
