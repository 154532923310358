<template>
  <div class="bg-black text-white py-6 sm:py-8">
    <div class="container mx-auto">
      <div class="lg:flex items-center">
        <div class="lg:w-3/12 xl:w-1/3 mb-6 lg:mb-0">
          <slot name="leftcol" />
        </div>
        <div class="lg:w-6/12 xl:w-1/3">
          <div class="pb-8 font-medium">
            <h2 class="sm:text-2xl leading-tight">
              {{ title }}
            </h2>
            <p
              v-if="subtitle"
              class="sm:text-2xl text-gray-mm-300 leading-tight">
              {{ subtitle }}
            </p>
          </div>
          <div>
            <slot />
          </div>
        </div>
        <div class="lg:w-3/12 xl:w-1/3 mb-6 lg:mb-0">
          <slot name="rightcol" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    }
  }
}
</script>
