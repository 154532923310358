<template>
  <layout :cart-is-visible="false">
    <div class="container mx-auto">
      <div class="mx-auto max-w-5xl">
        <div class="mx-auto grid sm:grid-cols-8 gap-4 md:gap-0">
          <div class="sm:col-span-4">
            <header class="mb-8 md:mt-8 font-medium">
              <h1 class="text-2xl sm:text-3xl md:text-4xl leading-tight mb-1">
                Checkout
              </h1>
              <div class="text-gray-mm-400 text-sm mt-2 leading-tight flex">
                <span class="mr-3 text-white">Information</span>
                <span class="mr-3">Shipping</span>
                <span class="mr-3">Payment</span>
              </div>
            </header>

            <div v-if="!$page.props.app.customer">
              <div class="bg-yellow-mm-600 text-black p-4 md:p-5 rounded-sm">
                <h2 class="text-lg leading-tight mb-2 font-semibold">
                  Existing customer?
                </h2>
                <div>
                  In order to buy certain accessories you must have already purchased a timepiece from us. <a
                    href="#"
                    @click.prevent="showLogin = !showLogin"
                    class="underline">Log in here</a>
                </div>
              </div>

              <div
                v-if="showLogin"
                class="p-4 md:p-5 bg-gray-mm-600">
                <auth />
              </div>
            </div>

            <div class="my-8 md:mt-12 md:mb-16">
              <div
                v-if="false"
                class="my-4 rounded-sm">
                <div class="bg-yellow-mm-600 text-black p-4 md:px-5 rounded-sm rounded-b-none">
                  <h2 class="text-lg md:text-xl leading-tight font-medium">
                    Customer Details
                  </h2>
                  <div>
                    Please verify your details below
                  </div>
                </div>
                <div class="bg-gray-mm-600 p-4 md:p-5">
                  <dl>
                    <dt class="text-sm text-gray-mm-300 mb-px">
                      First Name
                    </dt>
                    <dd class="mb-3">
                      {{ formData.billing_first_name }}
                    </dd>
                    <dt class="text-sm text-gray-mm-300 mb-px">
                      Last Name
                    </dt>
                    <dd class="mb-3">
                      {{ formData.billing_last_name }}
                    </dd>
                    <dt class="text-sm text-gray-mm-300 mb-px">
                      Address
                    </dt>
                    <dd class="mb-3">
                      {{ formData.billing_address_line_1 }} <br>
                      <span v-if="formData.billing_address_line_2">{{ formData.billing_address_line_2 }}</span>
                      {{ formData.billing_state }} {{ formData.billing_postal_code }} {{ formData.billing_city }}, {{ selectedCountry }}
                    </dd>
                    <dt class="text-sm text-gray-mm-300 mb-px">
                      Phone
                    </dt>
                    <dd class="mb-3">
                      {{ formData.billing_phone }}
                    </dd>
                    <dt class="text-sm text-gray-mm-300 mb-px">
                      Email
                    </dt>
                    <dd class="mb-5">
                      {{ formData.email }}
                    </dd>
                  </dl>
                  <div>
                    <default-button
                      inverted
                      @click.prevent.native="editCustomer = !editCustomer">
                      Edit Details
                    </default-button>
                  </div>
                </div>
              </div>
              <div class="my-4">
                <h2 class="sm:text-2xl leading-tight mb-6 font-medium">
                  Customer Details
                </h2>
                <div class="grid grid-cols-2 gap-4">
                  <input-field
                    placeholder="First Name"
                    label="First Name"
                    name="firstname"
                    v-model="formData.billing_first_name"
                    class="mb-3"
                    bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                  <input-field
                    placeholder="Last Name"
                    label="Last Name"
                    name="surname"
                    v-model="formData.billing_last_name"
                    class="mb-3"
                    bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                </div>
                <input-field
                  placeholder="Street Address"
                  label="Street Address"
                  name="street_address"
                  v-model="formData.billing_address_line_1"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  placeholder="Street Address Line 2"
                  label="Street Address Line 2"
                  name="street_address_line_2"
                  v-model="formData.billing_address_line_2"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <select-field
                  :options="countries"
                  option-key="Code"
                  option-value="Name"
                  name="country"
                  placeholder="Choose Country"
                  label="Choose Country"
                  v-model="formData.billing_country"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <div class="grid grid-cols-2 gap-4">
                  <input-field
                    placeholder="City"
                    label="City"
                    name="city"
                    v-model="formData.billing_city"
                    class="mb-3"
                    bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                  <input-field
                    v-if="['US', 'CA'].includes(formData.billing_country)"
                    placeholder="State"
                    label="State"
                    name="state"
                    v-model="formData.billing_state"
                    class="mb-3"
                    bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                  <input-field
                    placeholder="Zip Code"
                    label="Zip Code"
                    name="postal_code"
                    v-model="formData.billing_postal_code"
                    class="mb-3"
                    bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                </div>
                <input-field
                  placeholder="Phone"
                  label="Phone"
                  name="phone"
                  v-model="formData.billing_phone"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  placeholder="Email"
                  label="Email"
                  name="email"
                  v-model="formData.email"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
              </div>
              <div class="mt-2 pl-4 md:pl-5 pr-4">
                <input-checkbox
                  v-model="formData.use_separate_shipping_address"
                  class="mb-3">
                  Use a separate shipping address
                </input-checkbox>
              </div>
            </div>
            <div
              class="my-8 md:my-16"
              v-if="formData.use_separate_shipping_address">
              <h2 class="sm:text-2xl leading-tight mb-6 font-medium">
                Shipping Information
              </h2>
              <div class="my-4">
                <input-field
                  placeholder="First Name"
                  name="shipping_first_name"
                  v-model="formData.shipping_first_name"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  placeholder="Last Name"
                  name="shipping_last_name"
                  v-model="formData.shipping_last_name"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  placeholder="Street Address"
                  name="shipping_street_address"
                  v-model="formData.shipping_address_line_1"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  placeholder="Street Address Line 2"
                  name="shipping_street_address_line_2"
                  v-model="formData.shipping_address_line_2"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <select-field
                  :options="countries.filter((country) => country.Code === formData.billing_country)"
                  option-key="Code"
                  option-value="Name"
                  name="shipping_country"
                  placeholder="Choose Country"
                  v-model="formData.shipping_country"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  v-if="['US', 'CA'].includes(formData.shipping_country)"
                  placeholder="State / Province"
                  name="shipping_state"
                  v-model="formData.shipping_state"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  placeholder="City"
                  name="shipping_city"
                  v-model="formData.shipping_city"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
                <input-field
                  placeholder="Zip Code"
                  name="shipping_postal_code"
                  v-model="formData.shipping_postal_code"
                  class="mb-3"
                  bg-class="placeholder-gray-mm-300 bg-gray-mm-600" />
              </div>
            </div>

            <validation-errors />

            <div class="flex flex-col items-center mt-8 mb-8 sm:mb-16">
              <default-button
                tertiary
                :loading="submitting"
                class="mb-4 w-full text-base"
                @click.prevent.native="submit">
                Continue to shipping
              </default-button>
            </div>
          </div>
          <div class="sm:col-span-3 sm:col-start-6 sm:pt-28 mt-2">
            <div>
              <h2 class="sm:text-2xl leading-tight mb-1 font-medium">
                Summary
              </h2>

              <checkout-items-summary
                :items="checkout.items"
                class="mt-4 mb-8" />

              <div class="flex my-1 justify-between">
                <span>Items</span>
                <span>{{ checkout.items_count }}</span>
              </div>
              <div class="flex my-1 justify-between">
                <span>Subtotal</span>
                <total-price
                  :price="checkout.subtotal"
                  :currency="checkout.currency" />
              </div>
              <div class="flex my-1 justify-between">
                <span>Shipping</span>
                <total-price
                  :price="checkout.total_shipping"
                  :currency="checkout.currency" />
              </div>
              <div class="flex my-1 justify-between items-end">
                <span>Tax</span>
                <span v-if="checkout.has_calculated_tax">
                  <total-price
                    :price="checkout.total_tax"
                    :currency="checkout.currency" />
                </span>
                <span
                  v-else
                  class="text-sm text-gray-mm-300">Not calculated</span>
              </div>
              <div class="flex mt-4 justify-between border-t border-gray-mm-500 pt-3">
                <span>Total</span>
                <span class="font-medium">
                  <total-price
                    :price="checkout.total"
                    :currency="checkout.currency" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import countries from "@/utils/countries.json"
import Layout from "@/Pages/Shop/Layout.vue"
import CheckoutItemsSummary from "@/Pages/Shop/Partials/CheckoutItemsSummary.vue"
import Auth from "@/Pages/Shop/Partials/Auth.vue"
import TotalPrice from "@/Pages/Shop/Partials/TotalPrice"

export default {
  props: {
    checkout: {
      type: Object,
      default () {
        return {}
      }
    },

    product: {
      type: Object,
      default () {
        return {}
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    },

    formDefaults: {
      type: [Object, Array],
      default () {
        return {}
      }
    },

    cart: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    Layout,
    CheckoutItemsSummary,
    Auth,
    TotalPrice
  },

  mounted () {
    this.formData = { ...this.formData, ...this.formDefaults, ... { items: this.cart.items }}
    this.countries = countries
  },

  data () {
    return {
      formData: {
        items: [],
        currency: null,
        payment_option: {},
        payment_method: "stripe-checkout",
        relationship_option: {},
        relationship_description: null,
        addons: [],
        billing_first_name: null,
        billing_last_name: null,
        billing_address_line_1: null,
        billing_city: null,
        billing_state: null,
        billing_postal_code: null,
        billing_country: null,
        billing_phone: null,
        email: null,
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_address_line_1: null,
        shipping_address_line_2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_postal_code: null,
        shipping_country: null,
        engraving_line_1: null,
        engraving_line_2: null,
        terms: false,
        privacy_terms: false,
        newsletter: false,
        use_separate_shipping_address: false
      },
      countries: [],
      showLogin: false,
      editCustomer: false,
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(this.route("shop.checkout.contact-information.store", { checkout: this.checkout.id }), this.formData, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    }
  },

  computed: {
    selectedCountry () {
      return countries
        .filter(country => country.Code === this.formData.billing_country)
        .map(country => country.Name)[0]
    },

    shipping () {
      if (this.checkout.shipping_method_id) {
        return this.checkout.shipping_method.amount
      }

      return 0
    }
  }
}
</script>
