<template>
  <span>
    {{ amount / conversionRate.exchange_rate * conversionRate.amount | formatPriceWithDecimals(conversionRate.currency_unit) }}
  </span>
</template>

<script>
  export default {
    props: {
      amount: {
        type: [Number,String],
        default: 0
      }
    },

    computed: {
      market () {
        return this.$page.props.markets.filter(market => market.code === this.$page.props.market)[0]
      },

      conversionRate () {
        return this.$page.props.conversionRates.filter(rate => rate.currency_unit === this.market.currency)[0]
      }
    }
  }
</script>
