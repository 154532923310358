<template>
  <layout>
    <div class="hero bg-black text-white pt-8 pb-3">
      <div class="container mx-auto">
        <article class="text-center max-w-6xl mx-auto">
          <header class="sm:mt-12 max-w-4xl mx-auto">
            <div class="w-8 text-white mx-auto mb-6">
              <svg
                class="fill-current text-white"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.575 9.4446C20.6336 4.01384 15.9856 0 10.3705 0H9.4446V4.42583C4.01384 5.36656 0 10.0144 0 15.6295V16.5554H4.40684C5.34757 21.9862 9.99538 26 15.6114 26H16.5373V21.5753C21.9788 20.6351 26 15.9871 26 10.3705V9.4446H21.575V9.4446ZM16.5373 19.7037V17.2037H14.6855V24.1048C10.504 23.7087 7.12821 20.644 6.27858 16.5554H8.79627V14.7036H1.89525C2.2913 10.5229 5.35605 7.1472 9.4446 6.29757V8.79627H11.2964V1.89525C15.4771 2.2913 18.8537 5.35605 19.7033 9.4446H17.1847V11.2964H24.1048C23.7078 15.4778 20.6357 18.8547 16.5373 19.7037Z"
                  fill="white" />
                <path
                  d="M13.4576 11.6782H12.5316V12.5399H11.6699V13.4659H12.5316V14.3267H13.4576V13.4659H14.3184V12.5399H13.4576V11.6782Z"
                  fill="white" />
              </svg>
            </div>
            <h2 class="sm:text-xl uppercase leading-tight block text-yellow-mm-600">
              Swiss Made
            </h2>
            <h1 class="text-xl sm:text-5xl tracking-widest uppercase font-black leading-tight block my-2">
              Spectrum
            </h1>
            <p class="font-medium block uppercase my-2 sm:text-xl leading-tight">
              <span class="font-bold">FSK</span> &times; <span class="font-bold">MicroMilspec</span>
            </p>
          </header>

          <div class="max-w-4xl mx-auto my-8 sm:my-12 relative">
            <figure class="-mt-12 -mb-12">
              <img
                :src="asset('images/spectrum/hero.jpg')"
                alt="Spectrum">
            </figure>
          </div>

          <div class="max-w-lg mx-auto">
            <div class="flex items-stretch justify-between text-xs mt-8 sm:mt-8 sm:mb-8 flex-wrap sm:flex-no-wrap">
              <div class="w-1/2 sm:w-auto border border-white p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="18"
                    height="22"
                    viewBox="0 0 18 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.7143 3.14286C13.619 3.14286 10.4762 2.09524 8.90475 0V22C17.2857 18.8571 17.8095 13.619 17.8095 10.4762V3.14286H15.7143Z"
                      fill="#676767" />
                    <mask
                      id="path-2-inside-1"
                      fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.90476 0C7.33333 2.09524 4.19048 3.14286 2.09524 3.14286H0V10.4762C0 13.619 0.52381 18.8571 8.90476 22C17.2857 18.8571 17.8095 13.619 17.8095 10.4762V3.14286H15.7143C13.619 3.14286 10.4762 2.09524 8.90476 0Z" />
                    </mask>
                    <path
                      d="M8.90476 0L9.70476 -0.6L8.90476 -1.66667L8.10476 -0.6L8.90476 0ZM0 3.14286V2.14286H-1V3.14286H0ZM8.90476 22L8.55364 22.9363L8.90476 23.068L9.25589 22.9363L8.90476 22ZM17.8095 3.14286H18.8095V2.14286H17.8095V3.14286ZM2.09524 4.14286C3.29059 4.14286 4.73166 3.84839 6.08449 3.27878C7.43543 2.70996 8.7811 1.83155 9.70476 0.6L8.10476 -0.6C7.457 0.263688 6.44552 0.956708 5.30837 1.43551C4.1731 1.91352 2.99512 2.14286 2.09524 2.14286V4.14286ZM0 4.14286H2.09524V2.14286H0V4.14286ZM1 10.4762V3.14286H-1V10.4762H1ZM9.25589 21.0637C5.23897 19.5573 3.2217 17.5978 2.1811 15.7478C1.13004 13.8793 1 11.9937 1 10.4762H-1C-1 12.1015 -0.86814 14.4064 0.437948 16.7284C1.75449 19.0689 4.1896 21.2998 8.55364 22.9363L9.25589 21.0637ZM9.25589 22.9363C13.6199 21.2998 16.055 19.0689 17.3716 16.7284C18.6777 14.4064 18.8095 12.1015 18.8095 10.4762H16.8095C16.8095 11.9937 16.6795 13.8793 15.6284 15.7478C14.5878 17.5978 12.5706 19.5573 8.55364 21.0637L9.25589 22.9363ZM18.8095 10.4762V3.14286H16.8095V10.4762H18.8095ZM17.8095 2.14286H15.7143V4.14286H17.8095V2.14286ZM15.7143 2.14286C14.8144 2.14286 13.6364 1.91352 12.5012 1.43551C11.364 0.956708 10.3525 0.263688 9.70476 -0.6L8.10476 0.6C9.02842 1.83155 10.3741 2.70996 11.725 3.27878C13.0779 3.84839 14.5189 4.14286 15.7143 4.14286V2.14286Z"
                      fill="white"
                      mask="url(#path-2-inside-1)" />
                  </svg>
                </div>
                <div>
                  5 års garanti
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white border-l-0 p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <mask
                      id="path-1-inside-1"
                      fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M7.72727 7.72727V0L0 12.2727H5.90909V20L13.6364 7.72727H7.72727Z" />
                    </mask>
                    <path
                      d="M7.72727 7.72727H6.72727V8.72727H7.72727V7.72727ZM7.72727 0H8.72727V-3.46507L6.88104 -0.532813L7.72727 0ZM0 12.2727L-0.846233 11.7399L-1.81134 13.2727H0V12.2727ZM5.90909 12.2727H6.90909V11.2727H5.90909V12.2727ZM5.90909 20H4.90909V23.4651L6.75532 20.5328L5.90909 20ZM13.6364 7.72727L14.4826 8.26009L15.4477 6.72727H13.6364V7.72727ZM8.72727 7.72727V0H6.72727V7.72727H8.72727ZM6.88104 -0.532813L-0.846233 11.7399L0.846233 12.8055L8.57351 0.532813L6.88104 -0.532813ZM0 13.2727H5.90909V11.2727H0V13.2727ZM4.90909 12.2727V20H6.90909V12.2727H4.90909ZM6.75532 20.5328L14.4826 8.26009L12.7901 7.19446L5.06286 19.4672L6.75532 20.5328ZM13.6364 6.72727H7.72727V8.72727H13.6364V6.72727Z"
                      fill="white"
                      mask="url(#path-1-inside-1)" />
                  </svg>
                </div>
                <div>
                  Swiss Made
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white sm:border-l-0 border-t-0 sm:border-t p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19.4871 11C19.2271 16.0143 15.079 20 10 20C4.92101 20 0.772939 16.0143 0.512932 11H19.4871Z"
                      fill="#676767"
                      stroke="white" />
                    <path
                      d="M10 1C4.75329 1 0.5 5.25329 0.5 10.5H19.5C19.5 5.25329 15.2467 1 10 1Z"
                      stroke="white" />
                    <circle
                      cx="10"
                      cy="10.5"
                      r="2"
                      fill="white"
                      stroke="white" />
                  </svg>
                </div>
                <div>
                  Mekanisk urverk
                </div>
              </div>
              <div class="w-1/2 sm:w-auto border border-white border-l-0 border-t-0 sm:border-t p-2 flex items-center whitespace-no-wrap flex-grow">
                <div class="w-8 inline-flex justify-center pr-2">
                  <svg
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                      cx="9.5"
                      cy="11"
                      r="5.5"
                      fill="#676767"
                      stroke="white" />
                    <path
                      d="M9.5 19.5H6.5V21H4.5L1 13.5V8.5L4.5 1H6.5V2.5H9.5"
                      stroke="white" />
                    <path
                      d="M9.5 19.5H12.5V21H14.5L18 13.5V8.5L14.5 1H12.5V2.5H9.5"
                      stroke="white" />
                  </svg>
                </div>
                <div>
                  Spesiallaget
                </div>
              </div>
            </div>
          </div>
          <div class="max-w-2xl mx-auto">
            <div class="mt-16 mb-48 sm:px-16">
              <h2 class="mb-2 font-bold text-2xl leading-tight">
                Lanseres 22.06.2020
              </h2>
              <p class="mb-6 font-bold text-2xl">
                Få påminnelse når klokken kommer for salg
              </p>
              <pre-signup
                :site-id="9"
                button-text="Gi meg beskjed" />
            </div>
          </div>
        </article>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import PreSignup from "@/Shared/PreSignup.vue"

export default {
  components: {
    Layout,
    PreSignup,
  },

  created () {
    try {
      window.fathom("trackPageview")
    } catch (e) {}
  }
}
</script>
