<template>
  <div class="checkbox">
    <input
      v-model="model"
      class="clip"
      type="checkbox"
      :id="`checkbox-${uid}`"
      :disabled="disabled">
    <label
      :for="`checkbox-${uid}`">
      <div
        class="flex items-center justify-start">
        <div class="mr-3">
          <div class="marker" />
        </div>
        <div>
          <slot />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit("input", value)
      }
    },

    uid() {
      return "-" + Math.random().toString(36).substr(2, 9)
    }
  }

}
</script>
