<template>
  <div
    ref="renderer"
    class="renderer">
    <div
      ref="rendererInner"
      class="renderer-inner">
      <div class="image">
        <slot />
      </div>
      <svg
        v-for="(item) in engravings"
        ref="svgs"
        :width="nativeWidth"
        :height="nativeHeight"
        class="engraving inset-0"
        :class="{ 'engraving--dark': isDark, [casebackKind]: true }"
        :key="item.label"
        :viewBox="`0 0 ${nativeWidth} ${nativeHeight}`"
        :style="template.textStyle"
      >
        <defs>
          <filter
            v-for="(blur,i) in template.blurs"
            :key="i"
            :id="`blur-${i}`"
            width="150%"
            height="150%">
            <feGaussianBlur :stdDeviation="blur.size" />
            <feOffset
              :dx="blur.offsetX"
              :dy="blur.offsetY" />
          </filter>
          <filter id="text">
            <feMorphology operator="erode" :radius="template.erode" />
          </filter>
          <pattern
            width="32"
            height="32"
            patternUnits="userSpaceOnUse"
            id="texture">
            <image
              :xlink:href="asset(`images/engraving/${casebackKind}.png`)"
              width="32"
              height="32" />
          </pattern>
        </defs>
        <path
          id="arc"
          fill="transparent"
          :d="`M ${originX-template.radius} ${originY} A ${template.radius},${template.radius} 0 1 0 ${originX+template.radius},${originY}`" />
        <text
          v-for="(blur,i) in template.blurs"
          :key="i"
          :style="`filter: url(#blur-${i}); fill: ${blur.fill};`"
          :width="nativeWidth"
          text-anchor="middle">
          <textPath
            xlink:href="#arc"
            startOffset="50%">
            {{ value }}
          </textPath>
        </text>
        <text
          :width="nativeWidth"
          text-anchor="middle"
          style="filter: url(#text);"
          >
          <textPath
            xlink:href="#arc"
            startOffset="50%">
            {{ value }}
          </textPath>
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
const INDEX_NATIVE_WIDTH = 1000
const IS_SAFARI = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

const kinds = {
  "closed-caseback": {
    radius: .384,
    textStyle: {
      fontFamily: "Engraving, 'sans-serif'",
      fontSize: "37px",
      fill: "url(#texture)"
    },
    erode: 0,
    blurs: [
      {
        fill: "rgba(0,0,0,.9)",
        size: "1.2",
        offsetX: "0",
        offsetY: "1.5",
      },
      {
        fill: "rgba(255,255,255,.7)",
        size: ".8",
        offsetX: "0",
        offsetY: "-.8",
      },
    ]
  },
  "closed-caseback-checkout": {
    radius: .268,
    textStyle: {
      fontFamily: "Engraving, 'sans-serif'",
      fontSize: "26px",
      fill: "url(#texture)"
    },
    erode: 0,
    blurs: [
      {
        fill: "rgba(0,0,0,.9)",
        size: "1.2",
        offsetX: "0",
        offsetY: "1.5",
      },
      {
        fill: "rgba(255,255,255,.7)",
        size: ".8",
        offsetX: "0",
        offsetY: "-.8",
      },
    ]
  },
  "closed-caseback-dark": {
    radius: .384,
    textStyle: {
      fontFamily: "Engraving, 'sans-serif'",
      fontSize: "37px",
      fill: "url(#texture)"
    },
    erode: 0,
    blurs: [
      {
        fill: "rgba(0,0,0,1)",
        size: "1",
        offsetX: "1",
        offsetY: "1",
      },
      {
        fill: "rgba(0,0,0,1)",
        size: "1",
        offsetX: "-1",
        offsetY: "-1",
      },
      {
        fill: "rgba(255,255,255,.7)",
        size: ".4",
        offsetX: "0",
        offsetY: "-.58",
      },
    ]
  },
  "open-caseback-silver": {
    radius: .405,
    textStyle: {
      fontFamily: "EngravingDruk, 'sans-serif'",
      fontSize: "37px",
      letterSpacing: "0.08em",
      fill: "url(#texture)"
    },
    erode: 0,
    blurs: [
      {
        fill: "rgba(0,0,0,1)",
        size: "5",
        offsetX: "0",
        offsetY: "5",
      },
      {
        fill: "rgba(0,0,0,1)",
        size: "5",
        offsetX: "0",
        offsetY: "5",
      },
      {
        fill: "rgba(255,255,255,.95)",
        size: ".7",
        offsetX: "0",
        offsetY: "-1.8",
      },
    ]
  },
  "open-caseback-dark": {
    radius: .4,
    textStyle: {
      fontFamily: "EngravingDruk, 'sans-serif'",
      fontSize: "37px",
      fill: "url(#texture)"
    },
    erode: 0,
    blurs: [
      {
        fill: "rgba(255,255,255,.2)",
        size: ".2",
        offsetX: "0",
        offsetY: "-0.6",
      },
      {
        fill: "rgba(0,0,0,.6)",
        size: "1.5",
        offsetX: "0",
        offsetY: "3",
      }
    ]
  },
  "open-caseback-steel-2023": {
    radius: .413,
    textStyle: {
      fontFamily: "EngravingDruk, 'sans-serif'",
      fontSize: "42px",
      letterSpacing: "0px",
      fill: "url(#texture)"
    },
    erode: 1.78,
    blurs: [
      {
        fill: "rgba(0,0,0,1)",
        size: "1.5",
        offsetX: "0",
        offsetY: "1",
      },
      {
        fill: "rgba(255,255,255,1)",
        size: ".9",
        offsetX: ".2",
        offsetY: "-1.6",
      },
      {
        fill: "rgba(98,98,98,1)",
        size: "0",
        offsetX: "0",
        offsetY: "0",
      },
    ]
  },
  "closed-caseback-dark-2023": {
    radius: .412,
    textStyle: {
      fontFamily: "EngravingDruk, 'sans-serif'",
      fontSize: "39px",
      letterSpacing: "3px",
      fill: "url(#texture)"
    },
    erode: 0,
    blurs: [
      {
        fill: "rgba(0,0,0,1)",
        size: "1.7",
        offsetX: "0",
        offsetY: "3",
      },
      {
        fill: "rgba(255,255,255,.32)",
        size: ".5",
        offsetX: "0",
        offsetY: "-.65",
      },
    ]
  },
}

export default {
  props: {
    casebackKind: {
      type: String,
      default: "closed-caseback"
    },
    nativeWidth: {
      type: Number,
      default: 1024
    },
    nativeHeight: {
      type: Number,
      default: 1024
    },
    originU: {
      type: Number,
      default: .5
    },
    originV: {
      type: Number,
      default: .5
    },
    inputs: {
      type: Array,
      default () {
        return {}
      }
    },
    isDark: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      originX: this.nativeWidth * this.originU,
      originY: this.nativeHeight * this.originV,
      aspectRatio: this.nativeHeight / this.nativeWidth,
      scaleFactor: this.nativeWidth / INDEX_NATIVE_WIDTH,
      engravings: this.inputs.map(x => {
        return {...x, ...{
          radius: x.radius * this.nativeWidth,
          value: "",
          remaining: x.maxCharacters
        }}
      })
    }
  },

  computed: {
    template() {
      const kind = kinds[this.casebackKind]
      return {
        ...kind,
        radius: kind.radius * this.nativeWidth
      }
    }
  },

  beforeUpdate() {
    for (const x of this.engravings) {
      x.value = x.value.slice(0, x.maxCharacters)
      x.remaining = x.maxCharacters - x.value.length
    }
  },

  updated() {
    // Safari has some mysterious bug where it doesn't repaint SVG filters
    // force it to repaint by toggling display to none
    if (IS_SAFARI) {
      for (const svg of this.$refs.svgs) {
        svg.style.display = "none";
      }
      setTimeout(() => {
        for (const svg of this.$refs.svgs) {
          svg.style.display = "";
        }
      },0);
    }
  },

  mounted() {
    this.scaleRenderer()
    window.addEventListener("resize", this.scaleRenderer)
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.scaleRenderer)
  },

  methods: {
    scaleRenderer() {
      const width = this.$refs.renderer.clientWidth
      const height = width * this.aspectRatio
      // const scale = width / this.nativeWidth
      this.$refs.renderer.style.height = height + "px"
      // this.$refs.rendererInner.style.transform = `scale(${scale})`
    }
  }
}
</script>
