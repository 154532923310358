<template>
  <div class="toggle-timeline">
    <button
      ref="left"
      @click="onLeftClick">
      <slot name="a">
        A
      </slot>
    </button>
    <!--
    <input ref="input" v-on:input="onInput" v-on:touchstart ="onMouseDown" v-on:mousedown="onMouseDown" v-on:touchend="onMouseUp" v-on:touchcancel="onMouseUp" v-on:mouseup="onMouseUp" type="range" min="0" max="1" step="0.01">
    -->
    <div
      ref="track"
      class="track">
      <svg
        v-if="size == 'large'"
        ref="thumb"
        class="large thumb"
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="14.5"
          cy="14.5"
          r="13.5"
          fill="#181818"
          stroke="white"
          stroke-width="2" />
      </svg>

      <div class="line" />
    </div>
    <button
      ref="right"
      @click="onRightClick">
      <slot name="b">
        B
      </slot>
    </button>
  </div>
</template>

<script>
import Hammer from "hammerjs"

const colors = [151, 255]

export default {
  props: ["size"],
  mounted() {
    this.colorLabels(0)
    this.tick = null
    this.progress = 0
    this.destProgress = 0
    this.colorLabels(0)

    switch (this.size) {
      case "small":
        this.thumbSize = 18
        break
      case "large":
        this.thumbSize = 29
        break
    }

    this.hammer = new Hammer(this.$refs.track)
    this.hammer.on("panstart", this.onPanStart)
    this.hammer.on("panmove", this.onPanMove)
    this.hammer.on("panend pancancel", this.onPanEnd)
    this.hammer.on("tap press", this.onTap)

    this.setProgress(0)
  },
  beforeDestroy() {
    this.clearTick()
    this.hammer.destroy()
  },
  methods: {
    colorLabels: function(val) {
    if (this.$refs.left && this.$refs.right) {
      let c = colors[0] + (colors[1] - colors[0]) * (1-val)
      this.$refs["left"].style.color = `rgba(${c},${c},${c}, 1)`

      c = colors[0] + (colors[1] - colors[0]) * val
      this.$refs["right"].style.color = `rgba(${c},${c},${c}, 1)`
      }
    },

    mapX: function(x) {
      const rect = this.$refs.track.getBoundingClientRect()
      return Math.max(0, Math.min(1, (x - rect.left) / (rect.right - rect.left)))
    },

    onPanMove: function(e) {
      const val = this.mapX(e.center.x)
      this.setProgress(val)
    },

    onTap: function(e) {
      const val = this.mapX(e.center.x)
      this.destProgress = Math.round(val)
      this.startTick()
    },

    onPanStart: function(e) {
      this.clearTick()
    },

    onPanEnd: function(e) {
      const val = this.mapX(e.center.x)
      this.destProgress = Math.round(val)
      this.startTick()
    },

    onLeftClick: function() {
      this.setDest(0)
    },

    onRightClick: function() {
      this.setDest(1)
    },

    setDest: function(x) {
      this.destProgress = x
      this.startTick()
    },

    startTick: function() {
      if (this.tick) {
        return
      }
      this.tick = setInterval(this.onTick.bind(this), 20)
    },

    onTick: function() {
      const f = 9
      const distance = this.destProgress - this.progress
      if (Math.abs(distance) > .0000001) {
        const sign = distance >= 0 ? 1 : -1
        const x = this.progress + Math.min(.03, Math.abs(distance) / f) * sign
        this.setProgress(x)
      } else {
        this.setProgress(this.destProgress)
        this.clearTick()
      }
    },

    setProgress: function(x) {
      this.progress = x
      this.colorLabels(x)

      const offset = this.thumbSize / 2
      const range = this.$refs.track.clientWidth
      this.$refs.thumb.style.left = `${range * x - offset}px`

      this.$emit("change", x)
    },

    clearTick: function() {
      if (this.tick !== null) {
        clearInterval(this.tick)
        this.tick = null
      }
    }
  }
}
</script>
