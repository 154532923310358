<template>
  <div
    id="logos-box"
    class="box bg-yellow-mm-600 rounded lg:px-8 xl:px-16 relative"
  >
    <div class="text-center text-black text-xl sm:text-3xl mt-11 mb-13 px-4 leading-7">
      {{ data.logos_headline }}
    </div>
    <div
      class="block -z-10 rounded-xl"
      :class="open ? '' : 'shadow'" />
    <div
      class="relative accordion rounded-xl"
      :class="open ? 'open' : 'closed'">
      <div class="flex items-center justify-center flex-wrap px-1 sm:px-0 pb-12">
        <div
          v-for="(i, index) in data.logos_list"
          :key="`${index}-${i.logo.url}`"
          class="p-px lg:m-2.5 w-1/2 sm:w-1/4 lg:w-48 select-none">
          <a
            :href="i.logo_external_link.url"
            :target="i.logo_external_link.target">
            <img :src="i.logo.url">
          </a>
        </div>
      </div>
      <div class="mx-auto z-10 bottom-4 absolute ml-1/2 -translatex-1/2">
        <a
          href="#"
          class="block border-0 select-none"
          @click.prevent="open = !open">
          <div
            class="arrow"
            :style="{ transform: open ? 'translateX(-50%) rotate(180deg)' : '' }" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import prismicMixins from "../../../utils/prismic-mixins"
import { staticStrings } from "../staticStrings"

export default {
  mixins: [prismicMixins, staticStrings],
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      open: false
    }
  }
}
</script>
