<template>
  <checkout-total
    :data="formData"
    :discount="discount">
    <template v-slot:copy>
      Your total
    </template>
    <template v-slot:default="slotProps">
      <div v-if="step === 1">
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-6/12 xl:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Customize your watch
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  You are only a few steps away from completing your order
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Choose kit"
          subtitle="One of four options">
          <p class="mb-8 text-sm">
            All kits include personal engraving, display box, tool for changing straps, proof of authenticity by MICROMILSPEC. All straps will fit any wrist
          </p>
          <radio-list
            :options="products"
            v-model="formData.product">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                :class="{ 'bg-gray-mm-600': item.id === formData.product.id }">
                <div
                  class="absolute top-0 right-0 mx-4 -mt-5"
                  v-if="item.id === formData.product.id">
                  <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Selected</span>
                </div>
                <div class="flex items-center text-sm justify-between mt-4 mb-2 -ml-4 -mr-4">
                  <div class="w-5/12 px-4">
                    <zoom-image>
                      <img
                        v-if="item.featured_image"
                        :src="item.featured_image"
                        :alt="item.name"
                        class="object-contain h-40 mx-auto">
                      <template v-slot:zoomed>
                        <div>
                          <p class="font-xl block mb-4 text-center">
                            {{ item.name }}
                          </p>
                          <img
                            :src="item.meta.full_size_image"
                            alt="">
                        </div>
                      </template>
                    </zoom-image>
                  </div>
                  <div class="w-7/12 px-4">
                    <h2 class="text-lg leading-tight text-white mb-2">
                      {{ item.name }}
                    </h2>
                    <p class="text-gray-mm-300 block mb-4">
                      {{ item.description }}
                    </p>
                    <p class="text-yellow-mm-600">
                      {{ item.price | formatPrice }} incl. tax,
                    </p> or <span>{{ item.price / 6 | formatPrice }}</span> /mo for six months.
                  </div>
                </div>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Add extra straps"
          subtitle="Click to add strap"
          class="overflow-hidden">
          <template v-slot:rightcol>
            <div class="block xl:-mr-20 sm:mt-12 sm:mr-12">
              <video
                autoplay
                loop
                muted
                playsinline>
                <source
                  :src="formDefaults.checkout.strap_video_url"
                  type="video/mp4">
              </video>
            </div>
          </template>
          <p class="mb-8 text-sm text-gray-mm-300">
            (Rubber, leather and steel bracelet are included in the kit)
          </p>
          <checkbox-list
            class="flex flex-wrap items-stretch -ml-2 -mr-2 mb-4"
            :options="addons"
            v-model="formData.addons"
            option-class="px-2 mb-4 w-1/2">
            <template slot-scope="{ item }">
              <div
                class="border border-gray-mm-300 rounded p-4 flex text-sm sm:text-base"
                :class="{ 'bg-gray-mm-600': formData.addons.includes(item) }">
                <div>
                  <img
                    :src="item.featured_image"
                    :alt="item.name"
                    class="object-contain h-24">
                </div>
                <div class="pl-4">
                  <h3 class="font-bold leading-tight">
                    {{ item.name }}
                  </h3>
                  <p class="text-gray-mm-300 text-sm">
                    {{ item.price | formatPrice }}
                  </p>
                  <in-cart
                    :item="item"
                    :cart="formData.addons">
                    Selected
                  </in-cart>
                </div>
              </div>
            </template>
          </checkbox-list>
        </checkout-section>

        <div v-if="sanityData">
          <div
            v-for="block in sanityData.page1Blocks"
            :key="block._key"
            class="loop">
            <div v-if="block._type === 'checkoutEngravingWithRelationship'">
              <checkout-section
                :title="block.title ? block.title : 'Your connection to the unit'"
                :subtitle="block.subtitle ? block.subtitle : 'Choose one'">
                <select-field-object
                  v-if="block.relationshipSelectStyle == 'select'"
                  :options="block.options"
                  v-model="formData.relationship_option"
                  placeholder="Choose option"
                  class="mb-4">
                  <template slot-scope="{ item }">
                    {{ item.title }}
                  </template>
                </select-field-object>
                <radio-list
                  v-else
                  class="mb-4"
                  :options="block.options"
                  v-model="formData.relationship_option">
                  <template slot-scope="{ item }">
                    <div class="py-2 flex items-center text-sm sm:text-base">
                      <span
                        class="radio-icon mr-3"
                        :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                      <span>{{ item.title }}</span>
                    </div>
                  </template>
                </radio-list>

                <div v-if="formData.relationship_option && ['other', 'Other', 'aktiv', 'Aktiv'].includes(formData.relationship_option.id)">
                  <input-field
                    placeholder="Enter your connection"
                    v-model="formData.relationship_description"
                    class="mb-3" />
                </div>

                <div
                  class="content text-sm"
                  v-if="block.helpText">
                  <block-content
                    :blocks="block.helpText" />
                </div>
              </checkout-section>

              <checkout-engraving
                :value="formData.engraving_line_1"
                :image="formData.relationship_option.id ? formData.relationship_option.image : block.casebackImage"
                :is-dark="false"
                :caseback-kind="block.engravingStyle">
                <div class="lg:max-w-md py-8 sm:py-12">
                  <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                    Choose your unique engraving
                  </h3>
                  <p class="text-gray-mm-900 lg:text-lg mb-6">
                    You can modify your engraving any time before production starts at https://hq.micromilspec.com
                  </p>
                  <label
                    for="engraving_line_1"
                    class="text-gray-mm-900 mb-3 inline-block">
                    Your personal engraving (optional)
                  </label>
                  <input-field
                    placeholder="Your engraving here (optional)"
                    :maxlength="block.engravingLine1Max || 30"
                    v-model="formData.engraving_line_1"
                    class="mb-6"
                    name="engraving_line_1"
                    bg-class="bg-gray-mm-500" />
                  <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
                    Ex. Name, personal motto, dates or important milestones.
                  </p>
                </div>
              </checkout-engraving>
            </div>
            <div v-if="block._type === 'checkoutRelationship'">
              <checkout-section
                :title="block.title ? block.title : 'Your connection to the unit'"
                :subtitle="block.subtitle ? block.subtitle : 'Choose one'">
                <select-field-object
                  v-if="block.relationshipSelectStyle == 'select'"
                  :options="block.options"
                  v-model="formData.relationship_option"
                  placeholder="Choose one"
                  class="mb-4">
                  <template slot-scope="{ item }">
                    {{ item.title }}
                  </template>
                </select-field-object>
                <radio-list
                  v-else
                  class="mb-4"
                  :options="block.options"
                  v-model="formData.relationship_option">
                  <template slot-scope="{ item }">
                    <div class="py-2 flex items-center text-sm sm:text-base">
                      <span
                        class="radio-icon mr-3"
                        :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                      <span>{{ item.title }}</span>
                    </div>
                  </template>
                </radio-list>

                <div v-if="formData.relationship_option && ['other', 'Other'].includes(formData.relationship_option.id)">
                  <input-field
                    placeholder="Enter your connection"
                    v-model="formData.relationship_description"
                    class="mb-3" />
                </div>

                <div
                  class="content text-sm"
                  v-if="block.helpText">
                  <block-content
                    :blocks="block.helpText" />
                </div>
              </checkout-section>
            </div>
            <div v-if="block._type === 'checkoutRelationshipWithDialAndEngraving'">
              <checkout-section
                :title="block.title ? block.title : 'Your connection to the unit'"
                :subtitle="block.subtitle ? block.subtitle : 'Choose one'">
                <div
                  v-if="formData.relationship_option.id"
                  class="w-full md:px-12">
                  <figure class="my-4">
                    <img
                      :src="formData.relationship_option.dialImage"
                      :alt="formData.relationship_option.id">
                  </figure>
                </div>
                <div
                  v-else
                  class="w-full md:px-12">
                  <figure class="my-4 opacity-50">
                    <img
                      :src="block.dialImage"
                      alt="Default">
                  </figure>
                </div>
                <select-field-object
                  v-if="block.relationshipSelectStyle == 'select'"
                  :options="block.options"
                  v-model="formData.relationship_option"
                  :placeholder="block.subtitle ? block.subtitle : 'Choose one'"
                  class="mb-4">
                  <template slot-scope="{ item }">
                    {{ item.title }}
                  </template>
                </select-field-object>
                <radio-list
                  v-else
                  class="mb-4"
                  :options="block.options"
                  v-model="formData.relationship_option">
                  <template slot-scope="{ item }">
                    <div class="py-2 flex items-center text-sm sm:text-base">
                      <span
                        class="radio-icon mr-3"
                        :class="{ 'radio-icon--checked': formData.relationship_option.id === item.id }" />
                      <span>{{ item.title }}</span>
                    </div>
                  </template>
                </radio-list>

                <div v-if="formData.relationship_option && ['other', 'Other'].includes(formData.relationship_option.id)">
                  <input-field
                    placeholder="Enter your connection"
                    v-model="formData.relationship_description"
                    class="mb-3" />
                </div>

                <div
                  class="content text-sm"
                  v-if="block.helpText">
                  <block-content
                    :blocks="block.helpText" />
                </div>
              </checkout-section>

              <checkout-engraving
                :value="formData.engraving_line_1"
                :image="formData.relationship_option.id ? formData.relationship_option.image : block.casebackImage"
                :is-dark="false"
                :caseback-kind="block.engravingStyle">
                <div class="lg:max-w-md py-8 sm:py-12">
                  <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                    Choose your unique engraving
                  </h3>
                  <p class="text-gray-mm-900 lg:text-lg mb-6">
                    You can modify your engraving any time before production starts at https://hq.micromilspec.com
                  </p>
                  <label
                    for="engraving_line_1"
                    class="text-gray-mm-900 mb-3 inline-block">
                    Your personal engraving (optional)
                  </label>
                  <input-field
                    placeholder="Your engraving here (optional)"
                    :maxlength="block.engravingLine1Max || 30"
                    v-model="formData.engraving_line_1"
                    class="mb-6"
                    name="engraving_line_1"
                    bg-class="bg-gray-mm-500" />
                  <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
                    Ex. Name, personal motto, dates or important milestones.
                  </p>
                </div>
              </checkout-engraving>
            </div>
            <div v-if="block._type === 'checkoutEngraving'">
              <checkout-engraving
                :value="formData.engraving_line_1"
                :image="block.casebackImage"
                :is-dark="false"
                :caseback-kind="block.engravingStyle">
                <div class="lg:max-w-md py-8 sm:py-12">
                  <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                    {{ block.title || 'Choose your unique engraving' }}
                  </h3>
                  <p class="text-gray-mm-900 lg:text-lg mb-6">
                    {{ block.subtitle || 'You can modify your engraving any time before production starts at https://hq.micromilspec.com' }}
                  </p>

                  <a
                    href="#"
                    @click.prevent="showEngravingConfig = true"
                    v-if="!showEngravingConfig"
                    class="inline-block px-4 py-3 font-semibold text-sm rounded-sm focus:outline-none focus:shadow-outline whitespace-no-wrap text-white bg-transparent border border-white">
                    Add optional engraving
                  </a>
                  <div v-else>
                    <label
                      for="engraving_line_1"
                      class="text-gray-mm-900 mb-3 inline-block">
                      Your personal engraving (optional)
                    </label>
                    <input-field
                      placeholder="Your engraving here (optional)"
                      :maxlength="block.engravingLine1Max || 30"
                      v-model="formData.engraving_line_1"
                      class="mb-6"
                      name="engraving_line_1"
                      bg-class="bg-gray-mm-500" />
                    <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
                      Ex. Name, personal motto, dates or important milestones.
                    </p>
                  </div>
                </div>
              </checkout-engraving>
            </div>
            <div v-if="block._type === 'checkoutDialWithCustomText'">
              <div
                class="bg-black text-white py-6 sm:py-8">
                <div class="container mx-auto">
                  <div class="bg-gray-mm-600">
                    <div class="lg:flex items-center">
                      <div class="lg:w-1/2 lg:pr-4">
                        <div class="p-4 sm:p-8 lg:p-16 hidden lg:block">
                          <figure class="pb-full relative">
                            <img
                              v-if="block.defaultDialImage && formData.meta.filter(line => line.value != null && line.value != '').length === 0"
                              :src="block.defaultDialImage"
                              alt=""
                              class="absolute inset-0">
                            <img
                              v-else
                              :src="block.dialImage"
                              alt=""
                              class="absolute inset-0">
                            <div
                              v-for="(dialLine,dialLineIndex) in formData.meta"
                              class="absolute inset-0"
                              :key="dialLineIndex">
                              <div
                                class="text-center uppercase"
                                :style="{ marginTop: `${dialLine.offsetTop}%`, containerType: 'inline-size' }">
                                <div
                                  :style="{ fontSize: `${dialLine.fontScale}cqw`, fontFamily: `${block.previewFont}, sans-serif`, color: `${dialLine.textColor.value}` }">
                                  {{ dialLine.value }}
                                </div>
                              </div>
                            </div>
                          </figure>
                        </div>
                      </div>
                      <div class="lg:w-1/2 px-4">
                        <div class="lg:max-w-md py-8 sm:py-12">
                          <h3 class="text-xl lg:text-3xl leading-tight mb-4">
                            {{ block.title }}
                          </h3>
                          <p class="text-gray-mm-900 lg:text-lg mb-6">
                            {{ block.subtitle }}
                          </p>
                          <div class="p-4 sm:p-8 lg:p-16 lg:hidden">
                            <figure class="pb-full relative">
                              <img
                                v-if="block.defaultDialImage && formData.meta.filter(line => line.value != null && line.value != '').length === 0"
                                :src="block.defaultDialImage"
                                alt=""
                                class="absolute inset-0">
                              <img
                                v-else
                                :src="block.dialImage"
                                alt=""
                                class="absolute inset-0">
                              <div
                                v-for="(dialLine,dialLineIndex) in formData.meta"
                                class="absolute inset-0"
                                :key="dialLineIndex">
                                <div
                                  class="text-center uppercase"
                                  :style="{ marginTop: `${dialLine.offsetTop}%`, containerType: 'inline-size' }">
                                  <div
                                    :style="{ fontSize: `${dialLine.fontScale}cqw`, fontFamily: `${block.previewFont}, sans-serif`, color: `${dialLine.textColor.value}` }">
                                    {{ dialLine.value }}
                                  </div>
                                </div>
                              </div>
                            </figure>
                          </div>

                          <div
                            v-if="!showDialConfig"
                            class="flex justify-center lg:justify-start">
                            <a
                              href="#"
                              @click.prevent="showDialConfig = true"

                              class="inline-block px-4 py-3 font-semibold text-sm rounded-sm focus:outline-none focus:shadow-outline whitespace-no-wrap text-white bg-transparent border border-white">
                              Click to customize dial text
                            </a>
                          </div>
                          <div v-else>
                            <div
                              v-for="(metaItem, metaItemIndex) in formData.meta"
                              :key="metaItemIndex">
                              <label
                                :name="metaItem.key"
                                class="text-gray-mm-900 mb-3 inline-block">
                                {{ metaItem.label }}
                              </label>
                              <input-field
                                placeholder="..."
                                :maxlength="metaItem.maxLength"
                                :value="metaItem.value"
                                @input="metaItem.value = $event.toUpperCase()"
                                class="mb-6"
                                :name="metaItem.key"
                                :autocomplete="false"
                                bg-class="bg-gray-mm-500 uppercase" />
                            </div>
                            <div
                              class="content text-sm"
                              v-if="block.helpText">
                              <block-content
                                :blocks="block.helpText" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="block._type === 'checkoutDiscountCode'">
              <checkout-section
                :title="block.title"
                :subtitle="block.subtitle">
                <form @submit="validateDiscountCode">
                  <div class="flex gap-2 justify-between items-start">
                    <input-field
                      :placeholder="block.placeholder"
                      name="discount-code"
                      v-model="formData.discount_code"
                      class="mb-3 flex-grow" />
                    <default-button
                      tertiary
                      small
                      :loading="submitting"
                      @click.prevent.native="validateDiscountCode">
                      Apply
                    </default-button>
                  </div>
                  <div
                    v-if="discount"
                    class="bg-green-800 text-white text-sm p-2 rounded-sm">
                    The code "<span class="font-bold uppercase">{{ discount.code }}"</span> has been applied.
                  </div>
                  <div
                    v-else-if="discountError"
                    class="bg-red-400 text-black text-sm p-2 rounded-sm">
                    {{ discountError }}
                  </div>
                </form>
              </checkout-section>
            </div>
            <div v-if="block._type === 'checkoutCustomField'">
              <checkout-section
                :title="block.title"
                :subtitle="block.subtitle">
                <div class="flex gap-2 justify-between items-start">
                  <input-field
                    name="custom-field"
                    v-model="customFields[block.key]"
                    :placeholder="block.placeholder"
                    class="flex-grow mb-6" />
                </div>
                <div
                  class="content text-sm"
                  v-if="block.helpText">
                  <block-content
                    :blocks="block.helpText" />
                </div>
              </checkout-section>
            </div>
          </div>
        </div>

        <checkout-engraving
          v-if="false"
          :value="formData.engraving_line_1"
          :image="formDefaults.checkout.caseback_image_url"
          :is-dark="false">
          <div class="lg:max-w-md py-8 sm:py-12">
            <h3 class="text-xl lg:text-3xl leading-tight mb-4">
              Choose your unique engraving
            </h3>
            <p class="text-gray-mm-900 lg:text-lg mb-6">
              You can modify your engraving any time before production starts at https://hq.micromilspec.com
            </p>
            <p class="text-gray-mm-900 mb-3">
              Your personal engraving
            </p>
            <input-field
              placeholder="Your engraving here"
              :maxlength="block.engravingLine1Max || 30"
              v-model="formData.engraving_line_1"
              class="mb-6"
              bg-class="bg-gray-mm-500" />
            <p class="text-gray-mm-900 mt-4 mb-4 font-bold text-sm">
              Ex. Name, personal motto, dates or important milestones.
            </p>
          </div>
        </checkout-engraving>

        <div class="container mx-auto">
          <div class="mx-auto lg:w-1/3 pt-8 pb-24 sm:pb-32">
            <default-button
              tertiary
              @click.prevent.native="step = 2"
              class="w-full">
              Next step
            </default-button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="text-white py-6 sm:py-16 border-b border-gray-mm-500">
          <div class="container mx-auto">
            <div class="lg:w-6/12 xl:w-1/3 mx-auto">
              <div class="font-medium">
                <h2 class="sm:text-2xl leading-tight">
                  Step 2 of 3
                </h2>
                <p class="sm:text-2xl text-gray-mm-300 leading-tight">
                  Your only a few steps away from completing your order
                </p>
              </div>
            </div>
          </div>
        </div>

        <checkout-section
          title="Contact information"
          subtitle="Double- and triplecheck">
          <div
            v-if="!isSensitive"
            class="mb-4">
            <label
              class="block text-white text-sm mb-2"
              for="first-name">
              First Name
            </label>
            <input
              type="text"
              name="first-name"
              autocomplete="off"
              placeholder="First Name"
              class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
              v-model="formData.customer.first_name">
          </div>
          <div
            v-if="!isSensitive"
            class="mb-4">
            <label
              class="block text-white text-sm mb-2"
              for="last-name">
              Last Name
            </label>
            <input
              type="text"
              name="last-name"
              autocomplete="off"
              placeholder="Last Name"
              class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
              v-model="formData.customer.last_name">
          </div>
          <address-autocomplete
            v-if="!isSensitive"
            @input="updateCustomerAddress"
            class="mb-4" />
          <div
            v-else
            class="mb-4">
            <label
              class="block text-white text-sm mb-2"
              for="country">
              Country
            </label>
            <select-field
              :options="countries"
              option-key="Code"
              option-value="Name"
              name="country"
              placeholder="Choose Country"
              v-model="formData.customer.country" />
          </div>

          <div class="mb-4">
            <label
              class="block text-white text-sm mb-2"
              for="phone">
              Phone
            </label>
            <input
              type="tel"
              name="phone"
              autocomplete="off"
              placeholder="Phone"
              class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
              v-model="formData.customer.phone">
          </div>
          <div>
            <label
              class="block text-white text-sm mb-2"
              for="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              autocomplete="off"
              placeholder="Email"
              class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
              v-model="formData.customer.email">
          </div>
        </checkout-section>

        <checkout-section
          title="Shipping information"
          subtitle="Where should we send your watch?">
          <input-checkbox
            v-model="formData.use_separate_shipping_address"
            class="mb-3">
            Use a separate shipping address
          </input-checkbox>
          <div v-if="formData.use_separate_shipping_address">
            <div class="mb-4">
              <label
                class="block text-white text-sm mb-2"
                for="first-name">
                First Name
              </label>
              <input
                type="text"
                name="first-name"
                autocomplete="off"
                placeholder="First Name"
                class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
                v-model="formData.shipping_first_name">
            </div>
            <div class="mb-4">
              <label
                class="block text-white text-sm mb-2"
                for="last-name">
                Last Name
              </label>
              <input
                type="text"
                name="last-name"
                autocomplete="off"
                placeholder="Last Name"
                class="block appearance-none w-full px-4 py-3 pr-8 rounded-sm focus:outline-none focus:shadow-outline text-white border border-transparent bg-gray-mm-600 placeholder-gray-mm-400"
                v-model="formData.shipping_last_name">
            </div>
            <address-autocomplete @input="updateCustomerShippingAddress" />
          </div>
        </checkout-section>

        <checkout-section
          title="Shipping Option"
          subtitle="Choose one">
          <radio-list
            :options="deliveryOptionsFiltered"
            v-model="formData.delivery_option">
            <template slot-scope="{ item }">
              <div class="py-2 flex items-center text-sm sm:text-base">
                <span
                  class="radio-icon mr-3"
                  :class="{ 'radio-icon--checked': formData.delivery_option.id === item.id }" />
                <span>
                  <span>{{ item.name }} - {{ item.description }}</span>
                  <span class="block font-bold">{{ item.price | formatPrice }}</span>
                </span>
              </div>
            </template>
          </radio-list>
        </checkout-section>

        <checkout-section
          title="Pay at your own pace"
          subtitle="No fees. No interest."
          class="pb-24 sm:pb-32">
          <div v-if="sanityData.paymentOptions.length > 0">
            <div class="mb-4">
              <h4 class="mb-3 text-sm sm:text-lg font-bold">
                Payment alternatives
              </h4>
            </div>
            <radio-list
              :options="sanityData.paymentOptions"
              option-key="value"
              v-model="formData.payment_method">
              <template slot-scope="{ item }">
                <div
                  class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                  :class="{ 'bg-gray-mm-600': item.value === formData.payment_method }">
                  <div
                    class="absolute top-0 right-0 mx-4 -mt-5"
                    v-if="item.value === formData.payment_method">
                    <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Selected</span>
                  </div>
                  <div>
                    <div class="flex font-bold items-center">
                      <span>{{ item.title }}</span>
                    </div>
                    <p class="text-gray-mm-300">
                      {{ item.description }}
                    </p>
                    <p
                      v-if="item.value == 'stripe-invoice'"
                      class="block text-gray-mm-300 mt-2">
                      <span class="text-white">Pay {{ depositAmount(slotProps.total) | formatPrice }} now and the rest later</span>. Remaining amount will be split between your number of installments.
                    </p>
                  </div>
                </div>
              </template>
            </radio-list>
          </div>

          <div v-if="formData.payment_method === 'stripe-invoice'">
            <div class="mt-12 mb-4">
              <h4 class="mb-3 text-sm sm:text-lg font-bold">
                Pay later
              </h4>
            </div>
            <input-field
              :placeholder="dateFormat"
              :label="dateFormatLabel"
              name="ssn"
              v-model="formData.customer.dob"
              class="mb-3" />
            <p class="text-gray-mm-300 text-sm mb-8">
              This field is required for us in order to offer you credit.
            </p>
            <radio-list
              :options="paymentOptions"
              v-model="formData.payment_option">
              <template slot-scope="{ item }">
                <div
                  class="border border-gray-mm-300 rounded p-4 mb-4 text-sm relative"
                  :class="{ 'bg-gray-mm-600': item.terms === formData.payment_option.terms }">
                  <div
                    class="absolute top-0 right-0 mx-4 -mt-5"
                    v-if="item.terms === formData.payment_option.terms">
                    <span class="rounded-full inline-block px-2 py-1 bg-blue-500 border border-white uppercase text-xxs font-bold leading-tight tracking-tight my-2">Selected</span>
                  </div>
                  <div class="flex gap-4 items-start mb-2">
                    <div>
                      <span class="font-bold block whitespace-no-wrap">{{ item.title }}</span>
                      <span class="text-gray-mm-300">
                        {{ item.description }}
                      </span>
                    </div>
                    <div class="ml-auto pl-3 text-right">
                      <span class="block font-bold">{{ (slotProps.total - depositAmount(slotProps.total)) / item.terms | formatPrice }}</span>
                    </div>
                  </div>
                </div>
              </template>
            </radio-list>
          </div>

          <div class="my-16">
            <h4 class="mb-3 text-sm sm:text-base">
              Terms of purchase
            </h4>
            <div class="bg-gray-mm-600 p-4 text-white h-48 overflow-y-scroll text-sm sm:text-base">
              <div class="content">
                <block-content
                  v-if="sanityData && sanityData.terms"
                  :blocks="sanityData.terms" />
              </div>
            </div>
            <div
              v-if="sanityData.returnPolicy"
              class="mt-4">
              <a
                href="#"
                @click.prevent="showReturnPolicy = !showReturnPolicy"
                class="text-gray-mm-300 text-sm underline mb-3">Return policy</a>
              <div
                v-if="showReturnPolicy"
                class="bg-gray-mm-600 p-4 text-white h-48 overflow-y-scroll text-sm sm:text-base mt-4">
                <div class="content">
                  <block-content
                    v-if="sanityData && sanityData.returnPolicy"
                    :blocks="sanityData.returnPolicy" />
                </div>
              </div>
            </div>
          </div>

          <div class="checkbox-list my-8 text-sm sm:text-base">
            <p class="my-3">
              <input-checkbox v-model="formData.terms">
                I have read and accepted the terms of purchase.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.privacy_terms">
                I accept the <a
                  :href="route('checkout.privacy-policy')"
                  target="_blank"
                  class="underline">privacy policy</a> in regards to purchases from Micromilspec AS.
              </input-checkbox>
            </p>
            <p class="my-3">
              <input-checkbox v-model="formData.newsletter">
                I wish to receive information of future launches.
              </input-checkbox>
            </p>
          </div>

          <div class="my-8 content text-gray-mm-300 text-sm">
            <p>* Unpaid orders can be cancelled without notice by MICROMILSPEC.</p>
            <p>* Personalized casebacks and dials with engravings are non-refundable priced at $150.</p>
            <p>* The anticipated production and delivery period is estimated to at least 9 months and due to residual Covid-19 supply chain impacts, an additional wait of up to 60 days may occur.</p>
          </div>

          <validation-errors />

          <default-button
            tertiary
            :loading="submitting"
            @click.prevent.native="submit"
            class="w-full mb-2">
            Complete order
          </default-button>
          <default-button
            @click.prevent.native="step = 1"
            class="w-full text-gray-mm-300">
            Go back
          </default-button>
        </checkout-section>
      </div>
    </template>
  </checkout-total>
</template>

<script>
import axios from "axios"
import countries from "@/utils/countries.json"
import provinces from "@/utils/canadian-provinces.json"
import CheckoutSection from "@/Shared/CheckoutSection.vue"
import CheckoutTotal from "@/Shared/CheckoutTotal.vue"
import RadioList from "@/Shared/RadioList.vue"
import CheckboxList from "@/Shared/CheckboxList.vue"
import InCart from "@/Shared/InCart.vue"
import CheckoutEngraving from "@/Shared/CheckoutEngraving.vue"
import ZoomImage from "@/Shared/ZoomImage.vue"
import AddressAutocomplete from "@/Shared/AddressAutocomplete.vue"
import BlockContent from "sanity-blocks-vue-component"

export default {
  components: {
    CheckoutSection,
    CheckoutTotal,
    RadioList,
    CheckboxList,
    InCart,
    CheckoutEngraving,
    ZoomImage,
    BlockContent,
    AddressAutocomplete
  },

  props: {
    products: {
      type: Array,
      default () {
        return []
      }
    },

    addons: {
      type: Array,
      default () {
        return []
      }
    },

    paymentMethods: {
      type: Array,
      default () {
        return []
      }
    },

    deliveryOptions: {
      type: Array,
      default () {
        return []
      }
    },

    formDefaults: {
      type: Object,
      default () {
        return {}
      }
    },

    siteId: {
      type: String,
      default: null
    },

    siteSlug: {
      type: String,
      default: null
    },

    salesTerms: {
      type: String,
      default: null
    },

    isSensitive: {
      type: Boolean,
      default: false
    },

    sanityData: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  mounted () {
    this.formData = { ...this.formData, ...this.formDefaults }
    this.countries = countries
    this.provinces = provinces

    if (this.sanityData && this.sanityData.page1Blocks) {
      this.sanityData.page1Blocks.map(block => {
        if (["checkoutEngravingWithRelationship", "checkoutRelationshipWithDialAndEngraving", "checkoutRelationship"].includes(block._type)) {
          this.formData.relationship_option = block.options ? block.options[0] : {}
        }

        if (["checkoutDialWithCustomText"].includes(block._type)) {
          this.formData.meta = block.lines.map((line) => {
            return {
              ...line,
              value: null
            }
          })
        }

        if (["checkoutCustomField"].includes(block._type)) {
          this.customFields[block.key] = null
        }
      })
    }

    if (this.sanityData && this.sanityData.page2Blocks) {
      this.sanityData.page2Blocks.map(block => {
        if (block._type == "checkoutEngravingWithRelationship") {
          this.formData.relationship_option = block.options ? block.options[0] : {}
        }
      })
    }

    this.formData.payment_option = this.paymentOptions[0] ?? {
      id: "now",
      terms: 1
    }

    if (this.sanityData.paymentOptions.length > 0) {
      this.formData.payment_method = this.sanityData.paymentOptions[0].value
    }

    try {
      fbq("track", "InitiateCheckout", {
        content_ids: this.products.map(product => product.id)
      })
    } catch (e) {}
  },

  data () {
    return {
      step: 1,
      checkoutComponent: "checkout-default-english",
      formData: {
        product: {},
        payment_option: {},
        payment_method: null,
        delivery_option: {},
        relationship_option: {},
        relationship_description: null,
        addons: [],
        customer: {
          first_name: null,
          last_name: null,
          address_line_1: null,
          city: null,
          state: null,
          postal_code: null,
          country: null,
          phone: null,
          email: null,
        },
        shipping_first_name: null,
        shipping_last_name: null,
        shipping_address_line_1: null,
        shipping_address_line_2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_postal_code: null,
        shipping_country: null,
        engraving_line_1: null,
        engraving_line_2: null,
        terms: false,
        privacy_terms: false,
        newsletter: false,
        use_separate_shipping_address: false,
        discount_code: null,
        meta: []
      },
      discount: null,
      discountError: null,
      submitting: false,
      countries: [],
      showReturnPolicy: false,
      showDialConfig: false,
      showEngravingConfig: false,
      customFields: {}
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(`/${this.siteId}`, this.checkoutRequest, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then(() => {
          this.submitting = false
        })
    },

    validateDiscountCode () {
      this.discountError = null
      this.submitting = true
      axios.post(this.route("checkout.verify-discount-code"), { site_uid: this.siteId, discount_code: this.formData.discount_code }, {
        headers: {
          "Content-Language": window.locale || window.default_locale
        }
      })
        .then((response) => {
          this.discount = response.data.data
          this.submitting = false
        })
        .catch(e => {
          this.discountError = e.response.data.error
          this.submitting = false
        })
    },

    updateCustomerAddress (formData) {
      this.formData.customer.address_line_1 = formData.address_line_1
      this.formData.customer.address_line_2 = formData.address_line_2
      this.formData.customer.city = formData.city
      this.formData.customer.state = formData.state
      this.formData.customer.postal_code = formData.postal_code
      this.formData.customer.country = formData.country
    },

    updateCustomerShippingAddress (formData) {
      this.formData.shipping_address_line_1 = formData.address_line_1
      this.formData.shipping_address_line_2 = formData.address_line_2
      this.formData.shipping_city = formData.city
      this.formData.shipping_state = formData.state
      this.formData.shipping_postal_code = formData.postal_code
      this.formData.shipping_country = formData.country
    },

    depositAmount (total) {
      return this.formDefaults.checkout.deposit_amount_pay_later || Math.ceil(total / 100 * 10)
    },
  },

  computed: {
    caProvinces () {
      return Object.keys(provinces).map(key => {
        return {
          key: key,
          value: provinces[key]
        }
      })
    },

    paymentOptions () {
      let options = this.sanityData.paymentOptions.filter(option => option.value == "stripe-invoice")

      if (options.length > 0) {
        return options[0].terms.map(item => {
          return {
            id: item.value ? item.value : "default",
            terms: item.terms,
            title: item.title,
            description: item.description,
          }
        })
      }

      return []
    },

    dateFormat () {
      if (["d.m.Y", "d-m-Y", "dmY"].includes(this.sanityData.dateFormat)) {
        return "ddmmyyyy"
      }

      if (["Y-m-d", "Y.m.d", "Ymd"].includes(this.sanityData.dateFormat)) {
        return "yyyymmdd"
      }

      return "mmddyyyy"
    },

    dateFormatLabel () {
      return "Date of birth (8-digits) " + this.dateFormat
    },

    deliveryOptionsFiltered () {
      if (this.isSensitive) {
        if (this.formData.use_separate_shipping_address) {
          return this.deliveryOptions.filter(opt => opt.price > 0)
        } else {
          return this.deliveryOptions.filter((opt) => opt.price === 0)
        }
      }

      return this.deliveryOptions
    },

    metaData () {
      let customFields = Object.keys(this.customFields).map(key => {
        return {
          key: key,
          value: this.customFields[key]
        }
      })

      return [
        ...this.formData.meta,
        ...customFields
      ]
    },

    checkoutRequest () {
      return { ...this.formData, ...{ meta: this.metaData }}
    }
  },

  watch: {
    step () {
      window.scrollTo(0,0)
    },

    "formData.use_separate_shipping_address"() {
      this.formData.delivery_option = {}
    }
  }
}
</script>
