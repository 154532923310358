<template>
  <layout>
    <div class="bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto sm:my-48">
          <div class="mb-4">
            <div class="mb-8 text-center">
              <h1 class="text-lg uppercase text-yellow-mm-600 tracking-widest mb-3">
                Thanks!
              </h1>
              <p>We will keep you posted.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"

export default {
  props: {
    siteSlug: {
      type: String,
      default: null
    },

    siteName: {
      type: String,
      default: null
    }
  },

  components: {
    Layout
  }
}
</script>
