<template>
  <layout>
    <div v-if="siteSlug === 'heimevernet'">
      <Countdown />
    </div>
    <div
      class="hero bg-black text-white pt-8 pb-20">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto sm:my-48">
          <div class="mb-4">
            <div class="mb-8 text-center">
              <h1 class="text-lg uppercase text-yellow-mm-600 tracking-widest">
                Restricted Area
              </h1>
              <p>Enter the pin to gain access</p>
            </div>

            <form @submit.prevent="submit">
              <div class="flex items-end justify-between">
                <div class="mr-1 w-full">
                  <input-field
                    v-model="formData.code"
                    type="password"
                    placeholder="Enter code"
                    class="text-sm"
                    autofocus />
                </div>
                <default-button
                  tertiary
                  :loading="submitting"
                  :disabled="submitting || formData.code === null">
                  OK
                </default-button>
              </div>
              <div
                class="p-3 bg-red-800 text-red-100 border border-red-800 rounded-sm mb-6 font-semibold text-sm text-center mt-2"
                v-if="error">
                {{ error }}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "@/Shared/Layout.vue"
import Countdown from "@/Pages/Checkout/Countdown.vue"
export default {
  props: {
    siteSlug: {
      type: String,
      default: null
    },

    error: {
      type: String,
      default: null
    }
  },

  components: {
    Layout,
    Countdown
  },

  created () {
    try {
      window.fathom("trackPageview")
    } catch (e) {}
  },

  data () {
    return {
      formData: {
        code: null
      },
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.post(`/${this.siteSlug}/auth`, this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  }
}
</script>
