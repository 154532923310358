<template>
  <layout>
    <div class="hero bg-black text-white pt-8">
      <div class="container mx-auto">
        <div class="w-full max-w-sm mx-auto">
          <div class="w-8 text-white mb-8">
            <svg
              class="fill-current text-white"
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.1457 5H30.4497C20.8435 5 12.7322 11.2155 10.3175 19.7049H2.5V21.3292C2.5 30.3293 8.70296 37.9392 17.2437 40.4388V45H18.9397C28.5511 45 36.6363 38.7608 39.0682 30.2951H47.5V28.6708C47.5 19.4749 41.0139 11.6838 32.1457 9.38714V5ZM28.7538 8.32629V12.0077L30.1196 12.2673C37.5668 13.6827 43.2869 19.6797 44.0269 27.0466H36.3464L36.0694 28.346C34.5506 35.4717 28.3164 40.9622 20.6357 41.6734V37.9096L19.3281 37.6149C12.1549 35.9985 6.6947 30.1396 5.97309 22.9534H13.0473L13.321 21.6493C14.8193 14.5113 21.0666 9.03501 28.7538 8.32629Z" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.6583 39.196V29.602H17.2663V39.196H20.6583Z" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M32.1457 18.2106V10.6741H28.7538V18.2106H32.1457Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.4498 30.295H39.088V27.0465H30.4498V30.295Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.9623 19.7049H7.88191V22.9534H18.9623V19.7049Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.181 26.4618V21.9572H24.0503V26.4618H25.181Z"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.241 24.7509H26.9672V23.6681H22.241V24.7509Z"
              />
            </svg>
          </div>
          <div class="mb-4">
            <div class="my-8">
              <h1 class="text-2xl font-semibold leading-tight my-4">
                Hi {{ customer.first_name }}, we need you to verify your shipping address for any delayed deliveries for your watch kit.
              </h1>
            </div>

            <form @submit.prevent="submit">
              <div class="my-8">
                <p class="text-sm uppercase text-yellow-mm-600 tracking-widest mb-4">
                  Shipping Address
                </p>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    First Name
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      v-model="formData.first_name"
                      type="text"
                      placeholder="First Name"
                      autofocus />
                  </div>
                </div>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    Last Name
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      v-model="formData.last_name"
                      type="text"
                      placeholder="Last Name"
                      autofocus />
                  </div>
                </div>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    Address
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      v-model="formData.address_line_1"
                      type="text"
                      placeholder="Address"
                      autofocus />
                  </div>
                </div>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    Postal Code
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      v-model="formData.postal_code"
                      type="text"
                      placeholder="Postal Code"
                      autofocus />
                  </div>
                </div>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    City
                  </p>
                  <div class="mb-2 w-full">
                    <input-field
                      v-model="formData.city"
                      type="text"
                      placeholder="City"
                      autofocus />
                  </div>
                </div>
                <div class="mb-4">
                  <p class="text-gray-mm-300 mb-2">
                    Country
                  </p>
                  <div class="mb-2 w-full">
                    <select-field
                      :options="countries"
                      option-key="Code"
                      option-value="Name"
                      name="country"
                      placeholder="Velg land"
                      v-model="formData.country"
                      class="mb-3" />
                  </div>
                </div>
                <div class="mb-4">
                  <default-button
                    tertiary
                    class="w-full"
                    :loading="submitting"
                    :disabled="submitting">
                    Save Information
                  </default-button>
                </div>
                <div
                  class="p-3 bg-green-800 text-white border border-green-800 rounded-sm mb-4 font-semibold text-sm text-center"
                  v-if="$page.props.flash.success">
                  {{ $page.props.flash.success }}
                </div>
                <validation-errors />
              </div>
            </form>
          </div>

          <div class="my-8 sm:mt-16 sm:mb-12">
            <p>
              If you need to make changes beyond the options available here, please reach out to us by email. <a
                href="mailto:sos@micromilspec.com"
                class="text-yellow-mm-600 underline">sos@micromilspec.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import countries from "@/utils/countries.json"
import Layout from "@/Shared/Layout.vue"

export default {
  props: {
    customer: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  components: {
    Layout
  },

  created () {
    this.countries = countries
  },

  mounted () {
    this.formData.first_name = this.customer.first_name
    this.formData.last_name = this.customer.last_name
    this.formData.address_line_1 = this.customer.address_line_1
    this.formData.address_line_2 = this.customer.address_line_2
    this.formData.postal_code = this.customer.postal_code
    this.formData.city = this.customer.city
    this.formData.country = this.customer.country
  },

  data () {
    return {
      formData: {
        first_name: null,
        last_name: null,
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        city: null,
        country: null,
      },
      error: null,
      submitting: false
    }
  },

  methods: {
    submit () {
      this.submitting = true
      this.$inertia.patch(`/customers/${this.customer.id}`, this.formData)
        .then(() => {
          this.submitting = false
        })
    }
  },
}
</script>
